import React, { useEffect } from 'react';
import { Amplify, Hub } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate } from 'react-router-dom';

const AuthPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const listener = (data) => {
      const { payload: { event } } = data;
      if (event === 'signIn') {
        const redirectURL = sessionStorage.getItem('attemptedURL') || '/';
        sessionStorage.removeItem('attemptedURL');
        navigate(redirectURL);
      }
    };

    // Hub.listen returns an unsubscribe function
    const unsubscribe = Hub.listen('auth', listener);

    // Use the unsubscribe function directly for cleanup
    return () => {
      unsubscribe();
    };
  }, [navigate]);

  return (
    <Authenticator>
      {/* Optional: Any custom components for your authentication UI */}
    </Authenticator>
  );
};

export default AuthPage;