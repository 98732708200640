import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

const OAuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleSignIn = async () => {
      try {
        await Auth.federatedSignIn({ provider: 'Google' });
        navigate('/');
      } catch (error) {
        console.error('Error during sign in:', error);
        navigate('/error');
      }
    };

    handleSignIn();
  }, [navigate]);

  return <div>Signing in...</div>;
};

export default OAuthCallback;
