// import { useSelector } from 'react-redux';

// const CheckRoute = (currentRoute) => {
//     const quizResults = useSelector(state => state.quizResults);
    
//     const { experienceLevel } = quizResults;
//     console.log('experienceLevel inside intentinos: ', experienceLevel);
//     switch (currentRoute) {
//         case 'intentions':
//             if (experienceLevel === 'experienced') {
//                 return '/strainType';
//             } else {
//                 return '/timeOfDay';
//             } 
//     }
    
//     ;
// }


const formatOptions = {
    firstLineText: "What format",
    secondLineText: "are you looking for?",
    fullText: "What format are you looking for?",
    progressValue: 90,
    scroll: true,
    options: [
      {route: "/summaryPreview", saveDataEntryValue: "flower", saveDataEntryName: "format", displayName: "Flower"},
      {route: "/summaryPreview", saveDataEntryValue: "preroll", saveDataEntryName: "format", displayName: "Pre-Roll"},
      {route: "/summaryPreview", saveDataEntryValue: "vaporizor", saveDataEntryName: "format", displayName: "Vaporizor"},
      {route: "/summaryPreview", saveDataEntryValue: "concentrates", saveDataEntryName: "format", displayName: "Concentrates"},
      {route: "/summaryPreview", saveDataEntryValue: "edibles", saveDataEntryName: "format", displayName: "Edibles"},
      {route: "/summaryPreview", saveDataEntryValue: "tinctures", saveDataEntryName: "format", displayName: "Tinctures"},
      {route: "/summaryPreview", saveDataEntryValue: "topicals", saveDataEntryName: "format", displayName: "Topicals"},
    //   {route: "/summaryPreview", saveDataEntryValue: "cbd", saveDataEntryName: "format", displayName: "CBD"}

    ]
}

const consumptionOptions = {
    firstLineText: "What consumption method",
    secondLineText: "are you looking for?",
    fullText: "What consumption method are you looking for?",
    progressValue: 90,
    avatar: "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/ConsumptionMethod-v2.mp4",
    route: "/summaryPreview",
    prevRoute: "/typeOfEffect",
    multipleSelect: false,
    imageGrid: true,
    scroll: true,
    options: [
        {route: "/summaryPreview", saveDataEntryValue: "flower", saveDataEntryName: "consumptionMethod", displayName: "smoke", displayNameAdvanced: "flower"},
        {route: "/summaryPreview", saveDataEntryValue: "edibles", saveDataEntryName: "consumptionMethod", displayName: "eat", displayNameAdvanced: "edibles"},
        {route: "/summaryPreview", saveDataEntryValue: "vape", saveDataEntryName: "consumptionMethod", displayName: "vape", displayNameAdvanced: "cartridges"},
        {route: "/summaryPreview", saveDataEntryValue: "drinks", saveDataEntryName: "consumptionMethod", displayName: "drink", displayNameAdvanced: "drinks"},
        {route: "/summaryPreview", saveDataEntryValue: "rubs", saveDataEntryName: "consumptionMethod", displayName: "rub", displayNameAdvanced: "topicals"},
        {route: "/summaryPreview", saveDataEntryValue: "concentrates", saveDataEntryName: "consumptionMethod", displayName: "dab", displayNameAdvanced: "extracts"},
        {route: "/summaryPreview", saveDataEntryValue: "drops", saveDataEntryName: "consumptionMethod", displayName: "drops", displayNameAdvanced: "tinctures"},
        {route: "/summaryPreview", saveDataEntryValue: "patches", saveDataEntryName: "consumptionMethod", displayName: "patches", displayNameAdvanced: "patches"},
        {route: "/summaryPreview", saveDataEntryValue: "recommended products", saveDataEntryName: "consumptionMethod", displayName: "not sure", displayNameAdvanced: "can't decide"},

    ]
}

const ageOptions = {
    currentLocation: "ageVerify",
    firstLineText: "Before we start",
    secondLineText: "can we verify your age?",
    fullText: "Before we start can we verify your age?",
    progressValue: 10,
    avatar: "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Verify-Age-v3.mp4",
    forcedRoute: "/experience",
    route: "/experience",
    prevRoute: false,
    options: [
        {route: "/underAge", saveDataEntryValue: "under_21", saveDataEntryName: "ageGroup", displayName: "Under 21"},
        {route: "/experience", saveDataEntryValue: "21-40", saveDataEntryName: "ageGroup", displayName: "21 - 40"},
        {route: "/experience", saveDataEntryValue: "41-65", saveDataEntryName: "ageGroup", displayName: "40 - 65"},
        {route: "/experience", saveDataEntryValue: "66+", saveDataEntryName: "ageGroup", displayName: "Over 65"},
    ],
    simpleOptions: ['Under 21', '21 - 40', '40 - 65', 'Over 65']
}

const intentionsOptions = {
    firstLineText: "How are you",
    secondLineText: "looking to feel?",
    fullText: "How are you looking to feel?",
    progressValue: 50,
    avatar: "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Intentions-v2.mp4",
    scroll: true,
    multipleSelect: true,
    imageGrid: true,
    route: "/timeOfDay",
    prevRoute: "/experience",
    options: [
        {route: "/timeOfDay", saveDataEntryValue: "euphoric", saveDataEntryName: "intentionFeelings", displayName: "euphoric"},
        {route: "/timeOfDay", saveDataEntryValue: "focused", saveDataEntryName: "intentionFeelings", displayName: "focused"},
        {route: "/timeOfDay", saveDataEntryValue: "creative", saveDataEntryName: "intentionFeelings", displayName: "creative"},
        {route: "/timeOfDay", saveDataEntryValue: "happy", saveDataEntryName: "intentionFeelings", displayName: "happy"},
        {route: "/timeOfDay", saveDataEntryValue: "calm", saveDataEntryName: "intentionFeelings", displayName: "calm"},
        {route: "/timeOfDay", saveDataEntryValue: "relaxed", saveDataEntryName: "intentionFeelings", displayName: "relaxed"},
        {route: "/timeOfDay", saveDataEntryValue: "giggly", saveDataEntryName: "intentionFeelings", displayName: "giggly"},
        {route: "/timeOfDay", saveDataEntryValue: "hungry", saveDataEntryName: "intentionFeelings", displayName: "hungry"},
        {route: "/timeOfDay", saveDataEntryValue: "sleep", saveDataEntryName: "intentionFeelings", displayName: "sleepy"}
    ]
}

const medicalOptions = {
    firstLineText: "What type of relief",
    secondLineText: "or ailments are you treating?",
    fullText: "What type of relief or ailments are you treating?",
    detailText: "Select up to 2 options",
    multipleSelect: true,
    maxSelect: 2,
    route: "/mostImportant",
    prevRoute: "/customerType",
    avatar: "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/4-Med_Type_Of_Relief-v2.mp4",
    scroll: true,
    progressValue: 35,
    scroll: false,
    options: [
        {route: "/intentions", saveDataEntryValue: "nausea", saveDataEntryName: "medicalIssues", displayName: "Nausea Relief"},
        {route: "/intentions", saveDataEntryValue: "pain", saveDataEntryName: "medicalIssues", displayName: "Pain Relief"},
        {route: "/intentions", saveDataEntryValue: "sleep", saveDataEntryName: "medicalIssues", displayName: "Improve Sleep"},
        {route: "/intentions", saveDataEntryValue: "anxiety", saveDataEntryName: "medicalIssues", displayName: "Control Anxiety"},
        {route: "/intentions", saveDataEntryValue: "appetite", saveDataEntryName: "medicalIssues", displayName: "Stimulate Appetite"},
        {route: "/intentions", saveDataEntryValue: "seriousCondition", saveDataEntryName: "medicalIssues", displayName: "Serious Condition"},
    ]
}

const mostImportantOptions = {
    firstLineText: "What do you prioritize",
    secondLineText: "when looking for products?",
    fullText: "What do you prioritize when looking for products?",
    prevRoute: "/technicalEffects",
    route: "/strainType",
    avatar: "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Priorizite-v2.mp4",
    imageGrid: true,
    progressValue: 40,
    scroll: false,
    options: [
        {route: "/strainType", saveDataEntryValue: "brand", saveDataEntryName: "mostImportant", displayName: "Brand"},
        {route: "/strainType", saveDataEntryValue: "price", saveDataEntryName: "mostImportant", displayName: "Price"},
        {route: "/strainType", saveDataEntryValue: "effect", saveDataEntryName: "mostImportant", displayName: "Effect"},
        {route: "/strainType", saveDataEntryValue: "strength", saveDataEntryName: "mostImportant", displayName: "Strength"},
        {route: "/strainType", saveDataEntryValue: "quality", saveDataEntryName: "mostImportant", displayName: "Quality"},
        {route: "/strainType", saveDataEntryValue: "lab_data", saveDataEntryName: "mostImportant", displayName: "Lab Data"},
    ]
}

const technicalOptions = {
    firstLineText: "What cannabinoids",
    secondLineText: "or terpenes are you looking for?",
    fullText: "What cannabinoids or terpenes are you looking for?",
    route: "/strainType",
    avatar: "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Cannabiniods_or_Terps-v2.mp4",
    prevRoute: "/experience",
    progressValue: 30,
    multipleSelect: true,
    imageGrid: true,
    scroll: true,
    options: [
        {route: "/strainType", saveDataEntryValue: "THC", saveDataEntryName: "preferredCannaTerps", displayName: "THC"},
        {route: "/strainType", saveDataEntryValue: "CBD", saveDataEntryName: "preferredCannaTerps", displayName: "CBD"},
        {route: "/strainType", saveDataEntryValue: "CBN", saveDataEntryName: "preferredCannaTerps", displayName: "CBN"},
        {route: "/strainType", saveDataEntryValue: "CBG", saveDataEntryName: "preferredCannaTerps", displayName: "CBG"},
        {route: "/strainType", saveDataEntryValue: "caryophyllene", saveDataEntryName: "preferredCannaTerps", displayName: "B-caryophyllene"},
        {route: "/strainType", saveDataEntryValue: "pinene", saveDataEntryName: "preferredCannaTerps", displayName: "B-pinene"},
        {route: "/strainType", saveDataEntryValue: "limonene", saveDataEntryName: "preferredCannaTerps", displayName: "Limonene"},
        {route: "/strainType", saveDataEntryValue: "linalool", saveDataEntryName: "preferredCannaTerps", displayName: "Linalool"},
        {route: "/strainType", saveDataEntryValue: "myrcene", saveDataEntryName: "preferredCannaTerps", displayName: "Myrcene"}
    ]
}

const customerType = {
    firstLineText: "What type of",
    secondLineText: "cannabis consumer are you?",
    fullText: "What type of cannabis consumer are you?",
    prevRoute: "/experience",
    avatar: "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/3-Type_Of_Consumer-v2.mp4",
    progressValue: 30,
    options: [
        {route: "/medical", saveDataEntryValue: "medical", saveDataEntryName: "visitReason", displayName: "Medical"},
        {route: "/intentions", saveDataEntryValue: "recreational", saveDataEntryName: "visitReason", displayName: "Recreational"},  
    ]
}

const experienceType = {
    firstLineText: "What experience",
    secondLineText: "have you had with dispensaries?",
    fullText: "What experience have you had with dispensaries?",
    progressValue: 20,
    avatar: "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Experience-v2.mp4",
    scroll: false,
    route: "/intentions",
    prevRoute: "/ageVerify",
    options: [
        {route: "/visitReason", saveDataEntryValue: "first_time", saveDataEntryName: "experienceLevel", displayName: "First Time"},
        {route: "/visitReason", saveDataEntryValue: "beginner", saveDataEntryName: "experienceLevel", displayName: "Beginner"},
        {route: "/visitReason", saveDataEntryValue: "moderate", saveDataEntryName: "experienceLevel", displayName: "Moderate"},
        {route: "/technicalEffects", saveDataEntryValue: "experienced", saveDataEntryName: "experienceLevel", displayName: "Experienced", disabled: true, subText: 'coming soon'},
    ]
}

const strengthOptions = {
    route: "/consumptionMethod",
    prevRoute: "/qualityLevel",
    progressValue: 70,
    avatar: "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/TypeOfEffect-v2.mp4",
    options: [
        {saveDataEntryValue: "lightest", saveDataEntryName: "preferredStrength", displayName: "lightest"},
        {saveDataEntryValue: "lighter", saveDataEntryName: "preferredStrength", displayName: "lighter"},
        {saveDataEntryValue: "stronger", saveDataEntryName: "preferredStrength", displayName: "stronger"},
        {saveDataEntryValue: "strongest", saveDataEntryName: "preferredStrength", displayName: "strongest"},
    ]
}

const durationOptions = {
    options: [
        {saveDataEntryValue: "shortest", saveDataEntryName: "preferredDuration", displayName: "shortest"},
        {saveDataEntryValue: "shorter", saveDataEntryName: "preferredDuration", displayName: "shorter"},
        {saveDataEntryValue: "longer", saveDataEntryName: "preferredDuration", displayName: "longer"},
        {saveDataEntryValue: "longest", saveDataEntryName: "preferredDuration", displayName: "longest"},
    ]
}

const strainTypeOptions = {
    avatar: "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Experienced_Strain_Type-v2.mp4",
    prevRoute: "/intentions",
    route: "/qualityLevel",
    progressValue: 60,
    firstLineText: "What type of strains",
    secondLineText: "are you looking for?",
    fullText: "What type of strains are you looking for?",
    options: [
        {saveDataEntryValue: "sativa", saveDataEntryName: "preferredStrainType", displayName: "sativa"},
        {saveDataEntryValue: "sativa-hybrid", saveDataEntryName: "preferredStrainType", displayName: "sativa hybrid"},
        {saveDataEntryValue: "hybrid", saveDataEntryName: "preferredStrainType", displayName: "hybrid"},
        {saveDataEntryValue: "indica-hybrid", saveDataEntryName: "preferredStrainType", displayName: "indica hybrid"},
        {saveDataEntryValue: "indica", saveDataEntryName: "preferredStrainType", displayName: "indica"}
    ]
}

const timeOfDayOptions = {
    avatar: "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/TimeOfDay-v2.mp4",
    prevRoute: "/intentions",
    route: "/qualityLevel",
    progressValue: 60,
    fullText: "What time of day do you prefer to consume?",
    options: [
        {saveDataEntryValue: "morning", saveDataEntryName: "preferredTimeOfDay", displayName: "morning"},
        {saveDataEntryValue: "day", saveDataEntryName: "preferredTimeOfDay", displayName: "day"},
        {saveDataEntryValue: "afternoon", saveDataEntryName: "preferredTimeOfDay", displayName: "afternoon"},
        // {saveDataEntryValue: "evening", saveDataEntryName: "preferredTimeOfDay", displayName: "evening"},
        {saveDataEntryValue: "night", saveDataEntryName: "preferredTimeOfDay", displayName: "night"}
    ]
}

const qualityOptions = {
    firstLineText: "What do you prefer",
    secondLineText: "better value or higher quality products?",
    fullText: "What do you prefer higher quality or value products?",
    avatar: "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/7-valueQuality-trial.mp4",
    prevRoute: "/timeOfDay",
    route: "/typeOfEffect",
    progressValue: 65,
    options: [
        {saveDataEntryValue: "value", saveDataEntryName: "preferredQuality", displayName: "better value"},
        {saveDataEntryValue: "balanced", saveDataEntryName: "preferredQuality", displayName: "balanced"},
        {saveDataEntryValue: "quality", saveDataEntryName: "preferredQuality", displayName: "higher quality"},
    ]
}


export { formatOptions, consumptionOptions, ageOptions, intentionsOptions, 
    medicalOptions, mostImportantOptions, technicalOptions, customerType, experienceType,
    strainTypeOptions, strengthOptions, durationOptions, timeOfDayOptions, qualityOptions
}; 