import UniversalQuestion from '../questionsv3/UniversalAvatarPrompt';
import { experienceType } from './../../utilities/menuOptions'

const WhatExperience = (props) => {
  
    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={experienceType.firstLineText}
              secondLineText={experienceType.secondLineText} 
              fullText={experienceType.fullText}
              answers={experienceType.options}
              progressValue={experienceType.progressValue}
              avatar={experienceType.avatar}
              route={experienceType.route}
              prevRoute={experienceType.prevRoute}
  
    />
  }


  export default WhatExperience;