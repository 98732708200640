// reducers/user.js

import { createReducer } from '@reduxjs/toolkit';

const initialState = {};

export default createReducer(initialState, {
  updateUser: (state, action) => {
    return {
        ...state,
        ...action.payload
    }
  }  
});