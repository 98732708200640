import React from "react";
import { useState, useEffect } from 'react';
import {MdLocationPin} from 'react-icons/md'
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <MdLocationPin style={{color: '#FF9438', fontSize: '3rem'}} />;

export default function Map(props){
    let defaultProps = {
        center: {
            lat:  33.999905,
            lng:  -118.465356
        },
        zoom: 12
    };

    let {user, mobile} = props;
    
    let [mapDefaults, setMapDefaults] = useState(defaultProps)

    useEffect(() => {
      if (user && user.lat) {
          let updatedMapDefault = {
              center: {
                  lat:  user.lat,
                  lng:  user.long
              },
              zoom: 11
          }
          setMapDefaults(updatedMapDefault)
        }
   
    }, []);


  return (
    // Important! Always set the container height explicitly
    <div style={{ height: mobile ? '180px' : '300px', width: '100%', borderRadius: '80px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCHTvihLNMlE3m3f5bUlfvSw_Am0QGA9YQ" }}
        defaultCenter={mapDefaults.center}
        defaultZoom={mapDefaults.zoom}
      >
        <AnyReactComponent
          lat={ mapDefaults.center.lat}
          lng={ mapDefaults.center.lng}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
  );
}
