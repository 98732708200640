import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSteps, Grid, Step, Stepper, StepIcon, StepIndicator, StepStatus, Progress, Box, Icon, Button, Input, AspectRatio, Center, Select, Radio, RadioGroup, Text, Flex, Heading, Image, Divider, Spacer, HStack, Badge, Stack, useRadio, UseRadioProps, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, GridItem, } from '@chakra-ui/react'
import { useRadioGroup } from '@chakra-ui/radio';
import { FaLocationArrow, FaPercent,FaArrowRight, FaStickyNote,  } from 'react-icons/fa';
import { LiaDirectionsSolid } from 'react-icons/lia';
import { Auth } from 'aws-amplify';
import { useAuthenticator} from '@aws-amplify/ui-react';
import { isUndefined } from 'lodash';

const steps = [
    { title: 'First', description: 'Contact Info' },
    { title: 'Second', description: 'Date & Time' },
    { title: 'Third', description: 'Select Rooms' },
  ]
  
//   const StepperToolOld = (step) => {
//     const { activeStep, setActiveStep } = useSteps({
//       index: 0,
//       count: steps.length,
//     })
  
//     // const activeStepText = steps[activeStep].description
  
//     const max = steps.length - 1
//     const progressPercent = (activeStep / max) * 100
  
//     return (
//       <Box position='relative'>
//         <Stepper size='sm' index={activeStep} gap='0' colorScheme={'white'}>
//           {steps.map((step, index) => (
//             <Step key={index} gap='0'>
//               <StepIndicator bg='#C5F277'>
//                 <StepStatus  bg='#C5F277' complete={<StepIcon  bg='#C5F277' borderWeight={'2px'} borderColor={'#C5F277'}/>} />
//               </StepIndicator>
//             </Step>
//           ))}
//         </Stepper>
//         <Progress
//             bg='#C5F277'
//             colorScheme={'white'}
//           color={'#C5F277'}
//           value={progressPercent}
//           position='absolute'
//           height='3px'
//           width='full'
//           top='10px'
//           zIndex={-1}
//         />
//       </Box>
//     )
//   }
const StepperTool = ({ index }) => {
    // Use the passed index prop directly as the activeStep
    const activeStep = index;

    const max = steps.length - 1;
    const progressPercent = (activeStep / max) * 100;

    return (
        <Box position='relative'>
            <Stepper size='sm' index={activeStep} gap='0' colorScheme={'white'}>
                {steps.map((step, idx) => (
                    <Step key={idx} gap='0'>
                        <StepIndicator bg='#C5F277' borderColor={'#ACACAC'}>
                            <StepStatus bg='#C5F277' complete={<StepIcon bg='#ACACAC'/>} />
                        </StepIndicator>
                    </Step>
                ))}
            </Stepper>
            <Progress
                bg='#C5F277'
                colorScheme={'white'}
                color={'#C5F277'}
                value={progressPercent}
                position='absolute'
                height='3px'
                width='full'
                top='10px'
                zIndex={-1}
            />
        </Box>
    );
}




const Checkout = (props) => {
    // const { user } = useAuthenticator((context) => [context.user]);
    const [activeStep, setActiveStep] = useState(0);
    console.log('activeStep', activeStep)
    const [orderDetails, setOrderDetails] = useState({
        subtotal: 0,
        tax: 0,
        deliveryFee: 1,
        total: 0
    });

    let user = '';
    function getFirstName(fullName) {
        return fullName.split(' ')[0];
    }
    const awsUser = Auth.user?.attributes?.name;
    console.log('awsUser', awsUser)
    let viewHeight = window.innerHeight;
    let mobile = props.isMobile;
    // let cart = props.cart
    let medicalPatient = false
    // let subtotal = 180    
    // let tax = 20
    // let deliveryFee = 1
    // let total = subtotal + tax + deliveryFee
    // let orderDetails = {
    //     subtotal: subtotal,
    //     tax: tax,
    //     deliveryFee: deliveryFee,
    //     total: total
    // }

    // cart.forEach(item => {
    //     subtotal += (Number(item.pricePerUnit) * Number(item.quantityInCart))
    // })
    let cart = [{name: "Super Lemon Haze", weight: "3.5g", subType: "Sativa", pricePerUnit: "60.00", quantityInCart: 1, image: "https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"}];
    // let cart = []
    const calculateSubtotal = () => {
        let total = 0
        cart.forEach(item => {
            total += (Number(item.pricePerUnit) * Number(item.quantityInCart))
        })
        return total
    }

    useEffect(() => {
        let subtotal = calculateSubtotal()
        let tax = subtotal * .12
        let deliveryFee = 0
        let total = (subtotal + tax) + deliveryFee;
        setOrderDetails({
            subtotal: subtotal,
            tax: tax,
            deliveryFee: deliveryFee,
            total: total
        })
    }, [])

    function PaymentOptionCard(props) {
        const { getInputProps, getCheckboxProps } = useRadio(props);
      
        const input = getInputProps();
        const checkbox = getCheckboxProps();
      
        return (
          <Box as="label">
            <input {...input} />
            <Box
              {...checkbox}
              cursor="pointer"
              borderWidth="2px"
              borderRadius="md"
              boxShadow="md"
              _checked={{
                borderColor: "orange.600",
                bg: "orange.50",
              }}
              _focus={{
                boxShadow: "outline",
              }}
              p={mobile ? 3 : 5}
              textAlign="center"
            > 
              <Center>
                <Image src={props.imageSrc} boxSize={mobile ? "40px" : "60px"} />
              </Center>
              <Text mt={2} fontSize="md" fontWeight="bold" color="black">
                {props.children}
              </Text>
            </Box>
          </Box>
        );
      }
      
    function PaymentSelectComponent() {
        const options = ["Cash", "Debit", "Crypto"];
        const { getRootProps, getRadioProps } = useRadioGroup({
          name: "framework",
          defaultValue: "Debit",
          onChange: console.log,
        });
      
        const group = getRootProps();
      
        return (
          
            <HStack p={3} w="full" justifyContent="center" alignItems="center">
                <Box {...group} w="100%">
                    <Stack spacing={mobile ? 4 : 20} direction="row" justifyContent="space-between" alignItems="center" isInline>
                    {options.map((value) => {
                        const radio = getRadioProps({ value });
                        return (
                        <PaymentOptionCard {...radio} imageSrc={`https://flowerly-app-storage.s3.amazonaws.com/assets/paymentOptions/${value.toLowerCase()}.webp`}>
                            {value}
                        </PaymentOptionCard>
                        );
                    })}
                    </Stack>
                </Box>
            </HStack>




        );
      }

    const CartComponent = ({cartItems}) => {
        console.log(cartItems.length)
        return (
            <Box>
                <Flex flexDir={'column'}>
                        <Box borderRadius={10} color={'black'} p={2} m={3} fontSize={'sm'}>
                        
                            {cartItems.length === 0 ? 
                                (
                                    <Box textAlign={'center'}>
                                        <Text fontSize={'large'} fontWeight={'bold'}>
                                            Your cart's empty. Add some products first!
                                        </Text>
                                            <Box  >
                                                <Center m={8} bg={'#C5F277'} borderRadius={20}>

                                                    <Image w={'96px'} src="https://flowerly-app-storage.s3.amazonaws.com/assets/ageVerification/ageWarning.webp"/>
                                                </Center>
                                            </Box>
                                        <Box flex={1} p={2}>
                                            <Link to={'/summary'}>
                                                <Button variant={'outline'} isFullWidth>Browser Products</Button>
                                            </Link>
                                        </Box>
                                    </Box>
                                ) : ( 
                                    cartItems.map(( item, i ) => {
                                    let quantity = item.quantityInCart
                                    let formatted_data = item
                                    return (
                                        <>
                                        {mobile ? (
                                            <Box key={i} borderRadius={10} marginTop={i !== 0 && 2}>
                                                <HStack w={"100%"}>
                                                    <Box flex={1} m={1} p={1}>
                                                        <Image src={formatted_data.image} />
                                                    </Box>
                                                    <Box flex={3} m={1}>
                                                        <Text fontSize={'sm'} fontWeight={'bold'}>{formatted_data.name}</Text>
                                                        <Text fontSize={'xs'}>{formatted_data.weight} • {formatted_data.subType}</Text>
                                                        <Select size='xs' variant='unstyled' placeholder='1' w={'60px'}>
                                                            <option value='2'>2</option>
                                                            <option value='3'>3</option>
                                                            <option value='4'>4</option>
                                                            <option value='5'>5</option>
                                                        </Select>
                                                    </Box>
                                                    <Box flex={1} mt={3} >
                                                        <Text fontSize={'xs'} fontWeight={'bold'}>${(Number(formatted_data.pricePerUnit) * Number(quantity)).toFixed(2)}</Text>
                                                    </Box>
                                                </HStack>
                                            </Box>
                                        ) : (
                                        <Box key={i} borderRadius={10} marginTop={i !== 0 && 4}>
                                            <Flex h={"80px"}>
                                                <Box flex={1} m={3} p={3}>
                                                    <Image src={formatted_data.image} boxSize="60px" h={'60px'} w={'60px'}/>
                                                </Box>
                                                <Box flex={4} m={3}>
                                                    <Text fontSize={'md'} fontWeight={'bold'}>{formatted_data.name}</Text>
                                                    <Text fontSize={'sm'}>{formatted_data.weight} • {formatted_data.subType}</Text>
                                                    <Select variant='unstyled' placeholder='1' w={'60px'} mt={1}>
                                                        <option value='2'>2</option>
                                                        <option value='3'>3</option>
                                                        <option value='4'>4</option>
                                                        <option value='5'>5</option>
                                                    </Select>
                                                </Box>
                                                <Box flex={1} m={3} >
                                                    <Text fontSize={'lg'} fontWeight={'bold'}>${(Number(formatted_data.pricePerUnit) * Number(quantity)).toFixed(2)}</Text>
                                                </Box>
                                            </Flex>
                                        </Box>
                                        )}
                                        </>
                                       
                                    )
                                }
                            )
                        )
                    }
                    </Box>
                    
                </Flex>
            </Box>
        )
    }


    const CheckoutButton = () => {
        return (
            <>
            {
                (isUndefined(user)) ? 
                (<Link to={'/authenticate'}>
                    <Button bgColor={'GrayText'} isFullWidth isDisabled={cart.length === 0}>Proceed to Checkout</Button>
                </Link>) :
                (<Link to={'/checkout/placeOrder'}>
                    <Button bgColor={'GrayText'} isFullWidth isDisabled={cart.length === 0}>Proceed to Checkout</Button>
                </Link>)
            }
            </>
        )
    }

    const MobileOrderConfirmed = () => {
        return (
            <Box >
                <Heading  paddingRight={'6vw'} paddingLeft={'6vw'} >
                    Order Confirmed
                </Heading>
                <Box p={2}  paddingRight={'6vw'} paddingLeft={'6vw'} >
                    <StepperTool index={activeStep}/>
                </Box>
                <Box overflowY={'scroll'} h={(viewHeight > 750) ? "70vh" : "60vh"}  paddingRight={'6vw'} paddingLeft={'6vw'} >
                    <Box bgColor={'rgba(255,255,255,.6)'} p={2} mb={4} mt={4} borderRadius={16} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'} pb={4}>
                        <Flex flexDir={'row'} p={2}>
                            <Box flex={3}>
                                <Text p={2} fontSize={'md'} fontWeight={'bold'} color={'black'}>Estimated Pickup Time: </Text>
                            </Box>
                            <Box flex={1}>
                                
                            <Text pt={2} bgGradient='linear(to-l, #FF9438, #FFDD56)' bgClip='text' fontSize={'lg'} stroke={'3px'} fontWeight={'bold'} >4:20 PM</Text>
                            </Box>     
                        </Flex >
                        <Box p={4}>
                            <Progress colorScheme="orange" size={'md'} borderRadius={'3rem'} isIndeterminate />
                        </Box>
                        <Flex >
                            <Center flex={1}>
                                <Text pb={4} pl={4} fontSize={'sm'}> We'll update you via email when your order is ready for pickup</Text>
                            </Center>
                        </Flex >
                    </Box>
                    <Box bgColor={'rgba(255,255,255,.6)'} p={2} mb={4} mt={4} borderRadius={16} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'} pb={4}>
                        <Text p={2} fontSize={'md'} fontWeight={'bold'} color={'black'}>Pickup Location</Text>
                        <HStack p={2}>
                            <Center p={4}>
                                <Icon as={FaLocationArrow} color={"#9CDF27"} fontSize={'xl'}/>
                            </Center>
                            <Box>
                                <Text fontSize={'md'} fontWeight={'bold'} color={'black'}>MedMen - Venice(Abbot Kinney)</Text>
                                <Text fontSize={'xs'} color={'blackAlpha.800'}>1310 Abbot Kinney Blvd, Venice, California 90291</Text>
                            </Box>     
                        </HStack>
                        <Center>
                            <Button colorScheme="black"  variant={'outline'}>
                                <Icon as={LiaDirectionsSolid} fontSize={'xl'} m={1}/>
                                Open in Maps
                            </Button>
                        </Center>
                    </Box>
                    
                    <Center mb={20}>
                        <Button bgColor={"#C5F277"} color={'black'}  variant={'outline'} colorScheme="black" m={2} onClick={() => {setActiveStep(1)}}>Back</Button>
                    </Center>
                </Box>
            </Box>
        )
    }

    const MobileContactAndPickup = () => {
        return (
            <Box >
                <Heading  paddingRight={'6vw'} paddingLeft={'6vw'} >
                    Pickup Details
                </Heading>
                <Box p={2}  paddingRight={'6vw'} paddingLeft={'6vw'} >
                    <StepperTool index={activeStep}/>
                </Box>
                <Box overflowY={'scroll'} h={(viewHeight > 750) ? "70vh" : "60vh"}  paddingRight={'6vw'} paddingLeft={'6vw'} >
                    <Box bgColor={'rgba(255,255,255,.6)'} p={2} mb={4} mt={4} borderRadius={16} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'} >
                        <Text p={2} fontSize={'md'} fontWeight={'bold'} color={'black'}>Contact Information</Text>
                        <Grid templateColumns="repeat(2, 1fr)" gap={4} p={2}>
                            <GridItem colSpan={1}>
                                <Text fontSize={'xs'} >First Name</Text>
                                <Input variant='flushed' fontWeight={'bold'} placeholder='Mia' />
                            </GridItem>
                            <GridItem colSpan={1}>
                                <Text fontSize={'xs'} >Last Name</Text>
                                <Input variant='flushed' fontWeight={'bold'} placeholder='Smith' />
                            </GridItem>
                            <GridItem colSpan={1}>
                                <Text fontSize={'xs'} >Email</Text>
                                <Input variant='flushed' fontWeight={'bold'} placeholder="msmith@me.com" />
                            </GridItem>
                            <GridItem colSpan={1}>
                                <Text fontSize={'xs'} >Mobile Number</Text>
                                <Input variant='flushed' fontWeight={'bold'} placeholder='312.120.1992' />
                            </GridItem>
                        </Grid>
                        <Box p={2}>
                            <Divider borderColor="black" marginTop={2} marginBottom={2} />
                        </Box>
                        <Text p={2} fontSize={'md'} fontWeight={'bold'} color={'black'}>Pickup Location</Text>
                        <HStack p={2}>
                            <Center p={4}>
                                <Icon as={FaLocationArrow} color={"#9CDF27"} fontSize={'xl'}/>
                            </Center>
                            <Box>
                                <Text fontSize={'md'} fontWeight={'bold'} color={'black'}>MedMen - Venice(Abbot Kinney)</Text>
                                <Text fontSize={'xs'} color={'blackAlpha.800'}>1310 Abbot Kinney Blvd, Venice, California 90291</Text>
                            </Box>     
                        </HStack>
                        <Center>
                            <Button colorScheme="black"  variant={'outline'}>
                                <Icon as={LiaDirectionsSolid} fontSize={'xl'} m={1}/>
                                Directions
                            </Button>
                        </Center>
                        <Box p={2}>
                            <Divider borderColor="black" marginTop={2} marginBottom={2} />
                        </Box>
                        <Text p={2} fontSize={'md'} fontWeight={'bold'} color={'black'}>Payment Options</Text>
                        <HStack p={2} w="full" justifyContent="center" alignItems="center">
                            <Box >
                                <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center" isInline>
                                    <PaymentSelectComponent />
                                </Stack>
                            </Box>
                        </HStack>


                    </Box>
                    <Center mb={20}>
                        <Button bgColor={"#C5F277"} color={'black'}  variant={'outline'} colorScheme="black" m={2} onClick={() => {setActiveStep(0)}}>Edit Cart</Button>
                        <Button bgColor={"#C5F277"} color={'black'}  variant={'outline'} colorScheme="black" m={2} onClick={() => {setActiveStep(2)}}>Place Order</Button>
                    </Center>
                </Box>
            </Box>
        )
    }

    const MobileReviewOrderAndCart = () => {
        return (
            <Box>
                <Heading paddingRight={'6vw'} paddingLeft={'6vw'} >
                    Review Order
                </Heading>
                <Box p={2} paddingRight={'6vw'} paddingLeft={'6vw'} >
                    <StepperTool index={activeStep}/>
                </Box>
                <Box overflowY={'scroll'} h={(viewHeight > 750) ? "70vh" : "60vh"} paddingRight={'6vw'} paddingLeft={'6vw'}>
                    <Box bgColor={'rgba(255,255,255,.6)'} p={2} mb={4} mt={4} borderRadius={16} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'} >
                        <Text p={2} fontSize={'md'} fontWeight={'bold'} color={'black'}>Order Details</Text>
                        <CartComponent cartItems={cart}/>
                        {
                            cart?.length > 0 && (
                                <Box>
                                    <Box p={2}>
                                        <Divider borderColor="black" marginTop={2} marginBottom={2} />
                                    </Box>
                                    <HStack ml={2} mr={2}>
                                        <Box>
                                            <Text fontSize={'xs'} fontWeight={'bold'} color={'blackAlpha.800'}>Subtotal</Text>
                                        </Box>
                                        <Spacer />
                                        <Box >
                                            <Text fontSize={'xs'}>${orderDetails.subtotal.toFixed(2)}</Text>
                                        </Box>
                                    </HStack>
                                    <HStack ml={2} mr={2}>
                                        <Box>
                                            <Text fontSize={'xs'} fontWeight={'bold'} color={'blackAlpha.800'}>Estimated Tax</Text>
                                        </Box>
                                        <Spacer />
                                        <Box >
                                            <Text fontSize={'xs'}>${orderDetails.tax.toFixed(2)}</Text>
                                        </Box>
                                    </HStack>
                                    <HStack ml={2} mr={2}>
                                        <Box>
                                            <Text fontSize={'xs'} fontWeight={'bold'} color={'blackAlpha.800'}>Reservation Discount</Text>
                                        </Box>
                                        <Spacer />
                                        <Box >
                                            <Text fontSize={'xs'} textDecoration={orderDetails.deliveryFee === 0 ? 'line-through' : null}>${orderDetails.deliveryFee.toFixed(2)}</Text>
                                        </Box>  
                                    </HStack>
                                    <Box p={2}>
                                        <Divider borderColor="black" marginTop={2} marginBottom={2} />
                                    </Box>
                                    <HStack ml={2} mr={2} mb={4}>
                                        <Box>
                                            <Text fontSize={'md'} fontWeight={'bold'} color={'blackAlpha.800'}>Total</Text>
                                        </Box>
                                        <Spacer />
                                        <Box >
                                            <Text fontSize={'md'} >${(orderDetails.total).toFixed(2)}</Text>
                                        </Box>
                                    </HStack>
                                </Box>
                            )
                        }
                    
                    </Box>
                    {
                        cart?.length > 0 && (
                            <Center mb={20}>
                                <Button bgColor={"#C5F277"} color={'black'}  variant={'outline'} colorScheme="black" w={'100%'} onClick={() => {setActiveStep(1)}}>Continue</Button>
                            </Center>
                        )
                    }
                </Box>
            </Box>
        )
    }


    const MobileCheckout = () => {
        const currentAvatarVideo = ["https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/ReviewYourOrder-v2.mp4", "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Ready_To_Place_Order-v2.mp4", "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Order_Placed-v2.mp4"]

        return (
            <Box  >
                <Center >
                    <Flex flexDir={'column'} width={'100%'}>
                        <Box mt={2} paddingRight={'6vw'} paddingLeft={'6vw'} >
                            <Box flex={1} h={"122px"} bgColor={'rgba(255,255,255,.4)'} borderRadius={'10px'} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}>
                                <HStack h={'100%'}>
                                    <Flex flex={1} width={'100%'} h={"100%"}>
                                        <AspectRatio 
                                            flexGrow={1}
                                            height={"100%"} 
                                            width={'100px'}>
                                            <video
                                            style={{borderRadius: "10px", boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)' }}
                                            title={'Video'}
                                            src={currentAvatarVideo[activeStep]}
                                            controls={false}
                                            autoPlay={true}
                                            playsInline={true}
                                            allowFullScreen={false}
                                            />
                                        </AspectRatio>
                                    </Flex>
                                    <Box p={3} width={'100%'} >
                                        {activeStep === 0 && <Heading fontSize='xs'>{'Take a moment to review your order.'}</Heading>}
                                        {activeStep === 1 && <Heading fontSize='xs'>{awsUser ? `${getFirstName(awsUser)}, are you ready to place your order?` : 'Are you ready to place your order?'}</Heading>}
                                        {activeStep === 2 && <Heading fontSize='xs'>{`Congratulations! You've placed your first order through Flowerly AI!`}</Heading>}
                                    </Box>
                                </HStack>
                            </Box>
                        </Box>
                        <Box mt={2}>
                            {activeStep === 0 && <MobileReviewOrderAndCart /> }
                            {activeStep === 1 &&  <MobileContactAndPickup /> }
                            {activeStep === 2 &&  <MobileOrderConfirmed /> }
                            
                        </Box>
                    </Flex>
                </Center>
            </Box>
        )
    }
    
    const DesktopCheckout = () => {
        return (
        <Box p={6}>
            <Flex >
                <Box flex={1}>
                    <Heading fontSize={'6xl'} fontWeight={'bold'}>Checkout</Heading>
                    <Stack alignItems="center" justifyContent="center">

                        <AspectRatio ratio={3 /4} 
                        m={8}
                        p={4} 
                        width="18vw" 
                        maxH="36vh" 
                        borderRadius={'2rem'} 
                        boxShadow={'30px 10px 42px  rgba(0, 0, 0, 0.2)'}
                        >
                            <video
                            style={{borderRadius: "30px"}}
                            title={'Video'}
                            src={"https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Ready_To_Place_Order-v2.mp4"}
                            controls={false}
                            autoPlay={true}
                            allowFullScreen={false}
                            />
                        </AspectRatio>
                        <Box
                        bgColor={'white'}
                        borderRadius={'2rem'}
                        boxShadow={'30px 10px 42px  rgba(0, 0, 0, 0.2)'}
                        width="25vw" 
                        minW={'15vw'}
                        p={4}
                        m={8}
                        >
                            <Box>
                                <Text p={3} fontSize={'3xl'} fontWeight={'bold'}>{awsUser ? `${getFirstName(awsUser)}, are you ready to place your order?` : 'Are you ready to place your order?'} </Text>
                            </Box>
                        </Box>
                        <HStack 
                        p={4}
                        m={8}>
                            <Link to={'/summary'}>
                                <Button colorScheme="orange" bgColor={'#FF9438'}>Continue Shopping</Button>
                            </Link>
                            <Link to={'/checkout/completeOrder'}>
                                <Button colorScheme="orange" bgColor={'#FF9438'}>Place Order</Button>
                            </Link>
                        </HStack>
                    </Stack>
                </Box>
                <Box flex={1} h="calc(100vh - 12px)" position="relative" p={6} overflowY="auto">
                    <Box bgColor={'whiteAlpha.600'} borderRadius={'1.2rem'}>
                        <Text fontSize={'3xl'} fontWeight={'bold'} p={8}>Pickup Information</Text>
                        <Flex flexDir={'column'} p={8}>
                            <Box>
                                <Text fontSize={'md'} fontWeight={'bold'} color={'blackAlpha.800'}>Contact Details</Text>
                            </Box>
                            <Box>
                                <HStack>
                                    <Box w={'100%'} p={3}>
                                        <Text fontSize={'xs'} >First Name</Text>
                                        <Input variant='flushed' fontWeight={'bold'} placeholder='Mia' />
                                    </Box>
                                    <Box w={'100%'} p={3}>
                                        <Text fontSize={'xs'} >Last Name</Text>
                                        <Input variant='flushed' fontWeight={'bold'} placeholder='Smith' />
                                    </Box>
                                </HStack>
                            </Box>
                            <Box>
                                <HStack>
                                    <Box w={'100%'} p={3}>
                                        <Text fontSize={'xs'} >Email</Text>
                                        <Input variant='flushed' fontWeight={'bold'} placeholder='msmith@me.com' />
                                    </Box>
                                    <Box w={'100%'} p={3}>
                                        <Text fontSize={'xs'} >Mobile Number</Text>
                                        <Input variant='flushed' fontWeight={'bold'} placeholder='312.120.1992' />
                                    </Box>
                                </HStack>
                            </Box>
                            <Divider borderColor="black" marginTop={4} marginBottom={8}/>
                            <Box>
                                <Text fontSize={'md'} fontWeight={'bold'} color={'blackAlpha.800'}>Pickup Point</Text>
                                <HStack p={3}>
                                    <Center p={4}>
                                        <Icon as={FaLocationArrow} color={"#9CDF27"} fontSize={'xl'}/>
                                    </Center>
                                    <Box>
                                        <Text fontSize={'md'} fontWeight={'bold'} color={'black'}>MedMen - Venice(Abbot Kinney)</Text>
                                        <Text fontSize={'xs'} color={'blackAlpha.800'}>1310 Abbot Kinney Blvd, Venice, California 90291</Text>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <Button colorScheme="black"  variant={'outline'}>
                                            <Icon as={LiaDirectionsSolid} fontSize={'xl'} m={1}/> 
                                            Directions
                                        </Button>
                                    </Box>
                                </HStack>
                            </Box>
                            <Divider borderColor="black" marginTop={4} marginBottom={8}/>
                            <Box marginBottom={'300px'}>
                                <Text fontSize={'md'} fontWeight={'bold'} color={'blackAlpha.800'}>Payment Options</Text>
                                <HStack p={3} w="100%" justifyContent="center" alignItems="center">
                                    <PaymentSelectComponent />
                                </HStack>

                            </Box>
                        </Flex>
                    </Box>
                </Box>
                <Box flex={1} h="calc(100vh - 12px)" position="relative" p={6} overflowY="auto">
                    <Box bgColor={'whiteAlpha.600'} overflowY="scroll" h={'100vh'} borderRadius={'1.2rem'}>
                        <Text fontSize={'3xl'} fontWeight={'bold'} p={8}>Order Details</Text>
                        <CartComponent cartItems={cart}/>
                        {
                            cart?.length > 0 && (
                                <Flex flexDir={'column'} p={8}>
                                    {/* <Box>
                                        <Text fontSize={'md'} fontWeight={'bold'} color={'blackAlpha.800'}>Promo Code</Text>
                                        <HStack p={3}>
                                            <Center p={4}>
                                                <Icon as={FaPercent} color={"#9CDF27"} fontSize={'xl'}/>
                                            </Center>
                                            <Box>
                                                <Text fontSize={'md'} fontWeight={'bold'} color={'black'}>Enter Promo Code</Text>
                                                <Text fontSize={'xs'} color={'blackAlpha.800'}>Enter your promo code to receive a discount</Text>
                                            </Box>
                                            <Spacer />
                                            <Box>
                                                <Button colorScheme="black"  variant={'outline'}>
                                                    <Icon as={FaArrowRight} fontSize={'xl'} m={1}/> 
                                                    Apply
                                                </Button>
                                            </Box>
                                        </HStack>
                                    </Box> */}
                                    <Divider borderColor="black" marginBottom={4}/>
                                    <Box>
                                        <Text fontSize={'md'} fontWeight={'bold'} color={'blackAlpha.800'}>Order Notes</Text>
                                        <HStack p={3}>
                                            <Center p={4}>
                                                <Icon as={FaStickyNote} color={"#9CDF27"} fontSize={'xl'}/>
                                            </Center>
                                            <Box>
                                                <Text fontSize={'md'} fontWeight={'bold'} color={'black'}>Notes For Dispensary</Text>
                                                <Text fontSize={'xs'} color={'blackAlpha.800'}>Add a note to your order</Text>
                                            </Box>
                                            <Spacer />
                                            <Box>
                                                <Button colorScheme="black"  variant={'outline'}>
                                                    <Icon as={FaArrowRight} fontSize={'xl'} m={1}/> 
                                                    Add Note
                                                </Button>
                                            </Box>
                                        </HStack>
                                        {
                                            medicalPatient ? (
                                                <Center>
                                                    <Text>Additional Discounts for Medical Patients will be applied at time of pickup in store.</Text>
                                                </Center>
                                            ) : null
                                        }
                                        
                                    </Box>
                                    <Divider borderColor="black" marginTop={4} marginBottom={8}/>
                                    <HStack>
                                        <Box>
                                            <Text fontSize={'md'} fontWeight={'bold'} color={'blackAlpha.800'}>Subtotal</Text>
                                        </Box>
                                        <Spacer />
                                        <Box >
                                            <Text>${orderDetails.subtotal.toFixed(2)}</Text>
                                        </Box>
                                    </HStack>
                                    <HStack>
                                        <Box>
                                            <Text fontSize={'md'} fontWeight={'bold'} color={'blackAlpha.800'}>Estimated Tax</Text>
                                        </Box>
                                        <Spacer />
                                        <Box >
                                            <Text>${orderDetails.tax.toFixed(2)}</Text>
                                        </Box>
                                    </HStack>
                                    <HStack>
                                        <Box>
                                            <Text fontSize={'md'} fontWeight={'bold'} color={'blackAlpha.800'}>Reservation Discount</Text>
                                        </Box>
                                        <Spacer />
                                        <Box >
                                            <Text>${orderDetails.deliveryFee.toFixed(2)}</Text>
                                        </Box>  
                                    </HStack>
                                    <Divider borderColor="black" marginTop={4} marginBottom={8}/>
                                    <HStack>
                                        <Box>
                                            <Text fontSize={'xl'} fontWeight={'bold'} color={'blackAlpha.800'}>Total</Text>
                                        </Box>
                                        <Spacer />
                                        <Box >
                                            <Text fontSize={'xl'} >${(orderDetails.total).toFixed(2)}</Text>
                                        </Box>
                                    </HStack>
                                    <Center>
                                        <Link to="/checkout/completeOrder">
                                            <Button colorScheme="green" bgColor={'#C5F277'} color={'black'}> Place Order </Button>
                                        </Link>
                                    </Center>
                                
                                </Flex>
                            )
                        }
                    </Box>
                </Box>
            </Flex>
        </Box>
        )
    }
    
    return (
        <>
        {mobile ? <MobileCheckout /> : <DesktopCheckout orderDetails /> }
        </>
    );
  }

export default Checkout;
  