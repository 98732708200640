import UniversalQuestion from '../questionsv3/UniversalAvatarPrompt';
import {consumptionOptions} from './../../utilities/menuOptions'

const ConsumptionMethod = (props) => {
  
    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={consumptionOptions.firstLineText}
              secondLineText={consumptionOptions.secondLineText} 
              fullText={consumptionOptions.fullText}
              answers={consumptionOptions.options}
              progressValue={consumptionOptions.progressValue}
              avatar={consumptionOptions.avatar}
              scroll={consumptionOptions.scroll}
              prevRoute={consumptionOptions.prevRoute}
              route={consumptionOptions.route}
              imageGrid={consumptionOptions.imageGrid}
    />
  }
  
export default ConsumptionMethod;