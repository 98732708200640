import { Link } from "react-router-dom";
import { Box, Flex, Heading, Spacer, Button, Container, Center, SimpleGrid, Text, Progress, AspectRatio } from '@chakra-ui/react';
import { has } from 'lodash'

const UniversalQuestion = (props) => {
    const { firstLineText, secondLineText, answers, extraColumns, extraSpacing, detailText, progressValue, avatar } = props;    

    return (
      <>
        <div className={'animate__animated animate__bounceInLeft'}>
            <Center paddingTop={props.isMobile ? 8 : 6}>
              <Progress w={props.isMobile ? '200px' : '40%' } colorScheme={'green'} size={props.isMobile && 'lg'} height={props.isMobile ? "1rem" : "32px"} value={progressValue} borderRadius={20} bg={"rgba(0, 0, 0, 0.4);"}/>
            </Center>
          <Flex paddingTop={props.isMobile ? 4 : 10} >
            <Box minW={props.isMobile ? 3 : 100} >
            </Box>
            <Box h='50vh' flex={1} bg={"rgba(0, 0, 0, 0.4);"} maxW='sm' color="white" style={{borderRadius: '2rem'}}>
              <Container style={{paddingTop: props.isMobile ? '60px' : '7vh'}}>
              <AspectRatio maxW='560px' ratio={1}>
                <video
                  style={{borderRadius: "30px"}}
                  title={'Video'}
                  width={"280px"}
                  height={"660px"}
                  src={avatar}
                  controls
                  autoPlay={true}
                  allowFullScreen={false}
                />
              </AspectRatio>
              </Container>
          </Box>
            <Box flex={1}></Box>
            <Box h='25vh' flex={1} bg={"rgba(0, 0, 0, 0.4);"} maxW='sm' color="white" style={{borderRadius: '2rem'}}>
              <Container style={{paddingTop: props.isMobile ? '60px' : '7vh'}}>
                <Heading fontSize='4xl'>{firstLineText}</Heading>
                <Text fontSize='2xl'>{secondLineText}</Text>
              </Container>
            </Box>
            <Box minW={3}>
            </Box>
          </Flex>
          
  
          <Flex paddingTop={20} >
            <Box >
            </Box>
            {props.isMobile ? null : <Spacer />}
            <Box h='25vh' flex={1} minW='3' color="white" style={{borderRadius: '2rem', paddingLeft: '10px'}}>
              <Center>
                {detailText}
              </Center>
              <SimpleGrid columns={extraColumns || 2} spacing={extraSpacing || 10}>
              {answers.map((item, i) => {
                return (
                <div key={item.displayName + i} >
                  
                      <Link key={item.displayName + i} to={item.route}>
                        {/* <Button isDisabled={has(item, 'disabled') ? true : false} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} variant='outline' size='md' borderRadius={9} height='60px' width='100%' onClick={() => props.handleAddUserData(item.saveDataEntryValue, item.saveDataEntryName)}> */}
                        <Button _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} outlineColor='green.600' variant='outline' size='md' borderRadius={9} height='60px' width='100%' onClick={() => props.handleAddUserData(item.saveDataEntryValue, item.saveDataEntryName)}>
                          <Flex flexDir={'column'}>
                            <Box flex={1}>
                            <Center color='green.600'>{item.displayName}</Center>
                            </Box>
                            {
                              has(item, 'subText') && (
                              <Box flex={1}>
                                <Text fontSize={'sm'} fontWeight={'normal'}>coming soon</Text>
                              </Box>
                              )
                            }
                          </Flex>
                        </Button>
                      </Link>
                   
                </div>  
                )
              })}
              </SimpleGrid>
            </Box>
            <Box minW={props.isMobile ? 3 : 100}>
            </Box>
          </Flex>
        </div>
      </> 
    )
  }

  export default UniversalQuestion;