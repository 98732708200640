import { configureStore } from '@reduxjs/toolkit';

import user from './reducers/user';
import quizResults from './reducers/quizResults';
import searchQuery from './reducers/searchQuery';
import summaryQuery from './reducers/summaryQuery';
import results from './reducers/results';
import selectedProduct from './reducers/selectedProduct';
import cart from './reducers/cart';
import pickupInfo from './reducers/pickupInfo';
import contactInfo from './reducers/contactInfo';
import order from './reducers/order';

import { combineReducers } from 'redux';

// Combine all reducers into a single reducer function
const rootReducer = combineReducers({
    user: user,
    quizResults: quizResults,
    searchQuery: searchQuery,
    summaryQuery: summaryQuery,
    results: results,
    selectedProduct: selectedProduct,
    cart: cart,
    pickupInfo: pickupInfo,
    contactInfo: contactInfo,
    order: order
});

// If you have a global initial state, define it. 
// But remember, each individual reducer should handle its own initial state.
const initialState = {
  user: {}
};


export default configureStore({
  reducer: rootReducer,
  preloadedState: initialState // Only add this if you have a specific need for a global initial state
});