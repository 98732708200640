// src/reducers/quizResults.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ageGroup: '',
  experienceLevel: '',
  visitReason: '',
  // ... up to field10
};

const quizResultsSlice = createSlice({
  name: 'quizResults',
  initialState,
  reducers: {
    // An action to update a specific field by its name and value
    updateField: (state, action) => {
        console.log('in reducer', action, action.payload)
      const { fieldName, value } = action.payload;
      state[fieldName] = value;
    },
    // You can add more actions here if needed
    resetQuizResults: (state) => {
      return initialState;
    },
  },
});

export const selectQuizResults = (state) => state.quizResults;

// Exporting the action creators
export const { updateField, resetQuizResults } = quizResultsSlice.actions;

// Exporting the reducer
export default quizResultsSlice.reducer;
