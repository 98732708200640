import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Box, Flex, Heading, Progress, Spacer, Button, Container, Center, SimpleGrid, Text, Checkbox, AspectRatio, useRadio, useRadioGroup, Stack, Badge, Grid, GridItem, HStack } from '@chakra-ui/react';
import { CheckIcon, CheckCircleIcon, ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { updateField } from '../../reducers/quizResults';
import useCheckRoute from '../hooks/useCheckRoute';
import { has } from 'lodash'


const UniversalQuestion = (props) => {
    const dispatch = useDispatch();
    const quizResults = useSelector(state => state.quizResults);
    const logicRoute = useCheckRoute('timeOfDay');
    let { firstLineText, secondLineText, fullText, route, simpleOptions, answers, extraColumns, extraSpacing, detailText, isMobile, progressValue, avatar, avatarChrome, avatarSafari, scroll, forcedRoute, prevRoute, multipleSelect, maxSelect, imageGrid } = props;  
    const totalAnswers = answers.length;
    const {experienceLevel} = quizResults;

    useEffect(() => {

        if (route === '/timeOfDay' && experienceLevel === 'experienced') {
            route = '/strainType';
            console.log("Route changed to strainType", route);
        }
    }, []);


    const viewHeight = window.innerHeight;
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    

    const handleDataSaveToRedux = (value, fieldName) => {
        dispatch(updateField({value, fieldName}));
    }

    function CheckboxCard({ value, isChecked, isComplete, toggleCheckbox, saveData, route }) {


        return isMobile ? (
            <Box as='label' >
                <Box 
                    marginTop="16px"
                    marginBottom={'4px'}
                    fontSize='1.2rem'
                    cursor='pointer'
                    borderWidth={isChecked ? '2px' : '1px'}
                    borderColor={isChecked ? '#9CDF27' : 'blackAlpha.300'}
                    borderRadius='md'
                    backgroundColor={(isComplete && !isChecked ? 'blackAlpha.300' : 'white')}
                    boxShadow={isChecked ? 'none' : 'md'}
                    fontWeight={isChecked ? 'bold' : 'normal'}
                    width="88vw"
                    px={5}
                    py={3}
                    onClick={() => {toggleCheckbox()}}
                    >

                    <Flex>
                        <Box flex={2}>{value.displayName}</Box>
                        <Box flex={1} textAlign={'right'} >
                            {isChecked && <CheckCircleIcon color={'#9CDF27'}/>}
                        </Box>
                    </Flex>
                </Box>
            </Box>
        ) :
        (

            <Box as='label' >
                <Box 
                    marginTop="16px"
                    marginBottom={'4px'}
                    fontSize='1.2rem'
                    cursor='pointer'
                    borderWidth={isChecked ? '2px' : '1px'}
                    borderColor={isChecked ? '#9CDF27' : 'blackAlpha.300'}
                    borderRadius='md'
                    backgroundColor={(isComplete && !isChecked ? 'blackAlpha.300' : 'white')}
                    boxShadow={isChecked ? 'none' : 'md'}
                    fontWeight={isChecked ? 'bold' : 'normal'}
                    px={5}
                    py={3}
                    onClick={() => {toggleCheckbox()}}
                    >

                    <Flex>
                        <Box flex={1}>{value.displayName}</Box>
                        <Box flex={1}></Box>
                        <Box flex={1} textAlign={'right'} >
                            {isChecked && <CheckCircleIcon color={'#9CDF27'}/>}
                        </Box>
                    </Flex>
                </Box>
            </Box>
        )
    }

    function ImageCard({ value, isChecked, isComplete, toggleCheckbox, route }) {
        return (
            <GridItem w='100%'>
                <Box 
                    marginTop="4px"
                    marginBottom={'8px'}
                    fontSize='1.4rem'
                    cursor='pointer'
                    borderWidth={isChecked ? '2px' : '1px'}
                    borderColor={isChecked ? '#9CDF27' : 'blackAlpha.300'}
                    borderRadius='md'
                    backgroundColor={(isComplete && !isChecked ? 'blackAlpha.300' : 'white')}
                    boxShadow={isChecked ? 'none' : 'md'}
                    fontWeight={isChecked ? 'bold' : 'normal'}
                    px={5}
                    py={5}
                    onClick={() => {
                        console.log("Checkbox clicked", value);
                        toggleCheckbox(value);}
                    }
                    >

                    <Flex>
                        
                        <Center flex={1}>{value.displayName}</Center>
                        <Box textAlign={'right'} >
                            {isChecked && <CheckCircleIcon color={'#9CDF27'}/>}
                        </Box>
                    </Flex>
                </Box>
            </GridItem>
        )
    }
    
    function FlowerlySelect({data, multipleSelect}) {
        const [isComplete, setIsComplete] = useState(false);
        const [selectedItems, setSelectedItems] = useState([]);
        const [nextRoute, setNextRoute] = useState(null);

        const handleDataSave = (item) => {
            handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName);
        }

        const handleDataSaveAllTypes = () => {
         
                if (selectedItems.length > 1) {
                    let saveMultipleValues = [];
                    selectedItems.forEach(item => {
                        saveMultipleValues.push(item.saveDataEntryValue);
                    })
                    return handleDataSaveToRedux(saveMultipleValues, selectedItems[0]["saveDataEntryName"]);
                } else {
                    selectedItems.forEach(item => {
                        handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName);
                    });

            }
        };


        const toggleCheckbox = useCallback((item) => {
            console.log("Selected items before toggle:", selectedItems);
        
            if (isComplete && !selectedItems.some(i => i.displayName === item.displayName)) {
                console.log("Item not added because it's complete and item is not selected");
                return;
            }
        
            setSelectedItems(prevSelectedItems => {
                // Check if item is already selected
                const itemIsSelected = prevSelectedItems.some(i => i.displayName === item.displayName);
        
                // If already selected, remove it
                if (itemIsSelected) {
                    setIsComplete(false);
                    setNextRoute(null); // Clear the next route if item is deselected
                    return prevSelectedItems.filter(i => i.displayName !== item.displayName);
                } 
                // For multiple select mode
                if (multipleSelect) {
                    if (prevSelectedItems.length < (maxSelect ? maxSelect : 3)) {
                        if (prevSelectedItems.length === ((maxSelect ? maxSelect - 1 : 2) )) {
                            setIsComplete(true);
                        }
                        setNextRoute(item.route); // Set the next route based on the selected item
                        return [...prevSelectedItems, item];
                    }
                }
        
                // For single select mode
                setNextRoute(item.route); // Set the next route based on the selected item
                return [item];
            });

            


        });

    
        return (
            <>
                {isMobile ? 
                (<Flex flexDir={'column'} height="100vh" justifyContent="space-between">
                            <Box overflowY={data.length > 4 ? 'scroll' : 'auto'} h={data.length > 4 && (viewHeight > 750) ? "55vh" : "38vh"}>
                               
                                {
                                    imageGrid && isMobile === false  ? (
                                        <Grid templateColumns='repeat(2, 1fr)' gap={8} maxH={'45vh'} overflowY={scroll ? 'scroll' : null}>        
                                            {data.map((item) => {
                                                const isChecked = selectedItems.map(i => i.displayName).includes(item.displayName);
                                                return (
                                                    <ImageCard key={item.displayName} value={item} isComplete={isComplete} isChecked={isChecked} toggleCheckbox={toggleCheckbox} route={item.route}/>
                                                    )
                                                })}
                                        </Grid>
                                    ) : (
                                        <Stack >        
                                            {data.map((item) => {
                                                const isChecked = selectedItems.map(i => i.displayName).includes(item.displayName);
                                                return (
                                                    <CheckboxCard 
                                                    key={item.displayName} 
                                                    value={item} 
                                                    isComplete={isComplete} 
                                                    isChecked={isChecked} 
                                                    toggleCheckbox={() => {
                                                        toggleCheckbox(item);
                                                    }} 
                                                    saveData={() => {
                                                        handleDataSave(item);
                                                    }}
                                                    route={item.route}/>
                                                    )
                                                })}
                                        </Stack>
                                    )
                                }

                            </Box>  
                            <Box flex={1}>
                            <Box textAlign={'center'} paddingTop={viewHeight > 670 ? "4rem" : "1rem"} mb=".4rem" color={'white'}>
                                <Link 
                                    to={nextRoute ? nextRoute : (route ? route : forcedRoute)} 
                                    onClick={(e) => {
                                        if (selectedItems.length < 1) {
                                            e.preventDefault();
                                        }
                                    }}
                                >
                                    <Button bgColor={selectedItems.length >= 1 ? '#FF9438' : 'rgba(255, 148, 56, .5)'} variant={'outline'} w={'88vw'} 
                                    _disabled={{
                                        bgColor: selectedItems.length === 0 ? 'rgba(255, 148, 56, .5)' : undefined,
                                        color: selectedItems.length === 0 ? 'alphaWhite.600' : undefined,
                                        cursor: 'not-allowed', // Optional: To show that the button can't be clicked
                                        pointerEvents: "none"
                                    }}
                                    onClick={handleDataSaveAllTypes}
                                    > 
                                        <HStack>
                                            <Text fontSize={'2xl'} >Continue</Text>
                                        </HStack>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                </Flex>) : (
                <Flex marginTop={'4vh'}>
                        <Box flex={1} textAlign={'center'}>
                            {
                                prevRoute && (
                                    <Box m={'auto'} paddingTop={"120px"}>
                                        <Link to={prevRoute} >
                                            <Button colorScheme='customColor' variant={'outline'} >
                                                <ArrowBackIcon />
                                            </Button>
                                        </Link>
                                    </Box>
                                )    
                            }
                        </Box>
                        <Box flex={6} >
                            <Box>
                                {(multipleSelect ) && (
                                    <Flex>
                                            {selectedItems.length > 0 ? (selectedItems.map((item) => {
                                                return (
                                                    <Stack flex={1} textAlign={'center'} bgColor={'blackAlpha.100'} borderColor='#9CDF27' borderWidth={'medium'} borderRadius={'2xl'} m={'4'}>
                                                        {/* <Avatar src='https://bit.ly/sage-adebayo' /> */}
                                                        <Box ml='3' >
                                                            <Text fontSize={'4xl'} fontWeight='bold'>
                                                            {item.displayName}
                                                            </Text>
                                                        </Box>
                                                    </Stack>
                                                )
                                            })) : 
                                            <Stack flex={1} textAlign={'center'} m={'4'}>    
                                                <Box ml='3' >
                                                    <Text fontSize={'4xl'} >
                                                    select up to three effects
                                                    </Text>
                                                </Box>
                                            </Stack>}
                                    </Flex>
                                )}
                                {
                                    imageGrid ? (
                                        <Grid templateColumns='repeat(2, 1fr)' gap={8} maxH={'45vh'} overflowY={scroll ? 'scroll' : null}>        
                                            {data.map((item) => {
                                                const isChecked = selectedItems.map(i => i.displayName).includes(item.displayName);
                                                console.log('test bitch')
                                                return (
                                                    <ImageCard key={item.displayName} value={item} isComplete={isComplete} isChecked={isChecked} toggleCheckbox={toggleCheckbox} route={item.route}/>
                                                    )
                                                })}
                                        </Grid>
                                    ) : (
                                        <Stack maxH={'40vh'} overflowY={multipleSelect ? 'scroll' : null}>        
                                            {data.map((item) => {
                                                const isChecked = selectedItems.map(i => i.displayName).includes(item.displayName);
                                                return (
                                                    <CheckboxCard 
                                                    key={item.displayName} 
                                                    value={item} 
                                                    isComplete={isComplete} 
                                                    isChecked={isChecked} 
                                                    toggleCheckbox={() => {
                                                        toggleCheckbox(item);
                                                    }} 
                                                    saveData={() => {
                                                        handleDataSave(item);
                                                    }}
                                                    route={item.route}/>
                                                    )
                                                })}
                                        </Stack>
                                    )
                                }

                            </Box>   
                        </Box>
                        <Box flex={1} textAlign={'center'}>
                            <Box m={'auto'} paddingTop={"120px"}>
                                <Link to={nextRoute ? nextRoute : (route)} >
                                    <Button colorScheme='customColor' variant={'outline'} 
                                    onClick={handleDataSaveAllTypes}> 
                                        <ArrowForwardIcon />
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Flex>
                )}
            </>
            
         
        )
    }
    
   

    const mobileView = <Box maxHeight='88vh'>
                            <Center >
                                <Flex flexDir={'column'} paddingRight={'6vw'} paddingLeft={'6vw'} width={'100%'}>
                                    <Box flex={1} h={"122px"} bgColor={'rgba(255,255,255,.4)'} borderRadius={'10px'} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}>
                                        <HStack h={'100%'}>
                                            <Flex flex={1} width={'100%'} h={"100%"}>
                                                <AspectRatio 
                                                    flexGrow={1}
                                                    height={"100%"} 
                                                    width={'100px'}>
                                                    <video
                                                    style={{borderRadius: "10px", boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)' }}
                                                    title={'Video'}
                                                    src={avatar}
                                                    controls={false}
                                                    autoPlay={true}
                                                    playsInline={true}
                                                    allowFullScreen={false}
                                                    />
                                                </AspectRatio>
                                            </Flex>
                                            <Box p={3} width={'100%'} >
                                                <Heading fontSize='xl'>{fullText}</Heading>
                                            </Box>
                                        </HStack>    
                                    </Box>
                                    <Box flex={1} pt={4} >
                                    <Progress 
                                        size='lg' 
                                        value={progressValue}
                                        color="white"
                                        bg="whiteAlpha.800"
                                        borderRadius="md"
                                        position="relative"
                                        _before={{
                                            bg: "linear-gradient(to right, #FFDD56, #FF9438)",
                                            position: "absolute",
                                            content: '""',
                                            top: "0",
                                            left: "0",
                                            height: "100%",
                                            width: `${progressValue}%`  // This should match the value prop of the Progress component
                                        }}
                                    />

                                    </Box>
                                    <Box flex={1} style={{borderRadius: '2rem', paddingTop: "4vh", paddingBottom:"8vh", marginBottom: '40px', maxHeight: '200px', }}>
                                        <Center fontSize={'md'}>
                                            {detailText}
                                        </Center>
                                        <Center>
                                            <FlowerlySelect data={answers} multipleSelect={multipleSelect}/>
                                    </Center>
                                    </Box>
                                </Flex>
                            </Center>
                       </Box>

    const desktopBackground = <Box w={'100%'}> 
                                  <Box borderTop="1px" borderColor="black" width="100vw" position="fixed" ></Box>
                                  <Flex>
                                    <Box flex={2}>
                                        <Center >
                                            <AspectRatio maxW='560px' w={'100%'} ratio={3 /4} m={['8']} marginTop={'4vh'} p={2} >
                                                <video
                                                style={{borderRadius: "30px"}}
                                                title={'Video'}
                                                src={avatar}
                                                controls={false}
                                                autoPlay={true}
                                                allowFullScreen={false}
                                                />
                                            </AspectRatio>
                                        </Center>
                                    </Box>
                                    <Box borderLeft="1px" borderColor="black" height="85vh" position="fixed" left="40%" mr={4}></Box>
                                    <Box flex={[2,3]}>
                                        <Box paddingLeft={['30px', '60px']} marginTop={'4vh'}>
                                            <Heading fontSize={'7xl'} fontWeight={'bold'}>
                                                {firstLineText}
                                            </Heading>
                                            <Text fontSize={'4xl'} fontWeight={'bold'}>
                                                {secondLineText}
                                            </Text>
                                        </Box>
                                        <FlowerlySelect data={answers} multipleSelect={multipleSelect}/>
                                    </Box>
                                  </Flex>
                              </Box>                                        
        
    return (
      <>
        <div className={'animate__animated animate__fadeIn'}>
            {isMobile ? mobileView : desktopBackground}
        </div>
      </> 
    )
  }

  export default UniversalQuestion;

