import { useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { updateField } from '../../reducers/quizResults';
import { Box, Flex, Heading, Text, AspectRatio, Center, Slider, SliderMark, SliderThumb, SliderTrack, Button, Image, HStack, Progress  } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { MdGraphicEq } from 'react-icons/md';
import { strengthOptions } from './../../utilities/menuOptions'

const TypeOfEffect = (props) => {
    const dispatch = useDispatch();
    const { isMobile } = props;
    const viewHeight = window.innerHeight;
    const fullText = "What type of effect are you looking for?";
    const { route, prevRoute, avatar, progressValue } = strengthOptions;
    const [strengthValue, setStrengthValue] = useState("strength");
    const [strengthDisplayValue, setStrengthDisplayValue] = useState("strength 💪");
    const [durationValue, setDurationValue] = useState("duration");
    const [durationDisplayValue, setDurationDisplayValue] = useState("duration ⏱️");

    const handleTranslatedStrengthUpdate = (value) => {
        if (value <= 25) {
            setStrengthValue("lightest");
            setStrengthDisplayValue("lightest 🕊️");
        } else if (value > 25 && value <= 50) {
            setStrengthValue("lighter");
            setStrengthDisplayValue("lighter 🍃");
        } else if (value > 50 && value <= 75) {
            setStrengthValue("stronger");
            setStrengthDisplayValue("stronger 💪");
        } else if (value > 75) {
            setStrengthValue("strongest");
            setStrengthDisplayValue("strongest 🏋️‍♀️");
        }
    }

    const handleTranslatedDurationUpdate = (value) => {
        if (value <= 25) {
            setDurationValue("shortest");
            setDurationDisplayValue("shortest ⏳");
        } else if (value > 25 && value <= 50) {
            setDurationValue("shorter");
            setDurationDisplayValue("shorter ⌛");
        } else if (value > 50 && value <= 75) {
            setDurationValue("longer");
            setDurationDisplayValue("longer 🕰️");
        } else if (value > 75) {
            setDurationValue("longest");
            setDurationDisplayValue("longest 🕗");
        }
    }

    const handleDataSaveToRedux = () => {
      dispatch(updateField({value: durationValue, fieldName: "preferredDuration"}));
      dispatch(updateField({value: strengthValue, fieldName: "preferredStrength"}));
  }
    
  const mobileView = <Box maxHeight='88vh'>
                        <Center >
                            <Flex flexDir={'column'} paddingRight={'6vw'} paddingLeft={'6vw'} width={'100%'}>
                                <Box flex={1} h={"122px"} bgColor={'rgba(255,255,255,.4)'} borderRadius={'10px'} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}>
                                    <HStack h={'100%'}>
                                        <Flex flex={1} width={'100%'} h={"100%"}>
                                            <AspectRatio 
                                                flexGrow={1}
                                                height={"100%"} 
                                                width={'100px'}>
                                                <video
                                                style={{borderRadius: "10px", boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)' }}
                                                title={'Video'}
                                                src={avatar}
                                                controls={false}
                                                autoPlay={true}
                                                playsInline={true}
                                                allowFullScreen={false}
                                                />
                                            </AspectRatio>
                                        </Flex>
                                        <Box p={3} width={'100%'} >
                                            <Heading fontSize='xl'>{fullText}</Heading>
                                        </Box>
                                    </HStack>    
                                </Box>
                                <Box flex={1} pt={4} >
                                <Progress 
                                    size='lg' 
                                    value={progressValue}
                                    color="white"
                                    bg="whiteAlpha.800"
                                    borderRadius="md"
                                    position="relative"
                                    _before={{
                                        bg: "linear-gradient(to right, #FFDD56, #FF9438)",
                                        position: "absolute",
                                        content: '""',
                                        top: "0",
                                        left: "0",
                                        height: "100%",
                                        width: `${progressValue}%`  // This should match the value prop of the Progress component
                                    }}
                                />

                                </Box>
                                <Box flex={1} >
                                    <Center fontSize={'md'}>
                                    
                                  <Box h='20vh'  color="white" style={{borderRadius: '1rem'}} textAlign={"-webkit-center"}>
                                      <Box position="relative" w="48vw" h="10vh" padding="20px">
                                          
                                          <Center 
                                              position="absolute"
                                              top="0"
                                              left="0"
                                              w="100%"
                                              h="100%"
                                              color={'blackAlpha.900'}
                                              fontWeight='semibold'
                                              letterSpacing='wide'
                                              fontSize='md'
                                          >
                                              {strengthDisplayValue} products
                                          </Center>
                                      </Box>
                                      
                                      <Box bg={"white"}  color="black" style={{borderRadius: '1rem'}}>
                                          <Flex paddingTop={2} padding={8} display={"inline-block"}>
                                          <Slider
                                              width={'74vw'}
                                              id='slider-strength'
                                              defaultValue={50}
                                              min={0}
                                              max={100}
                                              color={'black'}
                                              colorScheme='teal'
                                              onChangeEnd={(v) => handleTranslatedStrengthUpdate(v)}
                                              fontWeight={'bold'}
                                              >
                                              <SliderMark value={2} mt='1' ml='-2.5' fontSize='sm' paddingTop={2}>
                                              lightest
                                              </SliderMark>
                                              <SliderMark value={28} mt='1' ml='5.5' fontSize='sm' paddingTop={2}>
                                              lighter
                                              </SliderMark>
                                              <SliderMark value={60} mt='1' ml='-2.5' fontSize='sm' paddingTop={2}>
                                              stronger
                                              </SliderMark>
                                              <SliderMark value={88} mt='1' ml='.5' fontSize='sm' paddingTop={2}>
                                              strongest
                                              </SliderMark>
                                              <SliderTrack/>
                                              <SliderThumb boxSize={6} bgColor='#C8F382' >
                                                <Box  color='black' as={MdGraphicEq} />
                                              </SliderThumb>
                                          </Slider>
                                          </Flex> 
                                      </Box>
                                  
                                      <Box position="relative" w="48vw" h="10vh" padding="20px">
                                          
                                          <Center 
                                              position="absolute"
                                              top="0"
                                              left="0"
                                              w="100%"
                                              h="100%"
                                              color={'blackAlpha.900'}
                                              fontWeight='semibold'
                                              letterSpacing='wide'
                                              fontSize='md'
                                          >
                                              {durationDisplayValue} onset
                                          </Center>
                                      </Box>
                                      
                                      <Box bg={"white"}  color="black" style={{borderRadius: '1rem'}}>
                                          <Flex paddingTop={2} padding={8} display={"inline-block"}>
                                          <Slider
                                              width={'75vw'}
                                              id='slider-duration'
                                              defaultValue={50}
                                              min={0}
                                              max={100}
                                              colorScheme='teal'
                                              onChangeEnd={(v) => handleTranslatedDurationUpdate(v)}
                                              fontWeight={'bold'}
                                              >
                                              <SliderMark value={2} mt='1' ml='-2.5' fontSize='sm' paddingTop={2}>
                                              shortest
                                              </SliderMark>
                                              <SliderMark value={30} mt='1' ml='5.5' fontSize='sm' paddingTop={2}>
                                              shorter
                                              </SliderMark>
                                              <SliderMark value={64} mt='1' ml='-2.5' fontSize='sm' paddingTop={2}>
                                              longer
                                              </SliderMark>
                                              <SliderMark value={90} mt='1' ml='.5' fontSize='sm' paddingTop={2}>
                                              longest
                                              </SliderMark>
                                              <SliderTrack/>
                                              <SliderThumb boxSize={6} bgColor='#C8F382' >
                                                <Box  color='black' as={MdGraphicEq} />
                                              </SliderThumb>
                                          </Slider>
                                          </Flex> 
                                      </Box>
                                  </Box>
                              
                                    </Center>
                                </Box>
                                <Box flex={1} textAlign={'center'} paddingTop={viewHeight > 750 ? "20rem" : "15rem"}  color={'white'}>
                                  <Link 
                                      to={(route)} 
                                      onClick={(e) => {
                                          if (durationValue === "duration" || strengthValue === "strength") {
                                              e.preventDefault();
                                          }
                                      }}
                                  >
                                      <Button bgColor={durationValue === "duration" || strengthValue === "strength" ?  'rgba(255, 148, 56, .5)' : '#FF9438' } variant={'outline'} w={'88vw'} 
                                      
                                      onClick={handleDataSaveToRedux}
                                      > 
                                          <HStack>
                                              <Text fontSize={'2xl'} >Continue</Text>
                                          </HStack>
                                      </Button>
                                  </Link>
                                </Box>
                                
                            </Flex>
                        </Center>
                      </Box>
  const desktopView = <div className={'animate__animated animate__fadeIn'}>
                        <Box w={'100%'}> 
                          <Box borderTop="1px" borderColor="black" width="100vw" position="fixed" ></Box>
                          <Flex>
                            <Box flex={2}>
                              <Center>
                              <AspectRatio maxW='560px' width={'100%'} ratio={3 /4} margin={'8'} marginTop={'4vh'} p={2}>
                                <video
                                  style={{borderRadius: "30px"}}
                                  title={'Video'}
                                  src={avatar}
                                  controls={false}
                                  autoPlay={true}
                                  allowFullScreen={false}
                                />
                              </AspectRatio>
                              </Center>
                            </Box>
                            <Box borderLeft="1px" borderColor="black" height="85vh" position="fixed" left="40%"></Box>
                            <Box flex={3}>
                              <Box paddingLeft={'60px'} marginTop={'4vh'}>
                                <Heading fontSize={'7xl'} fontWeight={'bold'}>
                                  What type
                                </Heading>
                                <Text fontSize={'4xl'} fontWeight={'bold'}>
                                  of effect are you looking for?
                                </Text>
                              </Box>
                              <Flex marginTop={'4vh'}>
                                <Box flex={1} textAlign={'center'}>
                                  <Box m={'auto'} paddingTop={"120px"}>
                                    <Link to={prevRoute}>
                                      <Button colorScheme='customColor' variant={'outline'} >
                                        <ArrowBackIcon />
                                      </Button>
                                    </Link>
                                  </Box>
                                </Box>
                                <Box flex={6} >
                                  <Box h='20vh' flex={1} color="white" style={{borderRadius: '2rem'}} textAlign={"-webkit-center"}>
                                      <Box position="relative" w="48vw" h="10vh" padding="20px">
                                          {/* <Image 
                                              className="animate__animated animate__fadeIn animate__slow"
                                              w="100%"
                                              h="100%"
                                              src={`https://flowerly-app-storage.s3.amazonaws.com/assets/strength/a-${strengthValue}.webp`}
                                              borderRadius="10"
                                              objectFit="cover"
                                              objectPosition="80% 80%"
                                          /> */}
                                          <Center 
                                              position="absolute"
                                              top="0"
                                              left="0"
                                              w="100%"
                                              h="100%"
                                              color={'blackAlpha.900'}
                                              fontWeight='semibold'
                                              letterSpacing='wide'
                                              fontSize='4xl'
                                          >
                                              {strengthDisplayValue} products
                                          </Center>
                                      </Box>
                                      
                                      <Box bg={"white"}  color="black" style={{borderRadius: '2rem'}}>
                                          <Flex paddingTop={2} padding={8} display={"inline-block"}>
                                          <Slider
                                              width={'40vw'}
                                              id='slider-strength'
                                              defaultValue={50}
                                              min={0}
                                              max={100}
                                              color={'black'}
                                              colorScheme='teal'
                                              onChangeEnd={(v) => handleTranslatedStrengthUpdate(v)}
                                              fontWeight={'bold'}
                                              >
                                              <SliderMark value={2} mt='1' ml='-2.5' fontSize='lg' paddingTop={2}>
                                              lightest
                                              </SliderMark>
                                              <SliderMark value={28} mt='1' ml='5.5' fontSize='lg' paddingTop={2}>
                                              lighter
                                              </SliderMark>
                                              <SliderMark value={60} mt='1' ml='-2.5' fontSize='lg' paddingTop={2}>
                                              stronger
                                              </SliderMark>
                                              <SliderMark value={88} mt='1' ml='.5' fontSize='lg' paddingTop={2}>
                                              strongest
                                              </SliderMark>
                                              <SliderTrack/>
                                              <SliderThumb boxSize={6} bgColor='#C8F382' >
                                                <Box  color='black' as={MdGraphicEq} />
                                              </SliderThumb>
                                          </Slider>
                                          </Flex> 
                                      </Box>
                                  
                                      <Box position="relative" w="48vw" h="10vh" padding="20px">
                                          {/* <Image 
                                              className="animate__animated animate__fadeIn animate__slow"
                                              w="100%"
                                              h="100%"
                                              src={`https://flowerly-app-storage.s3.amazonaws.com/assets/duration/a-${durationValue}.webp`}
                                              borderRadius="10"
                                              objectFit="cover"
                                              objectPosition="80% 80%"
                                          /> */}
                                          <Center 
                                              position="absolute"
                                              top="0"
                                              left="0"
                                              w="100%"
                                              h="100%"
                                              color={'blackAlpha.900'}
                                              fontWeight='semibold'
                                              letterSpacing='wide'
                                              fontSize='4xl'
                                          >
                                              {durationDisplayValue} onset
                                          </Center>
                                      </Box>
                                      
                                      <Box bg={"white"}  color="black" style={{borderRadius: '2rem'}}>
                                          <Flex paddingTop={2} padding={8} display={"inline-block"}>
                                          <Slider
                                              width={'40vw'}
                                              id='slider-duration'
                                              defaultValue={50}
                                              min={0}
                                              max={100}
                                              colorScheme='teal'
                                              onChangeEnd={(v) => handleTranslatedDurationUpdate(v)}
                                              fontWeight={'bold'}
                                              >
                                              <SliderMark value={2} mt='1' ml='-2.5' fontSize='lg' paddingTop={2}>
                                              shortest
                                              </SliderMark>
                                              <SliderMark value={30} mt='1' ml='5.5' fontSize='lg' paddingTop={2}>
                                              shorter
                                              </SliderMark>
                                              <SliderMark value={64} mt='1' ml='-2.5' fontSize='lg' paddingTop={2}>
                                              longer
                                              </SliderMark>
                                              <SliderMark value={90} mt='1' ml='.5' fontSize='lg' paddingTop={2}>
                                              longest
                                              </SliderMark>
                                              <SliderTrack/>
                                              <SliderThumb boxSize={6} bgColor='#C8F382' >
                                                <Box  color='black' as={MdGraphicEq} />
                                              </SliderThumb>
                                          </Slider>
                                          </Flex> 
                                      </Box>
                                  </Box>
                              </Box>
                                <Box flex={1} textAlign={'center'}>
                                  <Box m={'auto'} paddingTop={"120px"}>
                                    <Link to={route}>
                                      <Button colorScheme='customColor' variant={'outline'} onClick={handleDataSaveToRedux}>
                                        <ArrowForwardIcon />
                                      </Button>
                                    </Link>
                                  </Box>
                                </Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Box>                                
                      </div>

    return (
      <>
        {isMobile ? mobileView : desktopView}
      </> 
    )
}

export default TypeOfEffect;
