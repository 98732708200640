import UniversalQuestion from '../questionsv3/UniversalAvatarPrompt';
import { customerType } from './../../utilities/menuOptions'

const VisitReason = (props) => {

    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={customerType.firstLineText}
              secondLineText={customerType.secondLineText} 
              fullText={customerType.fullText}
              answers={customerType.options}
              progressValue={customerType.progressValue}
              avatar={customerType.avatar}
              prevRoute={customerType.prevRoute}
  
    />
  }
  
  export default VisitReason;
