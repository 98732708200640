// src/reducers/searchQuery.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    humanSearchQuery: ''
};

const searchQuerySlice = createSlice({
  name: 'humanSearchQuery',
  initialState,
  reducers: {
    // An action to update a specific field by its name and value
    updateSearch: (state, action) => {
      const { value } = action.payload;
      console.log('value BITCHHHHHHHHH', value);
      state['humanSearchQuery'] = value;
    },
    // You can add more actions here if needed
    resetsearchQuery: (state) => {
      return initialState;
    },
  },
});

export const selectSearchQuery = (state) => state.searchQuery.humanSearchQuery;


// Exporting the action creators
export const { resetsearchQuery, updateSearch } = searchQuerySlice.actions;

// Exporting the reducer
export default searchQuerySlice.reducer;
