import { useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { updateSearch, selectSearchQuery } from '../reducers/searchQuery';
import { updateField, selectQuizResults } from '../reducers/quizResults';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { Box, Flex, Heading, Divider, Image, Spacer, Button, Container, Center, CircularProgress, CircularProgressLabel, Text, Checkbox, AspectRatio, useRadio, useRadioGroup, Stack, Badge, Grid, GridItem, HStack } from '@chakra-ui/react';
import { CheckIcon, CheckCircleIcon, ArrowForwardIcon, ArrowBackIcon, StarIcon, TriangleDownIcon } from '@chakra-ui/icons'
import { IoOptionsOutline } from 'react-icons/io5';
import { LuShoppingCart } from 'react-icons/lu'
import { formatOptions, consumptionOptions, ageOptions, intentionsOptions, 
    medicalOptions, mostImportantOptions, technicalOptions, customerType, experienceType,
    strengthOptions, durationOptions, timeOfDayOptions, qualityOptions } from '../utilities/menuOptions'
// import { BsCheckSquareFill, BsSquare } from 'react-i'
import { GrCart } from 'react-icons/gr'
import { has } from 'lodash'


const Summary = (props) => {
    const { isMobile } = props;
    const dispatch = useDispatch();
    const quizResults = useSelector(selectQuizResults);
    const searchQuery = useSelector(selectSearchQuery);
    const [showSearchOptions, setShowSearchOptions] = useState(false);

    const addPrefix = (inputStr) => {
        if (typeof inputStr !== 'string') {
            return null;  // or handle it differently if you want
        }
      
        // Check if the string ends with "est"
        if (inputStr.slice(-3) === 'est') {
            return 'the ' + inputStr;
        } else {
            return 'a ' + inputStr;
        }
    }

    const viewHeight = window.innerHeight;
    const viewWidth = window.innerWidth;
    
    const computeAndSaveQuery = () => {
        
        const { consumptionMethod, intentionFeelings, preferredQuality, preferredStrength, preferredTimeOfDay, visitReason } = quizResults;
        const updatedPreferredStrength = addPrefix(preferredStrength);

        // function joinStrings(arr) {
        //     console.log('what is this', arr, typeof arr )
        //     // let parsed = JSON.parse(arr);
        //     // return parsed.join(', ');
        // }
        // const updatedIntentionFeelings = joinStrings(intentionFeelings)
        const constructedSearchQuery = `${preferredQuality.charAt(0).toUpperCase() + preferredQuality.slice(1)} ${visitReason} ${consumptionMethod} with ${updatedPreferredStrength} effect for ${intentionFeelings} ${preferredTimeOfDay}s.`
        dispatch(updateSearch({value: constructedSearchQuery}));
    
        return constructedSearchQuery;
    }

    const handleOptionsToggle = () => {
        searchQuery?.length > 0 && (
            computeAndSaveQuery()
        )
        setShowSearchOptions(!showSearchOptions);
        
    }

    const handleDataSaveToRedux = (value, fieldName) => {
        dispatch(updateField({value, fieldName}));
    }
    
   const FlowerlyCards = () => {
    const [cardSelected, setCardSelected] = useState(false)
    
    
    const handleCardSelected = (i) => {
        setCardSelected(i)
    }

    const fakeData = [{name: "Super Lemon Haze", brand: "matter", recommendationScore: 95, strainType: 'Sativa', timeOfDay: ['morning, daytime'], effects: ['happy', 'energetic', 'creative'], flavors: ['citrus', 'lemon', 'sweet'], terpenes: ['limonene', 'pinene', 'myrcene'], thc: 0.25, cbd: 0.05, cbn: 0.01, image: "https://images.squarespace-cdn.com/content/v1/62929ff218fbff1499eba77a/a177695e-c87d-451d-8a6e-6738a1dae838/Northern+Emeralds+-+8th+Jar+and+Nug+-+Emerald+OG_2+%281%29.jpg?format=750w"},
    {name: "Electric Blue", brand: "Connect", recommendationScore: 94, strainType: 'Sativa', timeOfDay: ['morning, daytime'], effects: ['happy', 'creative'], flavors: ['citrus', 'lemon'], terpenes: ['limonene', 'pinene', 'myrcene'], thc: 0.25, cbd: 0.05, cbn: 0.01, image: "https://images.squarespace-cdn.com/content/v1/62929ff218fbff1499eba77a/a177695e-c87d-451d-8a6e-6738a1dae838/Northern+Emeralds+-+8th+Jar+and+Nug+-+Emerald+OG_2+%281%29.jpg?format=750w"},
    {name: "Honey Flower", brand: "a golden state", recommendationScore: 93, strainType: 'Sativa', timeOfDay: ['morning', 'daytime'], effects: ['uplifting', 'calming', 'creative'], flavors: ['melon', 'tangy', 'earthy'], terpenes: ['limonene', 'pinene', 'myrcene'], thc: 0.25, cbd: 0.05, cbn: 0.01, image: "https://images.squarespace-cdn.com/content/v1/62929ff218fbff1499eba77a/a177695e-c87d-451d-8a6e-6738a1dae838/Northern+Emeralds+-+8th+Jar+and+Nug+-+Emerald+OG_2+%281%29.jpg?format=750w"}]

    const card = (data, i) => {
        return (
            <Link to="/product">
                <Box
                    padding={'8'}
                    borderRadius={'20px'}
                    backgroundColor={'white'}
                    boxShadow={'30px 10px 42px  rgba(0, 0, 0, 0.2)'}
                    onClick={() => handleCardSelected(i)}   
                    w={viewWidth < 1600 ? "100%" : "20vw"}
                >
                    <Flex>
                        <Box flex={1}>
                            <AspectRatio maxW='560px' ratio={1}>
                                <Image src={data.image} alt="Flowerly" objectFit="cover" borderRadius="20px" />
                            </AspectRatio>
                        </Box>
                        <Box flex={1} paddingLeft={'4'}>
                            <Flex flexDir={'column'} bgColor={'#C7F27D'} h={'100%'} borderRadius={'12px'}>
                                <Box flex={1} p={'2'}>
                                    <HStack>
                                        <StarIcon />
                                        <Text>
                                        Score
                                        </Text>
                                    </HStack>
                                </Box>
                                <Box flex={1} >
                                    <Flex flexDir={'row'} >
                                        <Box flex={1} />
                                        <Box flex={1} >
                                            <CircularProgress size='80px' value={data.recommendationScore} color='orange.400' thickness='8px'>
                                                <CircularProgressLabel fontSize={'xl'} fontWeight={'bold'}>{data.recommendationScore}%</CircularProgressLabel>
                                            </CircularProgress>
                                        </Box>
                                        <Box flex={1} />
                                    </Flex>
                                    
                                </Box>
                                <Box flex={1} textAlign={'center'}>
                                    Very high
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                    <Flex flexDir={'row'} paddingTop={'2'}>
                        <Box flex={1} paddingLeft={'4'}>
                            <Text fontSize={['md', 'lg', '2xl']} fontWeight={'bold'}>
                                {data.name}
                            </Text>
                            <HStack>
                                <Text fontSize={['sm', 'md', 'xl']} fontWeight={'thin'}>
                                    by
                                </Text>
                                <Text fontSize={['sm', 'md', 'xl']} fontWeight={'bold'}>
                                    {data.brand}
                                </Text>
                            </HStack>
                        </Box>
                    </Flex>
                    <Flex flexDir={'row'} paddingTop={'2'}>
                        <Box flex={1} paddingLeft={'4'}>
                            <HStack>
                                <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/morning-b.webp"} alt="Flowerly"/>
                                
                                <Flex flexDir={'column'}> 
                                    <Text fontSize={'lg'} fontWeight={'bold'}>
                                        {data.strainType}
                                    </Text>
                                    <Text fontSize={'md'} fontWeight={'thin'}>
                                        <HStack>
                                        {data.timeOfDay.map((item, i) => {
                                                return (
                                                    <Text key={item + i} fontSize={'md'} fontWeight={'thin'}>
                                                        {item}
                                                    </Text>
                                                )
                                            }
                                        )}
                                        </HStack>
                                    </Text>

                                </Flex>

                            </HStack>
                        </Box>
                    </Flex>
                    <Flex flexDir={'row'} paddingTop={'2'}>
                        <Box flex={1} paddingLeft={'4'}>
                            <HStack>
                                <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/happy-b.webp"} alt="Flowerly"/>
                                
                                <Flex flexDir={'column'}> 
                                    <Text fontSize={'lg'} fontWeight={'bold'}>
                                        Effects
                                    </Text>
                                    <Text fontSize={'md'} fontWeight={'thin'}>
                                        <HStack>
                                        {data.effects.map((item, i) => {
                                                return (
                                                    <Text key={item + i} fontSize={'md'} fontWeight={'thin'}>
                                                        {item}
                                                    </Text>
                                                )
                                            }
                                        )}
                                        </HStack>
                                    </Text>

                                </Flex>

                            </HStack>
                        </Box>
                    </Flex>
                    <Flex flexDir={'row'} paddingTop={'2'}>
                        <Box flex={1} paddingLeft={'4'}>
                            <HStack>
                                <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/hungry-b.webp"} alt="Flowerly"/>
                                
                                <Flex flexDir={'column'}> 
                                    <Text fontSize={'lg'} fontWeight={'bold'}>
                                        Aroma
                                    </Text>
                                    <Text fontSize={'md'} fontWeight={'thin'}>
                                        <HStack>
                                        {data.flavors.map((item, i) => {
                                                return (
                                                    <Text key={item + i} fontSize={'md'} fontWeight={'thin'}>
                                                        {item}
                                                    </Text>
                                                )
                                            }
                                        )}
                                        </HStack>
                                    </Text>

                                </Flex>

                            </HStack>
                        </Box>
                    </Flex>
                    <Flex flexDir={'row'} paddingTop={'2'}>
                        <Box flex={1} />
                        <Box flex={1} >
                            <Button variant={'outline'} colorScheme='black' bgColor={'#C7F27D'}> 
                                Add To Cart
                            </Button>  
                        </Box>          
                        <Box flex={1} />
                    </Flex>
                </Box>
            </Link>
        )
        
    }

    return (
        <Box  h={'60vh'} >
            <Flex flexDir={'row'} >
            {fakeData.map((item, i) => {
                return (
                    <Box key={item.name + i} m={4} w={'100%'}>
                        {card(item, i)}
                    </Box>
                )
            })}
            </Flex>
            
        </Box>
    )


    }

    const mobileView = <Box >
                            <Center >
                                <Flex flexDir={'column'}  width={'100%'}>
                                    <Box mr={'6vw'} ml={'6vw'} bgColor={'rgba(255,255,255,.6)'} p={2} mb={4} borderRadius={16} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}>
                                        <HStack>
                                            <Stack >
                                                <Text fontWeight={'semibold'}>Personalized for you</Text>
                                                {searchQuery?.length > 0 ? (
                                                    <HStack fontSize={'sm'}>
                                                        <Text fontWeight={'semibold'}>3 Best Results | </Text>
                                                        <Text fontWeight={'normal'}>18 Total Results</Text>
                                                    </HStack>
                                                ) : (
                                                    null
                                                )}
                                                
                                            </Stack>
                                            <Spacer />
                                            <Button variant={'ghost'} fontSize={'4xl'} onClick={handleOptionsToggle}>
                                                <IoOptionsOutline />
                                            </Button>
                                        </HStack>
                                    </Box>
                                    <Box  paddingRight={'6vw'} paddingLeft={'6vw'} >
                                        { showSearchOptions ? (
                                            <Box flex={1} bgColor={'rgba(255,255,255,.4)'} borderRadius={'10px'} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}>
                                                <Heading p={2}>
                                                    Options 
                                                </Heading>
                                                <Flex flexDir={'column'} overflowY={'scroll'} h={'60vh'}>
                                                    <Box>
                                                        <Heading fontSize={'xl'} p={4}>
                                                            Time of Day
                                                        </Heading>
                                                        <Center>
                                                            <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                                                                {timeOfDayOptions?.options?.map(item => {
                                                                    return item.saveDataEntryValue === quizResults.preferredTimeOfDay ? (
                                                                        <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                                        ) : (
                                                                            <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                                            )
                                                                    })}
                                                            </Grid>
                                                        </Center>

                                                    </Box>
                                                    <Box>
                                                        <Heading fontSize={'xl'} p={4}>
                                                            Visit Reason
                                                        </Heading>
                                                        <Center>
                                                            <Grid templateColumns={'repeat(2, 1fr)'} gap={6}>
                                                                {customerType?.options?.map(item => {
                                                                    return item.saveDataEntryValue === quizResults.visitReason ? (
                                                                        <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                                        ) : (
                                                                            <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                                            )
                                                                    })}
                                                            </Grid >
                                                        </Center>

                                                    </Box>
                                                    <Box>
                                                        <Heading fontSize={'xl'} p={4}>
                                                            Consumption Method
                                                        </Heading>
                                                        <Center>
                                                            <Grid templateColumns='repeat(3, 1fr)' gap={6} p={4}>
                                                                {consumptionOptions?.options?.map(item => {
                                                                    return item.saveDataEntryValue === quizResults.consumptionMethod ? (
                                                                        <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                                        ) : (
                                                                            <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                                            )
                                                                    })}
                                                            </Grid>
                                                        </Center>

                                                    </Box>
                                                    <Box>
                                                        <Heading fontSize={'xl'} p={4}>
                                                            Feelings
                                                        </Heading>
                                                        <Center>
                                                        <Grid templateColumns='repeat(3, 1fr)' gap={6} p={4}>
                                                            {intentionsOptions?.options?.map(item => {
                                                                // Check if intentionFeelings is an array and includes the current item
                                                                const isSelected = Array.isArray(quizResults.intentionFeelings) 
                                                                    ? quizResults.intentionFeelings.includes(item.saveDataEntryValue) 
                                                                    : item.saveDataEntryValue === quizResults.intentionFeelings;

                                                                return isSelected ? (
                                                                    <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                                ) : (
                                                                    <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName}</Button>
                                                                );
                                                            })}
                                                        </Grid>

                                                        </Center>

                                                    </Box>
                                                    
                                                    
                                                    <Box>
                                                        <Heading fontSize={'xl'} p={4}>
                                                            Quality
                                                        </Heading>
                                                        <Center>
                                                            <Stack>
                                                                {qualityOptions?.options?.map(item => {
                                                                    return item.saveDataEntryValue === quizResults.preferredQuality ? (
                                                                        <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                                        ) : (
                                                                            <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                                            )
                                                                    })}
                                                            </Stack>
                                                        </Center>

                                                    </Box>
                                                </Flex>

                                            </Box>
                                        ) : (
                                            <Box bgColor={'rgba(255,255,255,.4)'} mb={4} borderRadius={'10px'} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}>
                                                <HStack w={'100%'} h={"100%"} justifyContent="flex-start" alignItems="stretch" paddingX={0} marginX={0}>
                                                    {searchQuery?.length > 0 && (
                                                        <Flex h={"100%"} paddingX={0} marginX={0} alignItems="stretch">
                                                            <AspectRatio 
                                                                flexGrow={1}
                                                                paddingX={0} marginX={0}
                                                                height={"100%"} 
                                                                width={'100px'}>
                                                                <video
                                                                style={{borderRadius: "10px", boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)' }}
                                                                title={'Video'}
                                                                src={"https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Summary_3Results-v2.mp4"}
                                                                controls={false}
                                                                autoPlay={true}
                                                                playsInline={true}
                                                                allowFullScreen={false}
                                                                />
                                                            </AspectRatio>
                                                        </Flex>
                                                    )}
                                                    <Box p={3} width={'100%'} >
                                                        <Text fontSize='sm'>{searchQuery?.length > 0 ? (searchQuery) 
                                                        : (<Stack pt={8} pb={8} textAlign={'center'}>
                                                            <Text>Sign in or start searching to begin</Text>
                                                            <Link to={'/ageVerify'}> 
                                                                <Button bgColor={'#FF9438'} color={'white'}>Start Searching</Button>
                                                            </Link>
                                                            </Stack>)}
                                                        </Text>
                                                    </Box>
                                                </HStack>    
                                            </Box>
                                        )}
                                    </Box>
                                    <Flex flexDir={'column'} overflowY={'scroll'} h={'600px'} paddingRight={'6vw'} paddingLeft={'6vw'}>
                                        {
                                            !showSearchOptions && searchQuery?.length > 0 && (
                                                [1,2,3].map((item, i ) => {
                                                    return (
                                                        <Link to={"/product"}>
                                                            <Box mb={i===2 ? (viewHeight > 750 ? "60px": "40vh") : 3} bgColor={'rgba(255,255,255,.4)'} borderRadius={'10px'} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}>
                                                                <Box borderRadius={'20px'} bgColor={"#FF9438"} p={1} pl={3} position={'relative'} fontSize={'xs'}>
                                                                    Flowerly Match • 97% • Very High
                                                                    <Box borderRadius={'20px'} bgColor={"#FFDD56"} p={1} pl={3} position={'absolute'} top={0} left={0} width={'86vw'}>
                                                                        Flowerly Match • 97% • Very High
                                                                    </Box>
                                                                </Box>
                                                                <Flex flexDir={'row'} p={2}>
                                                                    <Box flex={2} margin={'2'} >
                                                                        <Image borderRadius={'14px'} w='24vw' src={"https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"} />
                                                                    </Box>
                                                                    <Box flex={4}>
                                                                        <Stack>
                                                                            <Heading fontSize={'md'}>
                                                                                Super Lemon Haze
                                                                            </Heading>
                                                                            <Text fontSize={'sm'}>
                                                                                by matter
                                                                            </Text>
                                                                            <Text fontSize={'sm'} fontWeight={'bold'}>
                                                                            $55 / eighth
                                                                            </Text>
                                                                        </Stack>
                                                                    </Box>
                                                                    <Center flex={2}>
                                                                        <Button bgColor={"#C5F278"} fontSize={'2xl'} h={'3rem'} borderRadius={'14px'}>
                                                                            <LuShoppingCart />
                                                                        </Button>
                                                                    </Center>
                                                                </Flex>
                                                                <HStack>
                                                                    <HStack p={2}>
                                                                        <Image w="30px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/morning-b.webp"} alt="Flowerly"/>
                                                                        <Flex flexDir={'column'}> 
                                                                            <Text fontSize={'sm'} fontWeight={'bold'}>
                                                                                Sativa
                                                                            </Text>
                                                                            <Text fontSize={'xs'} fontWeight={'thin'}>
                                                                                <HStack>
                                                                                    <Text fontWeight={'thin'}>
                                                                                        morning, daytime
                                                                                    </Text>
                                                                                </HStack>
                                                                            </Text>
                                                                        </Flex>
                                                                    </HStack>
                                                                    <HStack p={2}>
                                                                        <Image w="30px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/happy-b.webp"} alt="Flowerly"/>
                                                                        <Flex flexDir={'column'}> 
                                                                            <Text fontSize={'sm'} fontWeight={'bold'}>
                                                                                Effects
                                                                            </Text>
                                                                            <Text fontSize={'xs'} fontWeight={'thin'}>
                                                                                <HStack>
                                                                                    <Text fontWeight={'thin'}>
                                                                                        energy, motivation
                                                                                    </Text>
                                                                                </HStack>
                                                                            </Text>
                                                                        </Flex>
                                                                    </HStack>
                                                                    <HStack p={2}>
                                                                        <Image w="30px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/hungry-b.webp"} alt="Flowerly"/>
                                                                        <Flex flexDir={'column'}> 
                                                                            <Text fontSize={'sm'} fontWeight={'bold'}>
                                                                                Aroma
                                                                            </Text>
                                                                            <Text fontSize={'xs'} fontWeight={'thin'}>
                                                                                <HStack>
                                                                                    <Text  fontWeight={'thin'}>
                                                                                        citrus, lemon
                                                                                    </Text>
                                                                                </HStack>
                                                                            </Text>
                                                                        </Flex>
                                                                    </HStack>
                                                                </HStack>
                                                            </Box>
                                                        </Link>
                                                    )
                                                })
                                            )
                                        }
                                    </Flex>
                                </Flex>
                            </Center>
                       </Box>

const DesktopBackground = () => {
    const targetRef = useRef(null);
    const topRef = useRef(null);
  
    const scrollToTarget = () => {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToTop = () => {
        topRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <Box w={'100%'}>
            <Box borderTop="1px" borderColor="black" width="100vw" position="fixed" ></Box>
                {viewWidth < 1400 ? ( 
                    <Flex flexDir={'column'} overflowY={'scroll'} h={'92vh'}>
                        <Box
                            
                            flex={1}
                            padding={'4'} 
                            ml={'10'} mr={'10'} mt={8} 
                            borderRadius={'2rem'} 
                            backgroundColor={'white'} 
                            boxShadow={'30px 10px 42px  rgba(0, 0, 0, 0.2)'}
                            >
                            <Text fontSize={['2xl', '3xl']} fontWeight={'bold'}> 
                            {searchQuery?.length > 0 ? (
                            <Box>
                                <Flex flexDir={'row'}>
                                    <Center flex={1}> 
                                        <AspectRatio h='10vh' w={'50%'} ratio={1} margin={'2'} 
                                            >
                                            <video
                                                style={{borderRadius: "30px"}}
                                                title={'Video'}
                                                src={"https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Summary_3Results-v2.mp4"}
                                                controls={false}
                                                autoPlay={true}
                                                allowFullScreen={false}
                                                />
                                        </AspectRatio>
                                    </Center>
                                    <Center flex={4}>
                                        <Text fontSize={'2xl'} fontWeight={'bold'}>
                                            {searchQuery}
                                        </Text> 
                                    </Center>
                                    <Center flex={1}>
                                        <Flex flexDir={'column'}  >
                                            <Box flex={1}>
                                                <HStack fontSize={'md'}>
                                                    <Button fontWeight={'bold'} variant={'ghost'}>
                                                        3 Best Results
                                                    </Button>
                                                    <Text>
                                                        |
                                                    </Text>
                                                    <Button onClick={scrollToTarget} fontWeight={'thin'} variant={'ghost'} >
                                                        18 Total Results
                                                    </Button>
                                                </HStack>
                                            </Box>
                                            <Spacer />
                                
                                            <Box flex={1}>
                                                <Flex flexDir={'row'} >
                                                    <HStack flex={6} backgroundColor={'white'} textAlign={'center'} borderRadius={'14px'} w="160px">
                                                        <Button w="80px" fontSize={'xl'} fontWeight={'bold'} variant={'ghost'} backgroundColor={'#C7F27D'} borderRadius={'14px'}>
                                                            Best
                                                        </Button>
                                                        <Button onClick={scrollToTarget} w="80px" fontSize={'xl'} fontWeight={'thin'} variant={'ghost'} backgroundColor={'gray.200'} borderRadius={'14px'}>
                                                            All
                                                        </Button>
                                                    </HStack>
                                                    <Box flex={1} backgroundColor={'white'}  textAlign={'center'} borderRadius={'14px'}>
                                                        <Button variant={'ghost'} fontSize={'4xl'} onClick={handleOptionsToggle}>
                                                            <IoOptionsOutline />
                                                        </Button>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    </Center>
                                </Flex>

                            </Box>) 
                                                            : (<Stack pt={4} pb={4} textAlign={'center'}>
                                                                <Text>Sign in or start searching to begin</Text>
                                                                <Link to={'/ageVerify'}> 
                                                                    <Button bgColor={'#FF9438'} color={'white'}>Start Searching</Button>
                                                                </Link>
                                                                </Stack>)}
                            </Text>
                        </Box>
                        { showSearchOptions ? (
                            <Box  bgColor={'rgba(255,255,255,.4)'} borderRadius={'30px'} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}  flex={1} m={10} p={4}>
                                <Heading p={2}>
                                    Options 
                                </Heading>
                                <Grid templateColumns='repeat(2, 1fr)' gap={20} overflowY={'scroll'} h={'60vh'} >
                                    <Box w={'100%'} flex={1}>
                                        <Heading fontSize={'xl'} p={4}>
                                            Time of Day
                                        </Heading>
                                        <Center>
                                            <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                                                {timeOfDayOptions?.options?.map(item => {
                                                    return item.saveDataEntryValue === quizResults.preferredTimeOfDay ? (
                                                        <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                        ) : (
                                                            <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                            )
                                                    })}
                                            </Grid>
                                        </Center>

                                    </Box>
                                    <Box>
                                        <Heading fontSize={'xl'} p={4}>
                                            Visit Reason
                                        </Heading>
                                        <Center>
                                            <Grid templateColumns={'repeat(2, 1fr)'} gap={6}>
                                                {customerType?.options?.map(item => {
                                                    return item.saveDataEntryValue === quizResults.visitReason ? (
                                                        <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                        ) : (
                                                            <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                            )
                                                    })}
                                            </Grid >
                                        </Center>

                                    </Box>
                                    <Box>
                                        <Heading fontSize={'xl'} p={4}>
                                            Consumption Method
                                        </Heading>
                                        <Center>
                                            <Grid templateColumns='repeat(3, 1fr)' gap={6} p={4}>
                                                {consumptionOptions?.options?.map(item => {
                                                    return item.saveDataEntryValue === quizResults.consumptionMethod ? (
                                                        <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                        ) : (
                                                            <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                            )
                                                    })}
                                            </Grid>
                                        </Center>

                                    </Box>
                                    <Box>
                                        <Heading fontSize={'xl'} p={4}>
                                            Feelings
                                        </Heading>
                                        <Center>
                                        <Grid templateColumns='repeat(3, 1fr)' gap={6} p={4}>
                                            {intentionsOptions?.options?.map(item => {
                                                // Check if intentionFeelings is an array and includes the current item
                                                const isSelected = Array.isArray(quizResults.intentionFeelings) 
                                                    ? quizResults.intentionFeelings.includes(item.saveDataEntryValue) 
                                                    : item.saveDataEntryValue === quizResults.intentionFeelings;

                                                return isSelected ? (
                                                    <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                ) : (
                                                    <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName}</Button>
                                                );
                                            })}
                                        </Grid>

                                        </Center>

                                    </Box>
                                    
                                    
                                    <Box>
                                        <Heading fontSize={'xl'} p={4}>
                                            Quality
                                        </Heading>
                                        <Center>
                                            <Stack>
                                                {qualityOptions?.options?.map(item => {
                                                    return item.saveDataEntryValue === quizResults.preferredQuality ? (
                                                        <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                        ) : (
                                                            <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                            )
                                                    })}
                                            </Stack>
                                        </Center>

                                    </Box>
                                </Grid>

                            </Box>
                                        ) : (
                        <Box flex={1} ml={'8'} mr={'8'}>
                            
                            <Flex flexDir={'column'} >
                                <FlowerlyCards flexDir={'row'} width={'100%'} mb={'30vh'}/>
                                <Box paddingTop={'20vh'}>
                                    <Center flex={1} paddingLeft={'4'} paddingBottom={'2vh'}>
                                    
                                        <Button fontSize={'4xl'} fontWeight={'bold'} variant={'ghost'}  >
                                            <Flex flexDir={'column'}>
                                                <Box flex={1}>
                                                    <Text fontSize={'xl'} fontWeight={'bold'}>
                                                        Load More
                                                    </Text>
                                                </Box>
                                                <Box flex={1}>
                                                    <TriangleDownIcon fontSize={'xl'}/>   
                                                </Box>
                                            </Flex>
                                        </Button>
                                    </Center>
                                </Box>
                                <Flex ref={targetRef} id="targetComponent" flexDir={'row'}  pt={'2vh'} paddingBottom={'10vh'}>
                                    <Grid flex={1} templateColumns='repeat(3, 1fr)' gap={2} padding={2}>
                                        {
                                            [1,2,3,4,5,6].map((item) => {
                                                return (
                                                    <GridItem  w='100%' h='100%' >
                                                        <Link to="/product">
                                                            <Box
                                                                padding={'6'} 
                                                                margin="1"
                                                                borderRadius={'2rem'} 
                                                                backgroundColor={'white'} 
                                                                boxShadow={'30px 10px 42px  rgba(0, 0, 0, 0.2)'}
                                                                >
                                                                <Flex flexDir={'column'}>
                                                                    <HStack>
                                                                        <Box flex={4} textAlign={'left'}>
                                                                            <Text fontSize={'xl'} fontWeight={'bold'}> 
                                                                                Pinapple Express
                                                                            </Text>
                                                                            <Text fontSize={'lg'} fontWeight={'thin'}>
                                                                                by matter 
                                                                            </Text>
                                                                        </Box>
                                                                        <Box flex={1} textAlign={'right'}>
                                                                            <Button fontSize={'2xl'} fontWeight={'bold'} variant={'ghost'} backgroundColor={'#C7F27D'} borderRadius={'14px'}>
                                                                                <GrCart />
                                                                            </Button>
                                                                        </Box>
                                                                    </HStack>
                                                                    <Flex flexDir={'row'} paddingTop={'2'}>
                                                                        <Box flex={2} margin={'2'}>
                                                                            <Image borderRadius={'14px'} w='100%' src={"https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"} />
                                                                        </Box>
                                                                        <Center flex={2} padding={'2'}>
                                                                            <CircularProgress value={80} color="orange.400" size="120px" thickness="10px" >
                                                                                <CircularProgressLabel fontSize={'2xl'} fontWeight={'bold'}>80%</CircularProgressLabel>
                                                                            </CircularProgress>
                                                                        </Center>
                                                                    </Flex>
                                                                    <HStack >
                                                                        <HStack>
                                                                            <Image w="30px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/morning-b.webp"} alt="Flowerly"/>
                                                                            <Flex flexDir={'column'}> 
                                                                                <Text fontSize={'md'} fontWeight={'bold'}>
                                                                                    Sativa
                                                                                </Text>
                                                                                <Text fontSize={'sm'} fontWeight={'thin'}>
                                                                                    <HStack>
                                                                                        <Text fontSize={'sm'} fontWeight={'thin'}>
                                                                                            morning, daytime
                                                                                        </Text>
                                                                                    </HStack>
                                                                                </Text>
                                                                            </Flex>
                                                                        </HStack>
                                                                        <HStack>
                                                                            <Image w="30px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/happy-b.webp"} alt="Flowerly"/>
                                                                            <Flex flexDir={'column'}> 
                                                                                <Text fontSize={'md'} fontWeight={'bold'}>
                                                                                    Effects
                                                                                </Text>
                                                                                <Text fontSize={'sm'} fontWeight={'thin'}>
                                                                                    <HStack>
                                                                                        <Text fontSize={'sm'} fontWeight={'thin'}>
                                                                                            energy, motivation
                                                                                        </Text>
                                                                                    </HStack>
                                                                                </Text>
                                                                            </Flex>
                                                                        </HStack>
                                                                        <HStack>
                                                                            <Image w="30px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/hungry-b.webp"} alt="Flowerly"/>
                                                                            <Flex flexDir={'column'}> 
                                                                                <Text fontSize={'md'} fontWeight={'bold'}>
                                                                                    Aroma
                                                                                </Text>
                                                                                <Text fontSize={'sm'} fontWeight={'thin'}>
                                                                                    <HStack>
                                                                                        <Text fontSize={'sm'} fontWeight={'thin'}>
                                                                                            citrus, lemon
                                                                                        </Text>
                                                                                    </HStack>
                                                                                </Text>
                                                                            </Flex>
                                                                        </HStack>
                                                                    </HStack>
                                                                </Flex>    
                                                            </Box>
                                                        </Link>
                                                    </GridItem>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Flex>
                            </Flex>
                        </Box>
                        )}
                    </Flex>
                 ) : 
            (
            <Flex flexDir={'row'}>
                <Box flex={1} textAlign={'center'}>
                    <Flex flexDirection={'column'} maxW={'28vw'}>
                    <Box flex={1}>
                        <Center>
                            <AspectRatio maxW='28vw' ratio={3 /4} margin={'8'} 
                                width="22vw" // adjust as needed
                                maxH="40vh" // adjust as needed
                                >
                                <video
                                    style={{borderRadius: "30px"}}
                                    title={'Video'}
                                    src={"https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/8-searchResults-edited.mp4"}
                                    controls={false}
                                    autoPlay={true}
                                    allowFullScreen={false}
                                    />
                            </AspectRatio>
                        </Center>
                    </Box>
                    <Center>
                    <Box
                        flex={1}
                        padding={'4'} 
                        margin="8"
                        borderRadius={'2rem'} 
                        backgroundColor={'white'} 
                        boxShadow={'30px 10px 42px  rgba(0, 0, 0, 0.2)'}
                        maxW='28vw'
                        width="22vw" 
                        >

                        <Text fontSize={['2xl', '4xl']} fontWeight={'bold'}> 
                        {searchQuery?.length > 0 ? (searchQuery) 
                                                        : (<Stack pt={8} pb={8} textAlign={'center'}>
                                                            <Text>Sign in or start searching to begin</Text>
                                                            <Link to={'/ageVerify'}> 
                                                                <Button bgColor={'#FF9438'} color={'white'}>Start Searching</Button>
                                                            </Link>
                                                            </Stack>)}
                        </Text>
                    </Box>
                    </Center>

                    </Flex>
                </Box>
                <Box flex={1} pr={'60px'} >
                    <Box marginTop={'6'}>
                        <Heading fontSize={'5xl'} fontWeight={'bold'}>
                            Personalized for you
                        </Heading>
                    </Box>
                    <Flex flexDir={'row'} paddingTop={'2'} paddingBottom={'2'} >
                        <Button fontSize={'4xl'} fontWeight={'bold'} variant={'ghost'}>
                            3 Best Results
                        </Button>
                        
                        <Button onClick={scrollToTarget} fontSize={'4xl'} fontWeight={'thin'} variant={'ghost'} >
                            18 Total Results
                        </Button>

                        <Spacer />
                        <Flex flexDir={'row'} paddingRight="70px" >
                            <HStack flex={6} backgroundColor={'white'} textAlign={'center'} borderRadius={'14px'} w="160px">
                                <Button  w="80px" fontSize={'xl'} fontWeight={'bold'} variant={'ghost'} backgroundColor={'#C7F27D'} borderRadius={'14px'}>
                                    Best
                                </Button>
                                <Button onClick={scrollToTarget} w="80px" fontSize={'xl'} fontWeight={'thin'} variant={'ghost'} backgroundColor={'white'} borderRadius={'14px'}>
                                    All
                                </Button>
                            </HStack>
                            <Box flex={1} backgroundColor={'white'}  textAlign={'center'} borderRadius={'14px'}>
                                <Button variant={'ghost'} fontSize={'4xl'} onClick={handleOptionsToggle}>
                                    <IoOptionsOutline />
                                </Button>
                            </Box>
                        </Flex>
                    </Flex>
                    <Flex overflowY={'scroll'} flexDir={'column'} h={'80vh'}>
                    { showSearchOptions ? (
                            <Box  bgColor={'rgba(255,255,255,.4)'} borderRadius={'10px'} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'} w={'70vw'}>
                                <Heading p={2}>
                                    Options 
                                </Heading>
                                <Grid templateColumns='repeat(2, 1fr)' gap={6} overflowY={'scroll'} h={'60vh'} >
                                    <Box w={'100%'} flex={1}>
                                        <Heading fontSize={'xl'} p={4}>
                                            Time of Day
                                        </Heading>
                                        <Center>
                                            <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                                                {timeOfDayOptions?.options?.map(item => {
                                                    return item.saveDataEntryValue === quizResults.preferredTimeOfDay ? (
                                                        <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                        ) : (
                                                            <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                            )
                                                    })}
                                            </Grid>
                                        </Center>

                                    </Box>
                                    <Box>
                                        <Heading fontSize={'xl'} p={4}>
                                            Visit Reason
                                        </Heading>
                                        <Center>
                                            <Grid templateColumns={'repeat(2, 1fr)'} gap={6}>
                                                {customerType?.options?.map(item => {
                                                    return item.saveDataEntryValue === quizResults.visitReason ? (
                                                        <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                        ) : (
                                                            <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                            )
                                                    })}
                                            </Grid >
                                        </Center>

                                    </Box>
                                    <Box>
                                        <Heading fontSize={'xl'} p={4}>
                                            Consumption Method
                                        </Heading>
                                        <Center>
                                            <Grid templateColumns='repeat(3, 1fr)' gap={6} p={4}>
                                                {consumptionOptions?.options?.map(item => {
                                                    return item.saveDataEntryValue === quizResults.consumptionMethod ? (
                                                        <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                        ) : (
                                                            <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                            )
                                                    })}
                                            </Grid>
                                        </Center>

                                    </Box>
                                    <Box>
                                        <Heading fontSize={'xl'} p={4}>
                                            Feelings
                                        </Heading>
                                        <Center>
                                        <Grid templateColumns='repeat(3, 1fr)' gap={6} p={4}>
                                            {intentionsOptions?.options?.map(item => {
                                                // Check if intentionFeelings is an array and includes the current item
                                                const isSelected = Array.isArray(quizResults.intentionFeelings) 
                                                    ? quizResults.intentionFeelings.includes(item.saveDataEntryValue) 
                                                    : item.saveDataEntryValue === quizResults.intentionFeelings;

                                                return isSelected ? (
                                                    <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                ) : (
                                                    <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName}</Button>
                                                );
                                            })}
                                        </Grid>

                                        </Center>

                                    </Box>
                                    
                                    
                                    <Box>
                                        <Heading fontSize={'xl'} p={4}>
                                            Quality
                                        </Heading>
                                        <Center>
                                            <Stack>
                                                {qualityOptions?.options?.map(item => {
                                                    return item.saveDataEntryValue === quizResults.preferredQuality ? (
                                                        <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                        ) : (
                                                            <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                            )
                                                    })}
                                            </Stack>
                                        </Center>

                                    </Box>
                                </Grid>

                            </Box>
                                        ) : (
                            <Box w={'70vw'}>    
                                <FlowerlyCards flexDir={'row'} ref={topRef}/>
                                <Flex flexDir={'row'} paddingTop={'2'}>
                                    <Center flex={1} paddingBottom={'4vh'}>
                                    
                                        <Button fontSize={'4xl'} fontWeight={'bold'} variant={'ghost'} onClick={scrollToTarget}>
                                            <Flex flexDir={'column'}>
                                                <Box flex={1}>
                                                    <Text fontSize={'xl'} fontWeight={'bold'}>
                                                        More Results
                                                    </Text>
                                                </Box>
                                                <Box flex={1}>
                                                    <TriangleDownIcon fontSize={'xl'}/>   
                                                </Box>
                                            </Flex>
                                        </Button>
                                    </Center>
                                
                                </Flex>
                                <Flex ref={targetRef} id="targetComponent" flexDir={'row'}  paddingBottom={'10vh'}>
                                    <Grid flex={1} templateColumns='repeat(3, 1fr)' gap={4} padding={4}>
                                        {
                                            [1,2,3,4,5,6].map((item) => {
                                                return (
                                                    <GridItem  w='100%' h='100%' >
                                                        <Link to="/product">
                                                            <Box
                                                                padding={'8'} 
                                                                margin="1"
                                                                borderRadius={'2rem'} 
                                                                backgroundColor={'white'} 
                                                                boxShadow={'30px 10px 42px  rgba(0, 0, 0, 0.2)'}
                                                                w={'21vw'}
                                                                >
                                                                <Flex flexDir={'column'}>
                                                                    <HStack>
                                                                        <Box flex={4} textAlign={'left'}>
                                                                            <Text fontSize={'xl'} fontWeight={'bold'}> 
                                                                                Pinapple Express
                                                                            </Text>
                                                                            <Text fontSize={'lg'} fontWeight={'thin'}>
                                                                                by matter
                                                                            </Text>
                                                                        </Box>
                                                                        <Box flex={1} textAlign={'right'}>
                                                                            <Button fontSize={'2xl'} fontWeight={'bold'} variant={'ghost'} backgroundColor={'#C7F27D'} borderRadius={'14px'}>
                                                                                <GrCart />
                                                                            </Button>
                                                                        </Box>
                                                                    </HStack>
                                                                    <Flex flexDir={'row'} paddingTop={'2'}>
                                                                        <Box flex={2} margin={'2'}>
                                                                            <Image borderRadius={'14px'} w='100%' src={"https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"} />
                                                                        </Box>
                                                                        <Center flex={2} padding={'2'}>
                                                                            <CircularProgress value={80} color="orange.400" size="100px" thickness="10px" >
                                                                                <CircularProgressLabel fontSize={'2xl'} fontWeight={'bold'}>80%</CircularProgressLabel>
                                                                            </CircularProgress>
                                                                        </Center>
                                                                    </Flex>
                                                                    <HStack>
                                                                        <HStack>
                                                                            <Image w="30px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/morning-b.webp"} alt="Flowerly"/>
                                                                            <Flex flexDir={'column'}> 
                                                                                <Text fontSize={'md'} fontWeight={'bold'}>
                                                                                    Sativa
                                                                                </Text>
                                                                                <Text fontSize={'sm'} fontWeight={'thin'}>
                                                                                    <HStack>
                                                                                        <Text fontSize={'sm'} fontWeight={'thin'}>
                                                                                            morning, daytime
                                                                                        </Text>
                                                                                    </HStack>
                                                                                </Text>
                                                                            </Flex>
                                                                        </HStack>
                                                                        <HStack>
                                                                            <Image w="30px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/happy-b.webp"} alt="Flowerly"/>
                                                                            <Flex flexDir={'column'}> 
                                                                                <Text fontSize={'md'} fontWeight={'bold'}>
                                                                                    Effects
                                                                                </Text>
                                                                                <Text fontSize={'sm'} fontWeight={'thin'}>
                                                                                    <HStack>
                                                                                        <Text fontSize={'sm'} fontWeight={'thin'}>
                                                                                            energy, motivation
                                                                                        </Text>
                                                                                    </HStack>
                                                                                </Text>
                                                                            </Flex>
                                                                        </HStack>
                                                                        <HStack>
                                                                            <Image w="30px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/hungry-b.webp"} alt="Flowerly"/>
                                                                            <Flex flexDir={'column'}> 
                                                                                <Text fontSize={'md'} fontWeight={'bold'}>
                                                                                    Aroma
                                                                                </Text>
                                                                                <Text fontSize={'sm'} fontWeight={'thin'}>
                                                                                    <HStack>
                                                                                        <Text fontSize={'sm'} fontWeight={'thin'}>
                                                                                            citrus, lemon
                                                                                        </Text>
                                                                                    </HStack>
                                                                                </Text>
                                                                            </Flex>
                                                                        </HStack>
                                                                    </HStack>
                                                                </Flex>    
                                                            </Box>
                                                        </Link>
                                                    </GridItem>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Flex>
                            </Box>
                        )}
                    </Flex>
                </Box>
            </Flex>
            )}
        </Box>  
    );  
};                         
        
    return (
      <>
        <div className={'animate__animated animate__fadeIn'}>
            {isMobile ? mobileView : <DesktopBackground />}
        </div>
      </> 
    )
  }

  export default Summary;

