import { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectQuizResults } from '../reducers/quizResults';
import { updateSearch } from '../reducers/searchQuery';
import { Link } from "react-router-dom";
import { Box, Flex, Heading, Spacer, Button, Container, Progress, useToast, Center, Image, AspectRatio, Text, SimpleGrid, Divider, Skeleton, Select, Input, HStack, InputGroup, InputLeftAddon, InputLeftElement,Grid, GridItem, Stack, Menu, MenuItem, MenuList, MenuButton, Modal, ModalOverlay, useDisclosure, ModalContent, List , ListIcon, ListItem} from '@chakra-ui/react';
// import { Box, Flex, Heading, Divider, Spacer, Button, Container, Center, SimpleGrid, Text, Checkbox, AspectRatio, useRadio, useRadioGroup, Stack, Badge, Grid, GridItem } from '@chakra-ui/react';
import { CheckIcon, CheckCircleIcon, ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons'
// import { BsCheckSquareFill, BsSquare } from 'react-i'
import { has } from 'lodash';

const SummaryPreview = (props) => {
    const dispatch = useDispatch();
    const isMobile = props.isMobile;
    const quizResults = useSelector(selectQuizResults);
    const avatarVideo = "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Finding_Results_Here-v2.mp4";
    let mobileView;
    let desktopView;

    const addPrefix = (inputStr) => {
        if (typeof inputStr !== 'string') {
            return null;  // or handle it differently if you want
        }
      
        // Check if the string ends with "est"
        if (inputStr.slice(-3) === 'est') {
            return 'the ' + inputStr;
        } else {
            return 'a ' + inputStr;
        }
    }
    
    const computeAndSaveQuery = () => {
        // Return early if quizResults is null or undefined
        if (!quizResults) return;
    
        // Destructure properties from quizResults and set default values
        const {
            consumptionMethod = "",
            experienceLevel = "",
            intentionFeelings = null,  // Default to null if undefined
            preferredDuration = "",
            preferredQuality = "",
            preferredStrength = "",
            preferredTimeOfDay = "",
            preferredCannaTerps = "",
            visitReason = "",
            preferredStrainType = ""
        } = quizResults;
    
        // Function to join array elements into a string
        const joinStrings = (arr) => arr.join(', ');
    
        // Function to add prefix to preferredStrength
        const updatedPreferredStrength = addPrefix(preferredStrength);
    
        // Initialize variable to store updated intentionFeelings
        let updatedIntentionFeelings = "";
        
        // Check if intentionFeelings is an array and has more than one item
        if (Array.isArray(intentionFeelings) && intentionFeelings.length > 1) {
            updatedIntentionFeelings = joinStrings(intentionFeelings);
        } else if (Array.isArray(intentionFeelings) && intentionFeelings.length === 1) {
            updatedIntentionFeelings = intentionFeelings[0];
        } else {
            // Handle the case where intentionFeelings is neither an array nor null
            updatedIntentionFeelings = intentionFeelings ? intentionFeelings.toString() : "";
        }
    
        // Construct the search query
        let constructedSearchQuery = "";
        if (experienceLevel === 'experienced') {
            constructedSearchQuery = `${preferredQuality.charAt(0).toUpperCase() + preferredQuality.slice(1)} ${preferredStrainType} ${consumptionMethod} with ${preferredCannaTerps} for ${preferredStrength}, ${preferredDuration} effects.`;
        } else {
            constructedSearchQuery = `${preferredQuality.charAt(0).toUpperCase() + preferredQuality.slice(1)} ${visitReason} ${consumptionMethod} with ${updatedPreferredStrength} effect for ${updatedIntentionFeelings ? updatedIntentionFeelings : null} ${preferredTimeOfDay}s.`;
        }
        // Dispatch the search query to the Redux store
        dispatch(updateSearch({ value: constructedSearchQuery }));
    
        return constructedSearchQuery;
    };
    
    

    if (quizResults?.experienceLevel?.length > 0 )  {
    const constructedSearchQuery = computeAndSaveQuery(quizResults);
    
    const { firstLineText = "Finding Results For You...", secondLineText = "Recreational flower with the strongest effect for creative days.", route, simpleOptions, answers, extraColumns, extraSpacing, detailText, isMobile, progressValue, avatar = "https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Finding_Results-v2.mp4", avatarChrome, avatarSafari, scroll, forcedRoute, prevRoute, multipleSelect, imageGrid } = props;  
    const viewHeight = window.innerHeight;
    // const totalAnswers = answers.length;


        mobileView = <Box maxHeight='88vh'>
                                <Center >
                                    <Flex flexDir={'column'} paddingRight={'6vw'} paddingLeft={'6vw'} width={'100%'}>
                                        <Box flex={1} h={"20vh"} bgColor={'rgba(255,255,255,.4)'} borderRadius={'10px'} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}>
                                            <HStack h={'100%'}>
                                                <Flex flex={1} width={'100%'} h={"100%"}>
                                                    <AspectRatio 
                                                        flexGrow={1}
                                                        height={"100%"} 
                                                        width={'100px'}>
                                                        <video
                                                        style={{borderRadius: "10px", boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)' }}
                                                        title={'Video'}
                                                        src={avatar}
                                                        controls={false}
                                                        autoPlay={true}
                                                        playsInline={true}
                                                        allowFullScreen={false}
                                                        />
                                                    </AspectRatio>
                                                </Flex>
                                                <Box p={3} width={'100%'} >
                                                    <Heading fontSize='xl'>{firstLineText}</Heading>
                                                </Box>
                                            </HStack>    
                                        </Box>
                                        <Box flex={1} pt={4} >
                                        <Progress 
                                            size='lg' 
                                            isIndeterminate
                                            colorScheme="orange"
                                            bg="whiteAlpha.800"
                                            borderRadius="md"
                                            position="relative"
                                            
                                        />

                                        </Box>
                                        <Box flex={1} style={{ paddingTop: "4vh"}} mt={'4vh'}>
                                        
                                        </Box>
                                        <Box flex={1} bgColor={'rgba(255,255,255,.6)'} m={2} p={6} borderRadius={16} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'} textAlign={'center'}>
                                            <Text fontSize={['2xl','3xl']} fontWeight={'semibold'}>{constructedSearchQuery}</Text>
                                        </Box>
                                        <Box textAlign={'center'} paddingTop={viewHeight > 750 ? "16rem" : "10rem"} mb=".4rem" color={'white'}>
                                            <Link 
                                                to={'/summary'} 
                                                
                                            >
                                                <Button bgColor={ '#FF9438' } variant={'outline'} w={'88vw'} 
                                            
                                                > 
                                                    <HStack>
                                                        <Text fontSize={'2xl'} >Continue</Text>
                                                    </HStack>
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Flex>
                                </Center>
                        </Box>


        desktopView = <Box w={'100%'}> 
                                    <Box borderTop="1px" borderColor="black" width="100vw" position="fixed" ></Box>
                                    <Flex>
                                        <Box flex={2}>
                                            <AspectRatio maxW='560px' ratio={3 /4} margin={'auto'} marginTop={'4vh'}>
                                                <video
                                                style={{borderRadius: "30px"}}
                                                title={'Video'}
                                                src={avatarVideo}
                                                controls={false}
                                                autoPlay={true}
                                                allowFullScreen={false}
                                                />
                                            </AspectRatio>
                                        </Box>
                                        <Box borderLeft="1px" borderColor="black" height="85vh" position="fixed" left="40%"></Box>
                                        <Box flex={3}>
                                            <Center padding={'60px'} marginTop={'12vh'}>
                                                <Stack>

                                                <Heading fontSize={'5xl'} fontWeight={'thin'}>
                                                    {firstLineText}
                                                </Heading>
                                                <Text fontSize={'4xl'} fontWeight={'bold'}>
                                                    {constructedSearchQuery}
                                                </Text>
                                                </Stack>
                                            </Center>
                                            <Center flexDir={'column'}>
                                                <Flex>
                                                    <Box flex={1} />
                                                    <Box flex={1}>
                                                        <AspectRatio w="200px" ratio={1}>
                                                            <Image borderRadius='full' src={"https://flowerly-app-storage.s3.amazonaws.com/tempImages/flowerly-loading-compressed.gif"} />
                                                        </AspectRatio>
                                                    </Box>
                                                    <Box flex={1} />
                                                </Flex>
                                                <Flex pt={8}>
                                                    <Box flex={1} />
                                                    <Box flex={1}>
                                                        <Link to={'/summary'}>
                                                            <Button colorScheme="black" variant={'outline'} w={'20rem'}> Next</Button>
                                                        </Link>
                                                    </Box>
                                                    <Box flex={1} />
                                                </Flex>
                                            </Center>
                                        </Box>
                                    </Flex>
                                </Box>             
                              
    } else {
        mobileView = <Box maxHeight='88vh'>
                            <Center >
                                <Flex flexDir={'column'} paddingRight={'6vw'} paddingLeft={'6vw'} width={'100%'}>
                                    <Box flex={1} h={"20vh"} p={8} mt={16} mb={8} bgColor={'rgba(255,255,255,.4)'} borderRadius={'10px'} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}>
                                        <Heading fontWeight={'bold'}>
                                            Ooops! 
                                        </Heading>
                                        <Text fontWeight={'bold'}>
                                            You missed a step. Let's answer a few more questions to get you the best results.
                                        </Text>
                                       
                                    </Box>
                                    <Flex>
                                        <Box flex={1} />
                                        <Box flex={1}>
                                            <AspectRatio w="122px" ratio={1}>
                                                <Image borderRadius='full' src={"https://flowerly-app-storage.s3.amazonaws.com/tempImages/flowerly-loading-compressed.gif"} />
                                            </AspectRatio>
                                        </Box>
                                        <Box flex={1} />
                                    </Flex>
                                    <Flex pt={8}>
                                        
                                        <Box flex={1}>
                                            <Link to={'/ageVerify'}>
                                                <Button bgColor={'#FF9438'} color={'white'} variant={'outline'} w={'100%'}> Back</Button>
                                            </Link>
                                        </Box>
                                        
                                    </Flex>
                                </Flex>
                            </Center>
                          </Box>

        desktopView = <Box w={'100%'}> 
                            <Box borderTop="1px" borderColor="black" width="100vw" position="fixed" ></Box>
                            <Flex>
                            <Box flex={2}>
                                <AspectRatio maxW='560px' ratio={3 /4} margin={'auto'} marginTop={'4vh'}>
                                    <video
                                    style={{borderRadius: "30px"}}
                                    title={'Video'}
                                    src={"https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/MissedAStep-v2.mp4"}
                                    controls={false}
                                    autoPlay={true}
                                    allowFullScreen={false}
                                    />
                                </AspectRatio>
                            </Box>
                            <Box borderLeft="1px" borderColor="black" height="85vh" position="fixed" left="40%"></Box>
                            <Box flex={3}>
                                <Center padding={'60px'} marginTop={'12vh'}>
                                    <Stack>

                                    <Heading fontSize={'5xl'} fontWeight={'thin'}>
                                
                                        Ooops! Looks like you missed a step. Let's answer a few more questions to get you the best results.
                                    </Heading>
                                    </Stack>
                                </Center>
                                <Center flexDir={'column'}>
                                    <Flex>
                                        <Box flex={1} />
                                        <Box flex={1}>
                                            <AspectRatio w="200px" ratio={1}>
                                                <Image borderRadius='full' src={"https://flowerly-app-storage.s3.amazonaws.com/tempImages/flowerly-loading-compressed.gif"} />
                                            </AspectRatio>
                                        </Box>
                                        <Box flex={1} />
                                    </Flex>
                                    <Flex pt={8}>
                                        <Box flex={1} />
                                        <Box flex={1}>
                                            <Link to={'/ageVerify'}>
                                                <Button colorScheme="black" variant={'outline'} w={'20rem'}> Back</Button>
                                            </Link>
                                        </Box>
                                        <Box flex={1} />
                                    </Flex>
                                </Center>
                            </Box>
                            </Flex>
                            </Box>   

                                                            
    }

        
    return (
      <>
        <div className={'animate__animated animate__fadeIn'}>
            {isMobile ? mobileView : desktopView}
        </div>
      </> 
    )
  }

export default SummaryPreview;



const OverlayOne = () => {
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(20px)'
    />
}
