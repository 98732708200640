import UniversalQuestion from '../questionsv3/UniversalAvatarPrompt';
import { strainTypeOptions } from './../../utilities/menuOptions';


const StrainType = (props) => {
    
    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={strainTypeOptions.firstLineText}
              secondLineText={strainTypeOptions.secondLineText} 
              fullText={strainTypeOptions.fullText}
              answers={strainTypeOptions.options}
              extraColumns={3}
              extraSpacing={4}
              progressValue={strainTypeOptions.progressValue}
              avatar={strainTypeOptions.avatar}
              route={strainTypeOptions.route}
              prevRoute={strainTypeOptions.prevRoute}

    />  
  }
  
  export default StrainType;