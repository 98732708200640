import { Button, Center } from '@chakra-ui/react';
import UniversalQuestion from '../questionsv3/UniversalAvatarPrompt';
import { technicalOptions } from './../../utilities/menuOptions'

const TechnicalEffects = (props) => {
    const detailText = "Select up to three"
    const route = "/mostImportant"
  
    return  <>
                <UniversalQuestion 
                        isMobile={props.isMobile} 
                        handleAddUserData={props.handleAddUserData} 
                        firstLineText={technicalOptions.firstLineText}
                        secondLineText={technicalOptions.secondLineText}
                        avatar={technicalOptions.avatar}
                        fullText={technicalOptions.fullText}
                        multipleSelect={technicalOptions.multipleSelect}
                        scroll={technicalOptions.scroll} 
                        answers={technicalOptions.options}
                        imageGrid={technicalOptions.imageGrid}
                        extraColumns={3}
                        extraSpacing={6}
                        detailText={detailText}
                        route={route}
                        prevRoute={technicalOptions.prevRoute}
                        progressValue={technicalOptions.progressValue}
            
                />
                
            </>
  }
  
  export default TechnicalEffects;