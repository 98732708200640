import UniversalAvatarPrompt from '../questionsv3/UniversalAvatarPrompt';
import { medicalOptions } from './../../utilities/menuOptions'

const MedicalReasons = (props) => {
  
    return <UniversalAvatarPrompt 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={medicalOptions.firstLineText}
              secondLineText={medicalOptions.secondLineText} 
              fullText={medicalOptions.fullText}
              detailText={medicalOptions.detailText}
              answers={medicalOptions.options}
              progressValue={medicalOptions.progressValue}
              route={medicalOptions.route}
              prevRoute={medicalOptions.prevRoute}
              scroll={medicalOptions.scroll}
              avatar={medicalOptions.avatar}
              multipleSelect={medicalOptions.multipleSelect}
              maxSelect={medicalOptions.maxSelect}
  
    />
  }
  
  export default MedicalReasons;