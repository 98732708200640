import React, { useEffect, useState, createContext, useContext } from 'react';
import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsConfig from './aws-exports';

import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import rootReducer from './reducers/reducers' 


import OAuthCallback from './components/OAuthCallback';
import AuthPage from './components/Auth';
import Navbar from './components/Navbar';
import Welcome from './components/Welcomev2';
import Introduction from './components/Introduction';
import Summary from './components/Summaryv3';
import SummaryPreview from './components/SummaryPreview';
import ProductDetail from './components/ProductDetailv2';
import AgeVerify from './components/questions/AgeVerify'
import VisitReason from './components/questions/VisitReason'
import WhatExperience from './components/questions/WhatExperience'
import ConsumptionMethod from './components/questionsv3/ConsumptionMethod'
import Intentions from './components/questions/Intentions'
import UnderAge from './components/questions/UnderAge'
import TechnicalEffects from './components/questions/TechnicalEffects'
import TimeOfDay from './components/questionsv3/TimeOfDay'
import StrainType from './components/questionsv3/StrainType';
import TypeOfEffect from './components/questionsv3/TypeOfEffect'
import WhatQuality from './components/questionsv3/QualityLevel'
import MostImportant from './components/questions/MostImportant'
import MedicalReasons from './components/questions/MedicalReasons'
import Format from './components/questions/Format'
import Checkout from './components/Checkoutv2';
import CompleteOrder from './components/CompleteOrderv2';
// import { } from './graphql/'

import { ChakraProvider, Container, Center, Box, Flex, Button, Heading, Grid, extendTheme } from '@chakra-ui/react';

// const store = configureStore({
//   reducer: rootReducer
// })

const customColors = {
  flowerlyColors: {
    50: '#e5f4e6', 
    500: '#9CDF27',
  },
};

const themeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config: themeConfig,
  colors: customColors,
});

const BackgroundPatternContext = createContext(() => {});

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);

const LocationHandler = () => {
  const setBackgroundPattern = useContext(BackgroundPatternContext);
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        console.log('Welcome page');
        setBackgroundPattern([[false, false, false], [], [false, false, false]])
        break;
      case '/ageVerify':
        console.log('Age verification page');
        setBackgroundPattern([[true, false, false], [], [false, false, true]])
        break;
      case '/typeOfEffect':
        console.log('typeOfEffect page');
        setBackgroundPattern([[false, true, false], [], [false, false, true]])
        break;
      case '/consumptionMethod':
        console.log('consumptionMethod page');
        setBackgroundPattern([[true, false, false], [false, false, true], [true, false, false]])
        break;
      case '/summaryPreview':
        console.log('summaryPreview page');
        setBackgroundPattern([[false, false, true], [], [false, true, false]])
        break;
      case '/summary':
        console.log('summary page');
        setBackgroundPattern([[false, false, true], [], [true, false, false]])
        break;
      // add more routes here
      default:
        console.log('Not found');
    }
  }, [location]);

  return null; // render nothing
};



const App = ({ signOut, user }) => {
  // const { user } = useAuthenticator((context) => [context.user]);
  
  const [loggedIn, setLoggedIn] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [backgroundPattern, setBackgroundPattern] = useState([[false, false, true], [false, true, false], [true, false, false]])
  // let location = useLocation();
  const data = useSelector(state => state.data)
  const dispatch = useDispatch()

  const onUpdate = () => {
    dispatch({type: 'UPDATE_DATA'}) 
  }

  const fetchUserData = async () => {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          console.log('user', user)
          resolve(user)
        })
        .catch((err) => {
          console.log('err', err)
          reject(err)
        })
    })
  }

  useEffect(() => {
    const checkUser = async () => {
      // online 
      try {
        const awsUser = await fetchUserData()
        console.log('this is the awsUser', awsUser)
        setLoggedIn(true);
      } catch (error) {
        setLoggedIn(false);
      }

    };

    const resizer = () => { 
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        } 
    }

    resizer()
    checkUser();
  }, []);

  const handleAddUserData = (e, name) => {
    console.log('what data is this', e, name)
    // setUser((prevUser) => ({
    //   ...prevUser,
    //   [name]: e,
    // }));
  };  


  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  const isMobile = width <= 840;



  const GreenCircle = (props) => {
    let hidden = props.hidden;
    let color = 'radial-gradient(circle, rgba(186,243,87,1) 2%, rgba(186,243,87,1) 10%, rgba(186,243,87,0) 61%)'
  
    return (
      <Box
        background={hidden ? color : ''}
        h={'50vh'}
        w={'50vh'}
        
      ></Box>
    )
  }

  const BackgroundAnimator = () => {
    let one = backgroundPattern[0]
    let two = backgroundPattern[1]
    let three = backgroundPattern[2]

    return (
      <Box position={'absolute'} zIndex={0}>
        <Flex flex={1} h={'33vh'} backgroundColor={''}>
          {one.map(showShape => {
              return (
                <Box flex={1} w={'33vw'} className={'animate__animated animate__zoomIn animate__slow animate__delay-2s'}>
                  <GreenCircle hidden={showShape}/>
                </Box>
              )
          })}
        </Flex>
        <Flex flex={1} h={'33vh'} backgroundColor={''}>
        {two.map(showShape => {
              return (
                <Box flex={1} w={'33vw'} className={'animate__animated animate__zoomIn animate__slow '}>
                  <GreenCircle hidden={showShape}/>
                </Box>
              )
          })}
        </Flex>
        <Flex flex={1} h={'33vh'} >
          {three.map(showShape => {
              return (
                <Box flex={1} w={'33vw'} className={'animate__animated animate__zoomIn animate__slow '}>
                  <GreenCircle hidden={showShape}/>
                </Box>
              )
          })}
        </Flex>
      </Box>
    )
  };


  const AuthenticatedRoutes = () => {
    // const navigate = useNavigate();

    // if (!loggedIn) {
    //   // Navigate to the sign-in page if the user is not authenticated
    //   navigate('/auth');
    //   console.log('supposed to move to /auth')
    //   return null;  // Render nothing as we're navigating away
    // }
  
    return (
      <Authenticator hideDefault={true} amplifyConfig={awsConfig}>
        {({ signOut, user }) => (
          <Routes >
            {/* Put all routes that require authentication inside here */}
            <Route path="checkout/completeOrder" element={<CompleteOrder isMobile={isMobile} />} />
            {/* ... other authenticated routes ... */}
          </Routes >
        )}
      </Authenticator>
    );
  };
  
  


  return (
    <BackgroundPatternContext.Provider value={setBackgroundPattern}>
        <ChakraProvider theme={theme}>
          <Router >
            <Box position={"relative"}>
              <LocationHandler /> 
              <Box position={"fixed"} width={"100%"} height={"100%"} backgroundImage={'https://flowerly-app-storage.s3.amazonaws.com/assets/univeral/bg-reduced.webp'} backgroundBlendMode={'multiply'}>
                <BackgroundAnimator />
                {/* Add your navigation components here */}
                <Box position={"relative"} zIndex={1}>
                  <Navbar loggedIn={loggedIn} isMobile={isMobile}/>
                  <Routes >
                    <Route path="/" element={<Welcome isMobile={isMobile} loggedIn={loggedIn} />} />
                    <Route path="introduction" element={<Introduction isMobile={isMobile} />} />
                    <Route path="/auth" element={<AuthPage />} />
                    <Route path="/oauth2/idpresponse" element={<OAuthCallback />} />
                    {/* <Route path="authenticate" element={ <Authentication isMobile={isMobile} localUser={localUser} cart={cart} totalItemsInCart={totalItemsInCart} /> } /> */}
                    <Route path="ageVerify" element={<AgeVerify isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
                    <Route path="visitReason" element={<VisitReason isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
                    <Route path="experience" element={<WhatExperience isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
                    <Route path="medical" element={<MedicalReasons isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
                    <Route path="intentions" element={<Intentions isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
                    <Route path="underAge" element={<UnderAge isMobile={isMobile} />} />
                    <Route path="technicalEffects" element={<TechnicalEffects isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
                    <Route path="timeOfDay" element={<TimeOfDay isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
                    <Route path="strainType" element={<StrainType isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
                    <Route path="typeOfEffect" element={<TypeOfEffect isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
                    <Route path="mostImportant" element={<MostImportant isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
                    <Route path="format" element={<Format isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
                    <Route path="consumptionMethod" element={<ConsumptionMethod isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
                    <Route path="qualityLevel" element={<WhatQuality isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
                    <Route path="summaryPreview" element={<SummaryPreview isMobile={isMobile} user={user}  />} />
                    <Route path="summary" element={<Summary isMobile={isMobile} handleAddUserData={handleAddUserData}  />} />
                    <Route path="product" element={<ProductDetail isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
                    {/* <Route path="product/:id" element={<ProductDetail isMobile={isMobile} handleAddUserData={handleAddUserData} />} /> */}
                    {/* <Route path="summary" element={<Summary isMobile={isMobile} handleAddUserData={handleAddUserData} user={localUser} inventory={inventory} handleAddInventory={handleAddInventory} handleUpdateCart={handleUpdateCart} />} /> */}
                    {/* <Route path=":storeId/:productName" element={<ProductDetail isMobile={isMobile} handleAddUserData={handleAddUserData} user={localUser} inventory={inventory} handleUpdateCart={handleUpdateCart} cart={cart}/>} /> */}
                    <Route path="checkout" element={<Checkout isMobile={isMobile} />} />
                    <Route path="/*" element={<AuthenticatedRoutes />} />
                  
                    {/* <Route path="checkout" element={<Checkout isMobile={isMobile} handleUpdateCart={handleUpdateCart} cart={cart}/>} /> */}
                    {/* <Route path="checkout/placeOrder" element={<PlaceOrder user={localUser} isMobile={isMobile} handlePlacedOrder={handlePlacedOrder} clearCart={clearCart} cart={cart}/>  } />
                    <Route path="checkout/completeOrder" element={<CompleteOrder user={localUser} isMobile={isMobile} orderData={orderData} cart={cart} updateUserData={lookupUserData}/>} />
                    <Route path="profile" element={<Profile isMobile={isMobile} user={localUser} cart={cart}/>} />
                    <Route path="cb" element={<AuthenticatedUser isMobile={isMobile} user={localUser} cart={cart}/>} />
                  <Route path="signout" element={<SignOut isMobile={isMobile} user={localUser} cart={cart}/>} /> */}
                  </Routes>
                </Box>
              </Box>
            </Box>
          </Router>
        </ChakraProvider>
    </BackgroundPatternContext.Provider>
  );
};

export default (App);
