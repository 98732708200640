import { useState } from "react";
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { Circle } from 'react-awesome-shapes';
import { 
    Box, 
    Button, 
    Container, 
    Center, 
    Text, 
    Image, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader, 
    ModalCloseButton, 
    ModalOverlay, 
    useDisclosure, 
    ModalContent, 
    Flex, 
    List, 
    ListIcon, 
    ListItem, 
    HStack, 
    Stack,
    AspectRatio,
    Spacer,
} from '@chakra-ui/react';


const Introduction = (props) => {
    let mobile = props.isMobile;
    let loggedIn = props.loggedIn;
    const viewHeight = window.innerHeight;
    let introVideo = 'https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/1-Welcome_to_Flowerly-v2.mp4';

    return (
        <Center>
            <Flex flexDir={'column'} width={mobile ? '100vw' : '60vw'} h={"90vh"} overflowY={'auto'} >
                <Center flex={1}>
                    <AspectRatio 
                            ratio={1} 
                            margin={'8'} 
                            w={mobile ? (viewHeight > 670 ? "14rem" : "10rem") : "22rem"} 
                            h={mobile ? (viewHeight > 670 ? "14rem" : "10rem") : "22rem"} 
                            style={{ overflow: 'hidden' }}  // Added this line
                            
                            >
                            <video
                                style={{borderRadius: "50%", boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.15)"}}  // Changed this line
                                title={'Flowerly Introduction'}
                                src={introVideo}
                                controls={false}
                                autoPlay={true}
                                allowFullScreen={false}
                                playsInline={true}  // Add this attribute
                                />
                        </AspectRatio>
                    </Center>
                    <Box flex={1} >
                        <Box bgColor={'rgba(255,255,255,.6)'} m={4} p={6} borderRadius={16} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}>
                            <Stack textAlign={'center'}>
                                <Text fontSize={['2xl','4xl']} fontWeight={'bold'}>Welcome to Flowerly</Text>
                                <Text fontSize={['2xl','4xl']} >I'm your assistant Sadie!</Text>
                            </Stack>
                        </Box>
                        <Box bgColor={'rgba(255,255,255,.6)'} m={4} p={6} borderRadius={16} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}>
                            <Stack textAlign={'center'}>
                                <Text fontWeight={'semibold'}>Let’s begin with some questions so I can help find what you’re looking for.</Text>
                            </Stack>
                        </Box>
                    </Box>
                    {/* <Box flex={1} bgColor={'rgba(255,255,255,.6)'} m={2} p={6} borderRadius={16} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'} textAlign={'center'}>
                        <Text fontSize={['md','xl']} fontWeight={'semibold'}>Let’s begin with some questions so I can help find what you’re looking for.</Text>
                    </Box> */}
                    <Box flex={1} textAlign={'center'} paddingBottom={20} m={2} >
                        <Link to="/ageVerify">
                            <Button backgroundColor={'#FF9438'} color="white" w={"96%"}> continue </Button>
                        </Link>
                    </Box>

            </Flex>
        </Center>
        
    );
}

export default Introduction;
