import { Link } from "react-router-dom";
import { Box, Icon, Button, Input, AspectRatio, Center, Select, Radio, RadioGroup, Text, Flex, Heading, Image, Divider, Spacer, HStack, Badge, Stack, useRadio, UseRadioProps, useRadioGroup, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Progress, } from '@chakra-ui/react'
import { FaLocationArrow, FaPercent,FaArrowRight, FaStickyNote,  } from 'react-icons/fa';
import { LiaDirectionsSolid } from 'react-icons/lia';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { isUndefined } from 'lodash';
import Map from './Map';

const Checkout = (props) => {
    // const { user } = useAuthenticator((context) => [context.user]);
    let user = '';
    let mobile = props.isMobile;
    // let cart = props.cart
    let medicalPatient = false
    let subtotal = 180    
    let tax = 20
    let deliveryFee = 1
    let total = subtotal + tax + deliveryFee
    let orderDetails = {
        subtotal: subtotal,
        tax: tax,
        deliveryFee: deliveryFee,
        total: total
    }

    // cart.forEach(item => {
    //     subtotal += (Number(item.pricePerUnit) * Number(item.quantityInCart))
    // })
    let cart = [{name: "Super Lemon Haze", weight: "3.5g", subType: "Sativa", pricePerUnit: "60.00", quantityInCart: 1, image: "https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"}];


    const MobileCheckout = () => {
        return (
            <Box  >
                <Flex flexDir={'column'}>
                    <Box color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} marginBottom={-6}>
                        Cart
                    </Box>
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                        
                            {cart.length === 0 ? 
                                (
                                    <Box textAlign={'center'}>
                                        <Text fontSize={'large'} fontWeight={'bold'}>
                                            Your cart's empty. Add some products first!
                                        </Text>
                                        <Center p={4}>
                                            <Image w={'96px'} src="https://flowerly-app-storage.s3.amazonaws.com/assets/ageVerification/ageWarning.webp"/>
                                        </Center>
                                        <Box flex={1} p={2}>
                                            <Link to={'/summary'}>
                                                <Button variant={'outline'} isFullWidth>Browser Products</Button>
                                            </Link>
                                        </Box>
                                    </Box>
                                ) : ( 
                                cart.map(( item, i ) => {
                                    let quantity = item.quantityInCart
                                    let formatted_data = item
                                   
                                    return (
                                        <Box key={i} className="glassEffect" borderRadius={10} marginTop={i !== 0 && 4}>
                                            <Flex>
                                                <Center flex={1} marginLeft={4} >
                                                    <Flex>
                                                        {/* <Box flex={1}  p={2}>
                                                            <Flex flexDir={'column'} >
                                                                <Center>
                                                                    <Button isDisabled borderRadius={100} variant={'outline'} size={'xs'}> - </Button>
                                                                </Center>    
                                                            </Flex>
                                                            
                                                        </Box> */}
                                                        <Box flex={1} >
                                                            {/* <Flex flexDir={'column'}> */}
                                                               
                                                                <Badge paddingLeft={2} paddingRight={2} fontSize={'lg'} textAlign={'center'}>{quantity ? quantity : 1}</Badge>
                                                            {/* </Flex> */}
                                                        </Box>
                                                        {/* <Box flex={1}  p={2}>
                                                            <Flex flexDir={'column'} >
                                                                <Center>
                                                                    <Button isDisabled borderRadius={100} variant={'outline'} size={'xs'}> + </Button>
                                                                </Center>    
                                                            </Flex>
                                                            
                                                        </Box> */}
                                                        
                                                    </Flex>
                                                    
                                                </Center>
                                                <Center flex={1}>
                                                    <Image p={2} src={formatted_data.productAttributes && formatted_data.productAttributes.productImages[0] ? formatted_data.productAttributes.productImages[0] : "https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"} />
                                                </Center>
                                                <Center flex={3}>
                                                    <Flex flexDir={'column'}>
                                                        <Text fontSize={'lg'} fontWeight={'black'}>{`${formatted_data.productName}`} </Text>
                                                        <Text fontSize={'xs'} fontWeight={'bold'}>{`${formatted_data.strainType}   ${formatted_data.category}`}</Text>
                                                    </Flex>
                                                </Center>
                                                <Center flex={1} fontWeight={'bold'} p={2} marginRight={2}>
                                                    
                                                    <Flex flexDir={'column'}>
                                                        <Center>
                                                            <Text fontSize={'xl'} fontWeight={'bold'}>${formatted_data.pricePerUnit}</Text>
                                                        </Center>
                                                        <Center>
                                                            <Text fontSize={'xs'} fontWeight={'medium'}>{formatted_data.units} {formatted_data.productType}</Text>
                                                        </Center>
                                                    </Flex>
                                                </Center>
                                            </Flex>
                                        </Box>
                                    )
                                })
                            )}
                    </Box>
                    <Box color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} marginBottom={-6} marginTop={-4}>
                        Summary
                    </Box>
                    <Box flex={2} bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                        <Flex flexDir={'column'}>
                            <Box>
                                <Flex>
                                    <Center flex={1} p={2}>
                                        <Center fontSize={'xl'} fontWeight={'bold'} backgroundColor={'gray.500'} borderRadius={10} p={4} w={'100%'}>
                                            Pickup
                                        </Center>
                                    </Center>
                                    <Center flex={1} p={2}>
                                        <Button fontSize={'xl'} fontWeight={'bold'} h={'100%'} borderRadius={10} p={4} variant={'outline'} isDisabled isFullWidth>
                                            Delivery
                                        </Button>
                                    </Center>
                                </Flex>
                            </Box>
                            <Center >
                                <Divider p={2}/>
                            </Center >
                            <Box p={2} >
                                <Flex >
                                    <Box>
                                        <Text>Subtotal</Text>
                                    </Box>
                                    <Spacer />
                                    <Box >
                                        <Text>${subtotal.toFixed(2)}</Text>
                                    </Box>
                                </Flex>
                                <Flex >
                                    <Box>
                                        <Text>Processing fee</Text>
                                    </Box>
                                    <Spacer />
                                    <Box >
                                        <Badge colorScheme='purple'>Free</Badge>
                                    </Box>
                                </Flex>
                                <Flex >
                                    <Box>
                                        <Text>Est. taxes</Text>
                                    </Box>
                                    <Spacer />
                                    <Box >
                                        <Text>calculated at payment</Text>
                                    </Box>
                                </Flex>
                                <Flex fontSize={'lg'} fontWeight={'bold'}>
                                    <Box>
                                        <Text>Estimated total</Text>
                                    </Box>
                                    <Spacer />
                                    <Box >
                                        <Text>${subtotal.toFixed(2)}</Text>
                                    </Box>
                                </Flex>
                            </Box >
                            <Center >
                                <Divider p={2} marginTop={-2}/>
                            </Center >
                            <Box marginTop={2}>

                                    <Box flex={1} p={2}>
                                        {/* <CheckoutButton /> */}
                                    </Box>
                                    <Box flex={1} p={2}>
                                        <Link to={'/summary'}>
                                            <Button variant={'outline'} isFullWidth>Continue Shopping</Button>
                                        </Link>
                                    </Box>
                                
                            </Box>
                          
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        )
    }
    
    const DesktopCheckout = () => {
        return (
        <Box p={6}>
            <Flex >
                <Box flex={1}>
                    <Heading fontSize={'6xl'} fontWeight={'bold'}>Order Submitted</Heading>
                    <Stack alignItems="center" justifyContent="center">

                        <AspectRatio ratio={3 /4} 
                        m={8}
                        p={4} 
                        width="18vw" 
                        maxH="36vh" 
                        borderRadius={'2rem'} 
                        boxShadow={'30px 10px 42px  rgba(0, 0, 0, 0.2)'}
                        >
                            <video
                            style={{borderRadius: "30px"}}
                            title={'Video'}
                            src={"https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Order_Placed-v2.mp4"}
                            controls={false}
                            autoPlay={true}
                            allowFullScreen={false}
                            />
                        </AspectRatio>
                        <Box
                        bgColor={'white'}
                        borderRadius={'2rem'}
                        boxShadow={'30px 10px 42px  rgba(0, 0, 0, 0.2)'}
                        width="25vw" 
                        minW={'15vw'}
                        p={4}
                        m={8}
                        >
                            <Box>
                                <Text p={3} fontSize={'3xl'} fontWeight={'bold'}>Order Placed!  I’ll ping you via email when your order is ready for pickup. </Text>
                                <Text p={3} fontSize={'3xl'} fontWeight={'bold'}>The estimated time is about 1 hour from now. </Text>
                            </Box>
                        </Box>
                        <HStack 
                        p={4}
                        m={8}>
                            <Button colorScheme="orange" bgColor={'#FF9438'}>Can you text me?</Button>
                            <Button colorScheme="orange" bgColor={'#FF9438'}>Awesome Thanks</Button>
                        </HStack>
                    </Stack>
                </Box>
                <Box flex={2} h="calc(100vh - 12px)" position="relative" p={6} >
                    <Flex bgColor={'whiteAlpha.600'} borderRadius={'1.2rem'}>
                        <Text fontSize={'3xl'} fontWeight={'bold'} p={8}>Order Status</Text>
                        <Spacer />
                        <Text fontSize={'3xl'} fontWeight={'bold'} p={8}>👍 Confirmed</Text>
                    </Flex>
                    <Flex marginTop={6}>
                        <Box bgColor={'whiteAlpha.600'} overflowY="scroll" h={'100vh'} borderRadius={'1.2rem'} flex={1} marginRight={3}>
                            <Box p={8}>
                                <Text fontSize={'md'} fontWeight={'bold'} color={'blackAlpha.800'}>Pickup Location</Text>
                            </Box>
                            <Box marginLeft={8}>
                                <Text fontSize={'3xl'} fontWeight={'bold'} >MedMen - Venice(Abbot Kinney)</Text>
                                <Text fontSize={'md'} fontWeight={'bold'} color={'blackAlpha.800'}>10861 Weyburn Ave</Text>
                                <Text fontSize={'md'} fontWeight={'bold'} color={'blackAlpha.800'}>Los Angeles, California 90024</Text>
                                <Text fontSize={'md'} fontWeight={'bold'} color={'blackAlpha.800'}>310-443-9042</Text>
                            </Box>
                            <Box p={8}>
                                <Map />
                            </Box>

                        </Box>
                        <Box bgColor={'whiteAlpha.600'} overflowY="scroll" h={'100vh'} borderRadius={'1.2rem'} flex={1} marginLeft={3}>
                            <Box p={8}>
                                <Text fontSize={'md'} fontWeight={'bold'} color={'blackAlpha.800'}>Estimated Pickup Time</Text>
                            </Box>
                            <Box margin={8}>
                                <Center>
                                    <Text bgGradient='linear(to-l, #FF9438, #FFDD56)' bgClip='text' fontSize={'6rem'} stroke={'3px'} fontWeight={'bold'} >4:20 PM</Text>
                                </Center>
                                <Progress colorScheme="orange" size={'lg'} borderRadius={'3rem'} isIndeterminate />
                                <Center marginTop={4}>
                                    <Text  bgGradient='linear(to-r, #FF9438, #FFDD56)' bgClip='text' fontSize={'2xl'} fontWeight={'bold'} >Your order is being prepared</Text>
                                </Center>
                                <Center marginTop={4}>
                                    <Text fontSize={'md'} color={'blackAlpha.800'}>We'll update you via email when your order is ready for pickup.</Text>
                                </Center>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
                {/* <Box flex={1} h="calc(100vh - 12px)" position="relative" p={6} overflowY="auto">
                    <Box bgColor={'whiteAlpha.600'} overflowY="scroll" h={'100vh'} borderRadius={'1.2rem'}>
                        <Text fontSize={'3xl'} fontWeight={'bold'} p={8}>Order Details</Text>
                      
                        <Flex flexDir={'column'} p={8}>
                            
                        </Flex>
                    </Box>
                </Box> */}
            </Flex>
        </Box>
        )
    }
    
    return (
        <>
        {mobile ? <MobileCheckout /> : <DesktopCheckout orderDetails /> }
        </>
    );
  }

export default Checkout;
  