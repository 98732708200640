import UniversalQuestion from '../questionsv3/UniversalAvatarPrompt';
import { mostImportantOptions } from './../../utilities/menuOptions'

const MostImportant = (props) => {

    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={mostImportantOptions.firstLineText}
              secondLineText={mostImportantOptions.secondLineText} 
              fullText={mostImportantOptions.fullText}
              answers={mostImportantOptions.options}
              progressValue={mostImportantOptions.progressValue}
              avatar={mostImportantOptions.avatar}
              route={mostImportantOptions.route}
              prevRoute={mostImportantOptions.prevRoute}
              imageGrid={mostImportantOptions.imageGrid}
              scroll={mostImportantOptions.scroll}
  
    />
  }
  
export default MostImportant;