import UniversalQuestion from '../questionsv3/UniversalAvatarPrompt';
import { ageOptions } from './../../utilities/menuOptions'

const AgeVerify = (props) => {
    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={ageOptions.firstLineText}
              secondLineText={ageOptions.secondLineText} 
              answers={ageOptions.options}
              progressValue={ageOptions.progressValue}
              avatar={ageOptions.avatar}
              forcedRoute={ageOptions.forcedRoute}
              prevRoute={ageOptions.prevRoute}
              fullText={ageOptions.fullText}
              
  
    />
  }
  
export default AgeVerify;