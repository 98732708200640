// useCheckRoute.js
import { useSelector } from 'react-redux';

const useCheckRoute = (currentRoute) => {
    const quizResults = useSelector(state => state.quizResults);
    const { experienceLevel } = quizResults;
    
    switch (currentRoute) {
        case 'intentions':
            if (experienceLevel === 'experienced') {
                return '/strainType';
            } else {
                return '/timeOfDay';
            } 
        default:
            return null;
    }
}

export default useCheckRoute;
