import UniversalQuestion from './UniversalQuestion';
import { formatOptions } from './../../utilities/menuOptions'

const Format = (props) => {

    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={formatOptions.firstLineText}
              secondLineText={formatOptions.secondLineText} 
              fullText={formatOptions.fullText}
              answers={formatOptions.options}
              progressValue={formatOptions.progressValue}
  
    />
  }
  
export default Format;