import { useState } from "react";
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { Circle } from 'react-awesome-shapes';
import { 
    Box, 
    Button, 
    Container, 
    Center, 
    Text, 
    Image, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader, 
    ModalCloseButton, 
    ModalOverlay, 
    useDisclosure, 
    ModalContent, 
    Flex, 
    List, 
    ListIcon, 
    ListItem, 
    HStack, 
    Stack 
} from '@chakra-ui/react';

const BottomRightImage = () => (
    <Box
      display={{ base: "block", lg: "block" }}
      position="fixed"
      bottom="0"
      right="0"
      h={{ base: "40vh", lg: "80vh" }}
      zIndex="1"
    >
      <Image
        src="https://flowerly-app-storage.s3.amazonaws.com/assets/univeral/hand-holding-cannabis.webp"
        objectFit="cover"
        height="100%"
        width="100%"
      />
    </Box>
);

const BottomCenterImage = () => (
    <Box
      display={{ base: "block", lg: "block" }}
      position="fixed"
      bottom="-10"
      left="50%"  // position the left edge at the center of the viewport
      transform="translateX(-50%)"  // move the box left by half of its own width to center it
      h={{ base: "50vh", lg: "80vh" }}
      w={'60%'}
      zIndex="1"
    >
      <Image
        src="https://flowerly-app-storage.s3.amazonaws.com/assets/univeral/hand-holding-cannabis.webp"
        objectFit="contain"
        height="100%"
        width="100%"
      />
    </Box>
);


const Welcome = (props) => {
    let mobile = props.isMobile;
    let loggedIn = props.loggedIn;

    return (
        <Box>
            {
                mobile ? (
                <Box> 
                    <BottomCenterImage />
                    <Stack >
                        <Stack p={10} fontSize={['3xl', '5xl']}> 
                            <Box >
                                Cannabis shopping 
                            </Box>
                            <Box fontWeight={'bold'} >
                                for the future.
                            </Box>
                        </Stack>
                        <Center p={10}>
                            <Link to={'/introduction'}>
                                <Button
                                    height={['60px', '80px', '100px']}
                                    width={['200px', '250px', '300px']}
                                    lineHeight='1.2'
                                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                                    border='1px'
                                    px='8px'
                                    borderRadius='40px'
                                    fontSize={['24px', '26px', '30px']}
                                    fontWeight='semibold'
                                    bg='#C5F277'
                                    borderColor='#C5F277'
                                    color='#000'
                                    zIndex={3}
                                    position={'relative'}
                                    _hover={{ 
                                        bg: '#FF9438',
                                        transform: 'scale(1.05)',
                                        borderColor: '#FF9438' 
                                    }}
                                    _active={{
                                        bg: '#FFDD56',
                                        transform: 'scale(1.1)',
                                        borderColor: '#FFDD56',
                                    }}
                                    _focus={{
                                        boxShadow:
                                        '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                                    }}
                                >
                                    <Flex align="center" justify="center" height="100%">
                                        <Text>Get Started</Text>
                                        <Image src="https://flowerly-app-storage.s3.amazonaws.com/assets/univeral/arrow-forward-icon.svg" boxSize="20px" ml="2" />
                                    </Flex>
                                </Button>
                            </Link>
                        </Center>
                    </Stack>
                </Box>) : (
                    <Box>
                        <Box padding={'6vh'}>
                            <BottomRightImage />
                            <Flex flexDir={'row'}>
                                <Box flex={[3]} > 
                                    <Stack position="relative" zIndex="2" padding={20} paddingTop={'20vh'}>
                                        <Box flex={1} fontSize={['3xl', '4xl', '5xl']}>
                                            A personal cannabis shopping experience 
                                        </Box>
                                        <Box flex={1} fontWeight={'bold'} fontSize={['4xl', '5xl', '6xl']}>
                                            for the future.
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box flex={[1]} />
                            </Flex>
                           
                            <Box padding={20}>
                                <Link to={'/introduction'}>
                                    <Button
                                        height={['60px', '80px', '100px']}
                                        width={['200px', '250px', '300px']}
                                        lineHeight='1.2'
                                        transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                                        border='1px'
                                        px='8px'
                                        borderRadius='40px'
                                        fontSize={['24px', '26px', '30px']}
                                        fontWeight='semibold'
                                        bg='#C5F277'
                                        borderColor='#C5F277'
                                        color='#000'
                                        zIndex={3}
                                        position={'relative'}
                                        _hover={{ 
                                            bg: '#FF9438',
                                            transform: 'scale(1.05)',
                                            borderColor: '#FF9438' 
                                        }}
                                        _active={{
                                            bg: '#FFDD56',
                                            transform: 'scale(1.1)',
                                            borderColor: '#FFDD56',
                                        }}
                                        _focus={{
                                            boxShadow:
                                            '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                                        }}
                                    >
                                        <Flex align="center" justify="center" height="100%">
                                            <Text>Get Started</Text>
                                            <Image src="https://flowerly-app-storage.s3.amazonaws.com/assets/univeral/arrow-forward-icon.svg" boxSize="20px" ml="2" />
                                        </Flex>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Box>  )
            }
        </Box>
        
    );
}

export default Welcome;
