import { useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { selectSearchQuery, updateSearch } from '../reducers/searchQuery';
import { selectQuizResults, updateField } from '../reducers/quizResults';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { Box, Flex, Heading, Progress, Divider, Image, Spacer, Button, Container, Center, CircularProgress, CircularProgressLabel, Text, ProgressLabel, Checkbox, AspectRatio, useRadio, useRadioGroup, Stack, Badge, Grid, GridItem, HStack, TableContainer, Table, TableCaption, Thead, Tbody ,Td, Tr, Th, Tfoot, Accordion, AccordionIcon, AccordionItem, AccordionPanel, AccordionButton, } from '@chakra-ui/react';
import { CheckIcon, CheckCircleIcon, ArrowForwardIcon, ArrowBackIcon, StarIcon, TriangleDownIcon } from '@chakra-ui/icons'
import { formatOptions, consumptionOptions, ageOptions, intentionsOptions, 
    medicalOptions, mostImportantOptions, technicalOptions, customerType, experienceType,
    strengthOptions, durationOptions, timeOfDayOptions, qualityOptions } from '../utilities/menuOptions'
import { LuShoppingCart } from 'react-icons/lu'

// import { BsCheckSquareFill, BsSquare } from 'react-i'
import { has } from 'lodash'


const Summary = (props) => {
    const { isMobile } = props;
    const [selectedOption, setSelectedOption] = useState(null); // no initial selection
    const [showSearchOptions, setShowSearchOptions] = useState(false);
    const [shouldPlayVideo, setShouldPlayVideo] = useState(true);
    const dispatch = useDispatch();
    const quizResults = useSelector(selectQuizResults);
    const searchQuery = useSelector(selectSearchQuery);
    const desktopToTabletWidth = 1600;

    const addPrefix = (inputStr) => {
        if (typeof inputStr !== 'string') {
            return null;  // or handle it differently if you want
        }
      
        // Check if the string ends with "est"
        if (inputStr.slice(-3) === 'est') {
            return 'the ' + inputStr;
        } else {
            return 'a ' + inputStr;
        }
    }

    const viewHeight = window.innerHeight;
    const viewWidth = window.innerWidth;
    
    const computeAndSaveQuery = () => {
        
        const { consumptionMethod, intentionFeelings, preferredQuality, preferredStrength, preferredTimeOfDay, visitReason } = quizResults;
        const updatedPreferredStrength = addPrefix(preferredStrength);

        // function joinStrings(arr) {
        //     console.log('what is this', arr, typeof arr )
        //     // let parsed = JSON.parse(arr);
        //     // return parsed.join(', ');
        // }
        // const updatedIntentionFeelings = joinStrings(intentionFeelings)
        const constructedSearchQuery = `${preferredQuality.charAt(0).toUpperCase() + preferredQuality.slice(1)} ${visitReason} ${consumptionMethod} with ${updatedPreferredStrength} effect for ${intentionFeelings} ${preferredTimeOfDay}s.`
        dispatch(updateSearch({value: constructedSearchQuery}));
    
        return constructedSearchQuery;
    }

    const handleOptionsToggle = () => {
        searchQuery?.length > 0 && (
            computeAndSaveQuery()
        )
        setShowSearchOptions(!showSearchOptions);
        
    }

    const handleDataSaveToRedux = (value, fieldName) => {
        dispatch(updateField({value, fieldName}));
    }

    const FlowerlyComponent = ({ options }) => {
        
        return (
          <Box
            // backgroundImage={"url('https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png')"}
            // backgroundSize={'80%'}
            // backgroundRepeat={'no-repeat'}
            // backgroundPosition="center"
            // width={'100%'}
            // borderRadius="20px"
            // p={4} // padding to ensure content doesn't touch the edges
          >
            <Center padding={16}>
                <Image src={"https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"} alt="Flowerly Sample" />
            </Center>
            <HStack spacing={4} padding={4} alignItems="center" justifyContent="center">
              {options.map((option, index) => (
                <Box
                  key={index}
                  borderRadius={'20px'}
                  bg={selectedOption === index ? 'whiteAlpha.800' : 'whiteAlpha.600'}
                  borderWidth={selectedOption === index ? "6px" : "3px"} // Add a border when the item is selected
                  borderColor={"#9CDF27"} // Set the border color to #9CDF27
                  p={8}
                  onClick={() => setSelectedOption(index)}
                  cursor="pointer"
                  textAlign={'center'}
                >
                  <Text fontSize={'6xl'} fontWeight={selectedOption === index ? 'bold' : 'normal'} color={selectedOption === index ? 'black' : 'blackAlpha.800'}>
                    ${option.price}
                  </Text>
                  <Text fontSize={'2xl'} fontWeight={selectedOption === index ? 'bold' : 'normal'} color={selectedOption === index ? 'black' : 'blackAlpha.800'}>
                    {option.quantity}
                  </Text>
                </Box>
              ))}
            </HStack>
            <Center paddingTop={'8'}>
                <Link to="/checkout">
                    <Button variant={'ghost'} colorScheme='black' bgColor={'#C7F27D'} size='lg'>
                        Add To Cart
                    </Button>
                </Link>
            </Center>
          </Box>
        );
      };
  
    
   const FlowerlyCards = () => {
    const [cardSelected, setCardSelected] = useState(false)
    
    const handleCardSelected = (i) => {
        setCardSelected(i)
    }

    const fakeData = [{name: "Super Lemon Haze", brand: "matter", recommendationScore: 95, strainType: 'Sativa', timeOfDay: ['morning, daytime'], effects: ['happy', 'energetic', 'creative'], flavors: ['citrus', 'lemon', 'sweet'], terpenes: ['limonene', 'pinene', 'myrcene'], thc: 0.25, cbd: 0.05, cbn: 0.01, image: "https://images.squarespace-cdn.com/content/v1/62929ff218fbff1499eba77a/a177695e-c87d-451d-8a6e-6738a1dae838/Northern+Emeralds+-+8th+Jar+and+Nug+-+Emerald+OG_2+%281%29.jpg?format=750w"},
    {name: "Electric Blue", brand: "Connect", recommendationScore: 94, strainType: 'Sativa', timeOfDay: ['morning, daytime'], effects: ['happy', 'creative'], flavors: ['citrus', 'lemon'], terpenes: ['limonene', 'pinene', 'myrcene'], thc: 0.25, cbd: 0.05, cbn: 0.01, image: "https://images.squarespace-cdn.com/content/v1/62929ff218fbff1499eba77a/a177695e-c87d-451d-8a6e-6738a1dae838/Northern+Emeralds+-+8th+Jar+and+Nug+-+Emerald+OG_2+%281%29.jpg?format=750w"},
    {name: "Honey Flower", brand: "a golden state", recommendationScore: 93, strainType: 'Sativa', timeOfDay: ['morning', 'daytime'], effects: ['uplifting', 'calming', 'creative'], flavors: ['melon', 'tangy', 'earthy'], terpenes: ['limonene', 'pinene', 'myrcene'], thc: 0.25, cbd: 0.05, cbn: 0.01, image: "https://images.squarespace-cdn.com/content/v1/62929ff218fbff1499eba77a/a177695e-c87d-451d-8a6e-6738a1dae838/Northern+Emeralds+-+8th+Jar+and+Nug+-+Emerald+OG_2+%281%29.jpg?format=750w"}]

    const card = (data, i) => {
        return (
            <Link to="/product">
                <Box
                    flex={1}
                    padding={'8'}
                    margin="8"
                    borderRadius={'20px'}
                    backgroundColor={'white'}
                    boxShadow={'30px 10px 42px  rgba(0, 0, 0, 0.2)'}
                    onClick={() => handleCardSelected(i)}    
                    w={'20vw'}
                    h={'54vh'}
                >
                    <Flex>
                        <Box flex={1}>
                            <AspectRatio maxW='560px' ratio={1}>
                                <Image src={data.image} alt="Flowerly" objectFit="cover" borderRadius="20px" />
                            </AspectRatio>
                        </Box>
                        <Box flex={1} paddingLeft={'4'}>
                            <Flex flexDir={'column'} bgColor={'#C7F27D'} h={'100%'} borderRadius={'12px'}>
                                <Box flex={1} p={'2'}>
                                    <HStack>
                                        <StarIcon />
                                        <Text>
                                        Score
                                        </Text>
                                    </HStack>
                                </Box>
                                <Box flex={1} >
                                    <Flex flexDir={'row'} >
                                        <Box flex={1} />
                                        <Box flex={1} >
                                            <CircularProgress size='80px' value={data.recommendationScore} color='orange.400' thickness='8px'>
                                                <CircularProgressLabel fontSize={'xl'} fontWeight={'bold'}>{data.recommendationScore}%</CircularProgressLabel>
                                            </CircularProgress>
                                        </Box>
                                        <Box flex={1} />
                                    </Flex>
                                    
                                </Box>
                                <Box flex={1} textAlign={'center'}>
                                    Very high
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                    <Flex flexDir={'row'} paddingTop={'2'}>
                        <Box flex={1} paddingLeft={'4'}>
                            <Text fontSize={'2xl'} fontWeight={'bold'}>
                                {data.name}
                            </Text>
                            <HStack>
                                <Text fontSize={'1xl'} fontWeight={'thin'}>
                                    by
                                </Text>
                                <Text fontSize={'1xl'} fontWeight={'bold'}>
                                    {data.brand}
                                </Text>
                            </HStack>
                        </Box>
                    </Flex>
                    <Flex flexDir={'row'} paddingTop={'2'}>
                        <Box flex={1} paddingLeft={'4'}>
                            <HStack>
                                <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/morning-b.webp"} alt="Flowerly"/>
                                
                                <Flex flexDir={'column'}> 
                                    <Text fontSize={'xl'} fontWeight={'bold'}>
                                        {data.strainType}
                                    </Text>
                                    <Text fontSize={'lg'} fontWeight={'thin'}>
                                        <HStack>
                                        {data.timeOfDay.map((item, i) => {
                                                return (
                                                    <Text key={item + i} fontSize={'lg'} fontWeight={'thin'}>
                                                        {item}
                                                    </Text>
                                                )
                                            }
                                        )}
                                        </HStack>
                                    </Text>

                                </Flex>

                            </HStack>
                        </Box>
                    </Flex>
                    <Flex flexDir={'row'} paddingTop={'2'}>
                        <Box flex={1} paddingLeft={'4'}>
                            <HStack>
                                <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/happy-b.webp"} alt="Flowerly"/>
                                
                                <Flex flexDir={'column'}> 
                                    <Text fontSize={'xl'} fontWeight={'bold'}>
                                        Effects
                                    </Text>
                                    <Text fontSize={'lg'} fontWeight={'thin'}>
                                        <HStack>
                                        {data.effects.map((item, i) => {
                                                return (
                                                    <Text key={item + i} fontSize={'lg'} fontWeight={'thin'}>
                                                        {item}
                                                    </Text>
                                                )
                                            }
                                        )}
                                        </HStack>
                                    </Text>

                                </Flex>

                            </HStack>
                        </Box>
                    </Flex>
                    <Flex flexDir={'row'} paddingTop={'2'}>
                        <Box flex={1} paddingLeft={'4'}>
                            <HStack>
                                <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/hungry-b.webp"} alt="Flowerly"/>
                                
                                <Flex flexDir={'column'}> 
                                    <Text fontSize={'xl'} fontWeight={'bold'}>
                                        Aroma
                                    </Text>
                                    <Text fontSize={'lg'} fontWeight={'thin'}>
                                        <HStack>
                                        {data.flavors.map((item, i) => {
                                                return (
                                                    <Text key={item + i} fontSize={'lg'} fontWeight={'thin'}>
                                                        {item}
                                                    </Text>
                                                )
                                            }
                                        )}
                                        </HStack>
                                    </Text>

                                </Flex>

                            </HStack>
                        </Box>
                    </Flex>
                    <Flex flexDir={'row'} paddingTop={'2'}>
                        <Box flex={1} />
                        <Box flex={1} >
                            <Button variant={'outline'} colorScheme='black' bgColor={'#C7F27D'}> 
                                Add To Cart
                            </Button>  
                        </Box>          
                        <Box flex={1} />
                    </Flex>
                </Box>
            </Link>
        )
        
    }

    return (
        <Box  h={'60vh'} m={'4'}>
         
        </Box>
    )


    }

    const optionsTest = [
        {price: 60, quantity: '3.5g'},
        {price: 90, quantity: '7g'}
        ]

    const MobilePriceQuantityAddToCart = () => { return (
            <HStack mt={4} >
                <HStack spacing={2} padding={2} alignItems="center" justifyContent="center">
                    {optionsTest.map((option, index) => (
                        <Box
                        key={index}
                        borderRadius={'6px'}
                        bg={selectedOption === index ? 'whiteAlpha.800' : 'whiteAlpha.600'}
                        borderWidth={selectedOption === index ? "4px" : "2px"} // Add a border when the item is selected
                        borderColor={"#9CDF27"} // Set the border color to #9CDF27
                        p={1}
                        onClick={() => setSelectedOption(index)}
                        cursor="pointer"
                        textAlign={'center'}
                        w={'20vw'}
                        h={viewHeight < 750 ? '8vh' : '6vh'}
                        >
                            <Text fontSize={'sm'} fontWeight={selectedOption === index ? 'bold' : 'normal'} color={selectedOption === index ? 'black' : 'blackAlpha.800'}>
                                ${option.price}
                            </Text>
                            <Text fontSize={'xs'} fontWeight={selectedOption === index ? 'bold' : 'normal'} color={selectedOption === index ? 'black' : 'blackAlpha.800'}>
                                {option.quantity}
                            </Text>
                        </Box>
                    ))}
                </HStack>
                <Spacer />
                <Box>
                    <Link to={"/checkout"}>
                        <Button bgColor={"#C5F278"} fontSize={'2xl'} h={viewHeight < 750 ? '8vh' : '6vh'} borderRadius={'10px'} borderWidth={'2px'} borderColor={'black'} w={'100%'}>
                            <HStack>

                            <LuShoppingCart />
                            <Spacer/>
                            <Text fontSize={'sm'}>
                                Add to Cart
                            </Text>
                            </HStack>
                        </Button>
                    </Link>
                </Box>
            </HStack>
    )};
                    
    const TabletPriceQuantityAddToCart = () => { return (
        <Stack mt={4} >
            <HStack spacing={2} padding={2} alignItems="center" justifyContent="center">
                {optionsTest.map((option, index) => (
                    <Box
                    key={index}
                    borderRadius={'6px'}
                    bg={selectedOption === index ? 'whiteAlpha.800' : 'whiteAlpha.600'}
                    borderWidth={selectedOption === index ? "4px" : "2px"} // Add a border when the item is selected
                    borderColor={"#9CDF27"} // Set the border color to #9CDF27
                    p={1}
                    onClick={() => setSelectedOption(index)}
                    cursor="pointer"
                    textAlign={'center'}
                    w={'20vw'}
                    h={viewHeight < 750 ? '8vh' : '6vh'}
                    >
                        <Text fontSize={'sm'} fontWeight={selectedOption === index ? 'bold' : 'normal'} color={selectedOption === index ? 'black' : 'blackAlpha.800'}>
                            ${option.price}
                        </Text>
                        <Text fontSize={'xs'} fontWeight={selectedOption === index ? 'bold' : 'normal'} color={selectedOption === index ? 'black' : 'blackAlpha.800'}>
                            {option.quantity}
                        </Text>
                    </Box>
                ))}
            </HStack>
            <Spacer />
            <Center>
                <Link to={"/checkout"}>
                    <Button bgColor={"#C5F278"} fontSize={'2xl'} h={viewHeight < 750 ? '8vh' : '6vh'} p={1} borderRadius={'10px'} borderWidth={'2px'} borderColor={'black'} w={'40vw'}>
                        <HStack>

                        <LuShoppingCart />
                        <Spacer/>
                        <Text fontSize={'sm'}>
                            Add to Cart
                        </Text>
                        </HStack>
                    </Button>
                </Link>
            </Center>
        </Stack>
)};

    const mobileView = <Box >
                            <Center >
                                <Flex flexDir={'column'} width={'100%'}>
                                    <Box h={"122px"} paddingRight={'6vw'} paddingLeft={'6vw'} >
                                        <Box flex={1} h={"122px"} bgColor={'rgba(255,255,255,.4)'} borderRadius={'10px'} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}>
                                            <HStack h={'100%'}>
                                                <Flex flex={1} width={'100%'} h={"100%"}>
                                                    <AspectRatio 
                                                        flexGrow={1}
                                                        height={"100%"} 
                                                        width={'100px'}>
                                                        <video
                                                        style={{borderRadius: "10px", boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)' }}
                                                        title={'Video'}
                                                        src={"https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Good_Match-v2.mp4"}
                                                        controls={false}
                                                        autoPlay={selectedOption === null ? true : false}
                                                        playsInline={true}
                                                        allowFullScreen={false}
                                                        />
                                                    </AspectRatio>
                                                </Flex>
                                                <Box p={3} width={'100%'} >
                                                    <Heading fontSize='xs'>Super Lemon Haze is great for creative days and calming mornings.</Heading>
                                                </Box>
                                            </HStack>
                                        </Box>
                                    </Box>
                                    <Box mt={2} paddingRight={'6vw'} paddingLeft={'6vw'} overflowY={'scroll'} h={(viewHeight > 750) ? "75vh" : "60vh"}>
                                        <Box>
                                            <Heading>
                                                Super Lemon Haze
                                            </Heading>
                                            <Text>
                                                by matter
                                            </Text>
                                        </Box>
                                        <Center>
                                            <Image borderRadius={'14px'} w='56vw' src={"https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"} />
                                        </Center>
                                        <MobilePriceQuantityAddToCart />


                                        <Accordion defaultIndex={[0]} allowMultiple>
                                            <AccordionItem>
                                                <h2>
                                                <AccordionButton _expanded={{ bg: 'rgba(0,0,0,0)', color: 'black' }}>
                                                    <Box as="span" flex='1' textAlign='left' fontWeight={'bold'} fontSize={'lg'}>
                                                    Hand Picked For You
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    <Box > 
                                                        <HStack paddingTop={'4'}>
                                                            <Box>
                                                                <CircularProgress size='70px' value={95} color='orange.400' thickness='4px'>
                                                                    <CircularProgressLabel fontSize={'xl'} fontWeight={'bold'}>{95}%</CircularProgressLabel>
                                                                </CircularProgress>
                                                            </Box>
                                                            <Box>
                                                                <Text fontSize={'sm'} fontWeight={'semibold'}>
                                                                Super Lemon Haze is perfectly suited for crushing work and working out. Great choice!
                                                                </Text>
                                                            </Box>
                                                        </HStack>
                                                        <Flex flexDir={'column'} paddingTop={'2'}>
                                                            <Stack flex={1} paddingTop={"8px"}>
                                                                <Flex>
                                                                    <Text fontSize={'sm'} fontWeight={'bold'}>
                                                                        Sativa | Whole Flower | 3.5g
                                                                    </Text>
                                                                    <Spacer />
                                                                    <Text fontSize={'sm'} fontWeight={'bold'}>
                                                                        100%
                                                                    </Text>
                                                                </Flex>
                                                                <Box backgroundColor={'white'} borderRadius={'30px'} height={'10px'} width={'100%'}>
                                                                    <Progress bgGradient='linear(to-l, #FFDD56, #FF9438)' colorScheme={''} height='10px' value={100} borderRadius={30}/>
                                                                </Box>
                                                            </Stack>
                                                            <Stack flex={1} paddingTop={"1px"} marginTop={"10px"}>
                                                                <Flex>
                                                                    <Text fontSize={'sm'} fontWeight={'bold'}>
                                                                        Creative | Focused | Uplifting
                                                                    </Text>
                                                                    <Spacer />
                                                                    <Text fontSize={'sm'} fontWeight={'bold'}>
                                                                        90%
                                                                    </Text>
                                                                </Flex>
                                                                <Box backgroundColor={'white'} borderRadius={'30px'} height={'10px'} width={'100%'}>
                                                                    <Progress bgGradient='linear(to-l, #FFDD56, #FF9438)' colorScheme={''} height='10px' width={'90%'} value={90} borderRadius={30}/>
                                                                </Box>
                                                            </Stack>
                                                            <Stack flex={1} paddingTop={"1px"} marginTop={"10px"}>
                                                                <Flex>
                                                                    <Text fontSize={'sm'} fontWeight={'bold'}>
                                                                        Floral | Citrus | Sweet
                                                                    </Text>
                                                                    <Spacer />
                                                                    <Text fontSize={'sm'} fontWeight={'bold'}>
                                                                        95%
                                                                    </Text>
                                                                </Flex>
                                                                <Box backgroundColor={'white'} borderRadius={'30px'} height={'10px'} width={'100%'}>
                                                                    <Progress bgGradient='linear(to-l, #FFDD56, #FF9438)' colorScheme={''} height='10px' width={'95%'} value={95} borderRadius={30}/>
                                                                </Box>
                                                            </Stack>
                                                        </Flex>
                                                    </Box>
                                                </AccordionPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <h2>
                                                <AccordionButton _expanded={{ bg: 'rgba(0,0,0,0)', color: 'black' }}>
                                                    <Box as="span" flex='1' textAlign='left' fontWeight={'bold'} fontSize={'lg'}>
                                                    AI Summary
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                <HStack flex={1} >
                                                    <Image w="100px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/morning-b.webp"} alt="Flowerly"/>
                                                    <Box flex={3} >
                                                        <Text fontSize={'md'} fontWeight={'bold'}>
                                                            Sativa
                                                        </Text>
                                                        <Text fontSize={'sm'} >
                                                        Best for calming mornings, creative focus, or a sunny hike. 
                                                        </Text>
                                                    </Box>
                                                    <Box flex={1} />
                                                </HStack >
                                                <Flex flex={1} paddingTop={'4'}>
                                                    <Stack textAlign={'center'} flex={1}>
                                                        <Text fontSize={'sm'} fontWeight={'thin'}>
                                                            Effects
                                                        </Text>
                                                        <Flex>
                                                            <Box flex={1}/>
                                                            <Box flex={1}>
                                                                <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/happy-b.webp"} alt="Flowerly"/>
                                                            </Box>
                                                            <Box flex={1}/>
                                                        </Flex>
                                                        <Text fontSize={'xs'} fontWeight={'bold'}>
                                                            Energy, Focus, Creativity
                                                        </Text>
                                                    </Stack>
                                                    <Stack textAlign={'center'} flex={1}>
                                                        <Text fontSize={'sm'} fontWeight={'thin'}>
                                                            Aroma
                                                        </Text>
                                                        <Flex>
                                                            <Box flex={1}/>
                                                            <Box flex={1}>
                                                                <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/happy-b.webp"} alt="Flowerly"/>
                                                            </Box>
                                                            <Box flex={1}/>
                                                        </Flex>
                                                        <Text fontSize={'xs'} fontWeight={'bold'}>
                                                            Citrus, Lemon, Sweet
                                                        </Text>
                                                    </Stack>
                                                    <Stack textAlign={'center'} flex={1}>
                                                        <Text fontSize={'sm'} fontWeight={'thin'}>
                                                            Terpenes
                                                        </Text>
                                                        <Flex>
                                                            <Box flex={1}/>
                                                            <Box flex={1}>
                                                                <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/happy-b.webp"} alt="Flowerly"/>
                                                            </Box>
                                                            <Box flex={1}/>
                                                        </Flex>
                                                        <Text fontSize={'xs'} fontWeight={'bold'}>
                                                            Limonene, Pinene, Myrcene
                                                        </Text>
                                                    </Stack>
                                                </Flex>
                                                </AccordionPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <h2>
                                                <AccordionButton _expanded={{ bg: 'rgba(0,0,0,0)', color: 'black' }}>
                                                    <Box as="span" flex='1' textAlign='left' fontWeight={'bold'} fontSize={'lg'}>
                                                    Description
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                <Box>
                                                    <Text fontSize={'sm'}>
                                                        Super Lemon Haze, sometimes "SLH", is a sativa-dominant hybrid marijuana strain of Lemon Skunk and Super Silver Haze and two time Cannabis Cup.
                                                    </Text>
                                                </Box>
                                                </AccordionPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <h2>
                                                <AccordionButton _expanded={{ bg: 'rgba(0,0,0,0)', color: 'black' }}>
                                                    <Box as="span" flex='1' textAlign='left' fontWeight={'bold'} fontSize={'lg'}>
                                                    Cannabinoids
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    <Flex flexDir={'row'} fontSize={'sm'}>
                                                        <Box textAlign={'center'} flex={1} m={2}>
                                                            <Center>
                                                                <Text>CBG</Text>
                                                            </Center>
                                                            <CircularProgress size='60px' value={25} color='orange.400' thickness='6px'>
                                                                <CircularProgressLabel fontSize={'sm'} fontWeight={'bold'}>{25}%</CircularProgressLabel>
                                                            </CircularProgress>
                                                            <Text fontSize={'xs'}>Anti-Anxiety</Text>
                                                        </Box>
                                                        <Box textAlign={'center'} flex={1} m={2}>
                                                            <Center>
                                                                <Text>THCV</Text>
                                                            </Center>
                                                            <CircularProgress size='60px' value={25} color='orange.400' thickness='6px'>
                                                                <CircularProgressLabel fontSize={'sm'} fontWeight={'bold'}>{25}%</CircularProgressLabel>
                                                            </CircularProgress>
                                                            <Text fontSize={'xs'}>Energy</Text>
                                                        </Box>
                                                        <Box textAlign={'center'} flex={1} m={2}>
                                                            <Center>
                                                                <Text>CBD</Text>
                                                            </Center>
                                                            <CircularProgress size='60px' value={25} color='orange.400' thickness='6px'>
                                                                <CircularProgressLabel fontSize={'sm'} fontWeight={'bold'}>{25}%</CircularProgressLabel>
                                                            </CircularProgress>
                                                            <Text fontSize={'xs'}>Pain</Text>
                                                        </Box>
                                                        <Box textAlign={'center'} flex={1} m={2}>
                                                            <Center>
                                                                <Text>CBC</Text>
                                                            </Center>
                                                            <CircularProgress size='60px' value={25} color='orange.400' thickness='6px'>
                                                                <CircularProgressLabel fontSize={'sm'} fontWeight={'bold'}>{25}%</CircularProgressLabel>
                                                            </CircularProgress>
                                                            <Text fontSize={'xs'}>Anti-Cancer</Text>
                                                        </Box>
                                                    </Flex>
                                                    <Box paddingBottom={3}>
                                                        <Box backgroundColor={'white'} borderRadius={'30px'} height={'8px'} width={'100%'} >
                                                            <Progress bgGradient='linear(to-l, #FFDD56, #FF9438)' colorScheme={''} height='8px' value={100} borderRadius={30}/>
                                                        </Box>
                                                    </Box>
                                                    <HStack>
                                                        <Box flex={1} textAlign={'center'}>
                                                            <Text fontSize={'sm'} fontWeight={'bold'}>
                                                                THC
                                                            </Text>
                                                            <Text fontSize={'md'}>
                                                                23%
                                                            </Text>
                                                        </Box>
                                                        <Box flex={1} textAlign={'center'}>
                                                            <Text fontSize={'sm'} fontWeight={'bold'}>
                                                                CBD
                                                            </Text>
                                                            <Text fontSize={'md'}>
                                                                3%
                                                            </Text>
                                                        </Box>
                                                        <Box flex={1} textAlign={'center'}>
                                                            <Text fontSize={'sm'} fontWeight={'bold'}>
                                                                Ratio
                                                            </Text>
                                                            <Text fontSize={'md'}>
                                                                High-THC
                                                            </Text>
                                                        </Box>
                                                    </HStack>
                                                </AccordionPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <h2>
                                                <AccordionButton _expanded={{ bg: 'rgba(0,0,0,0)', color: 'black' }}>
                                                    <Box as="span" flex='1' textAlign='left' fontWeight={'bold'} fontSize={'lg'}>
                                                    Terpenes
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    <HStack  fontSize={'xs'}>
                                                        <Box textAlign={'center'} flex={1}>
                                                            <Center p={3}>
                                                                <Text>b-Mycrene</Text>
                                                            </Center>
                                                            <CircularProgress size='70px' value={70} color='orange.400' thickness='6px' >
                                                                <CircularProgressLabel fontSize={'sm'} fontWeight={'bold'}>{.34}%</CircularProgressLabel>
                                                            </CircularProgress>
                                                            <Text pt={3}>Anti-Inflammatory</Text>
                                                        </Box>
                                                        <Box textAlign={'center'} flex={1}>
                                                            <Center pb={3}>
                                                                <Text>b-Caryophyllene</Text>
                                                            </Center>
                                                            <CircularProgress size='70px' value={80} color='orange.400' thickness='6px' >
                                                                <CircularProgressLabel fontSize={'sm'} fontWeight={'bold'}>{.24}%</CircularProgressLabel>
                                                            </CircularProgress>
                                                            <Text pt={3}>pain relief</Text>
                                                        </Box>
                                                        <Box textAlign={'center'} flex={1}>
                                                            <Center pb={3}>
                                                                <Text>Limonene</Text>
                                                            </Center>
                                                            <CircularProgress size='70px' value={90} color='orange.400' thickness='6px' >
                                                                <CircularProgressLabel fontSize={'sm'} fontWeight={'bold'}>{.09}%</CircularProgressLabel>
                                                            </CircularProgress>
                                                            <Text pt={3}>immunostimulant</Text>
                                                        </Box>
                                                    </HStack>
                                                </AccordionPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <h2>
                                                <AccordionButton _expanded={{ bg: 'rgba(0,0,0,0)', color: 'black' }}>
                                                    <Box as="span" flex='1' textAlign='left' fontWeight={'bold'} fontSize={'lg'}>
                                                    Ideal Temp + Taste <Badge colorScheme="orange">Beta</Badge>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    <HStack fontSize={'xs'}>
                                                        <Box textAlign={'center'} flex={1}>
                                                            <Text fontWeight={'bold'}>b-Mycrene</Text>
                                                            <Text fontWeight={'bold'}>Mango</Text>
                                                            <Flex paddingTop={3}>
                                                                <Box flex={1}/>
                                                                <Box flex={1}>
                                                                    <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/terpeneTaste/Mango.webp"} alt="Flowerly"/>
                                                                </Box>
                                                                <Box flex={1}/>
                                                            </Flex>
                                                            <Center p={3}>
                                                                <Text >Low Temp</Text>
                                                            </Center>
                                                            <Progress colorScheme={'yellow'} height='10px' value={40} borderRadius={50} mb={2}/>                                                            
                                                            <Text fontWeight={'bold'}>331℉</Text>
                                                            <Text >166℃</Text>
                                                        </Box>
                                                        <Box textAlign={'center'} flex={1}>
                                                            <Text fontWeight={'bold'}>Caryophyllene</Text>
                                                            <Text fontWeight={'bold'}>Black Pepper</Text>
                                                            <Flex  paddingTop={3}>
                                                                <Box flex={1}/>
                                                                <Box flex={1}>
                                                                    <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/terpeneTaste/BlackPepper.webp"} alt="Flowerly"/>
                                                                </Box>
                                                                <Box flex={1}/>
                                                            </Flex>
                                                            <Center p={3}>
                                                                <Text >High Temp</Text>
                                                            </Center>
                                                            <Progress colorScheme={'red'} height='10px' value={80} borderRadius={30} mb={2}/>                                                      
                                                            <Text fontWeight={'bold'}>390℉</Text>
                                                            <Text >199℃</Text>
                                                        </Box>
                                                        <Box textAlign={'center'} flex={1}>
                                                            <Text fontWeight={'bold'}>Limonene</Text>
                                                            <Text fontWeight={'bold'}>Lemon</Text>
                                                            <Flex paddingTop={3}>
                                                                <Box flex={1}/>
                                                                <Box flex={1}>
                                                                    <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/terpeneTaste/Lemon.webp"} alt="Flowerly"/>
                                                                </Box>
                                                                <Box flex={1}/>
                                                            </Flex>
                                                            <Center p={3}>
                                                                <Text >Med Temp</Text>
                                                            </Center>
                                                            <Progress colorScheme={'orange'} height='10px' value={60} borderRadius={30} mb={2}/>                                                     
                                                            <Text fontWeight={'bold'}>348.8℉</Text>
                                                            <Text >176℃</Text>
                                                        </Box>
                                                    </HStack>
                                                </AccordionPanel>
                                            </AccordionItem>
                                            <AccordionItem>
                                                <h2>
                                                <AccordionButton _expanded={{ bg: 'rgba(0,0,0,0)', color: 'black' }}>
                                                    <Box as="span" flex='1' textAlign='left' fontWeight={'bold'} fontSize={'lg'}>
                                                    Effects from Terpenes <Badge colorScheme="orange">Beta</Badge>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                <Box>
                                                    <TableContainer w={'100%'}>
                                                        <Table variant='simple' size={'xs'}>
                                                            <TableCaption>Results may vary. Consult a doctor for medical advice.</TableCaption>
                                                            <Thead>
                                                                <Tr >
                                                                    <Th color={'orange.400'} textAlign={'center'}>b-Myrcene</Th>
                                                                    <Th color={'red.600'} textAlign={'center'}>b-Caryophyllene</Th>
                                                                    <Th color={'yellow.600'} textAlign={'center'}>Limonene</Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody fontSize={'xs'} textAlign={'center'}>
                                                    
                                                                <Tr >
                                                                    <Td>anti-ageing</Td>
                                                                    <Td>anti-inflammatory </Td>
                                                                    <Td>anti-inflammatory </Td>
                                                                </Tr>
                                                                <Tr>
                                                                    <Td>analgesic</Td>
                                                                    <Td>antioxidant</Td>
                                                                    <Td>cancer-fighting</Td>
                                                                </Tr>
                                                                <Tr>
                                                                    <Td>reduced anxiety</Td>
                                                                    <Td>reduced anxiety</Td>
                                                                    <Td>reduced anxiety</Td>
                                                                </Tr>
                                                                <Tr>
                                                                    <Td>sedative</Td>
                                                                    <Td>pain</Td>
                                                                    <Td>stomach issues</Td>
                                                                </Tr>
                                                                <Tr>
                                                                    <Td>antidiabetic</Td>
                                                                    <Td>seizures</Td>
                                                                    <Td></Td>
                                                                </Tr>
                                                            </Tbody>
                                                            
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </Box>
                                </Flex>
                            </Center>
                       </Box>

    const tabletAvatar = <Box
                            
            
            padding={'4'} 
            mt={'8'} 
            mr={'60px'}
            borderRadius={'2rem'} 
            backgroundColor={'white'} 
            boxShadow={'30px 10px 42px  rgba(0, 0, 0, 0.2)'}
            >
            <Text fontSize={['2xl', '3xl']} fontWeight={'bold'}> 
            {searchQuery?.length > 0 ? (
            <Box>
                <Flex flexDir={'row'}>
                    <Center flex={1}> 
                        <AspectRatio h='10vh' w={'50%'} ratio={1} margin={'2'} 
                            >
                            <video
                                style={{borderRadius: "30px"}}
                                title={'Video'}
                                src={"https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Good_Match-v2.mp4"}
                                controls={false}
                                autoPlay={selectedOption === null ? true : false}
                                playsInline={true}
                                allowFullScreen={false}
                                />
                        </AspectRatio>
                    </Center>
                    <Center flex={4}>
                        <Text fontSize={'2xl'} fontWeight={'bold'}>
                            {searchQuery}
                        </Text> 
                    </Center>
                    <Center flex={1}>
                        <Button backgroundColor={"#FF9438"} color={'white'} onClick={handleOptionsToggle}> Refine Search</Button>
                    </Center>
                </Flex>

            </Box>) 
        :   (<Stack pt={4} pb={4} textAlign={'center'}>
            <Text>Sign in or start searching to begin</Text>
            <Link to={'/ageVerify'}> 
                <Button bgColor={'#FF9438'} color={'white'}>Start Searching</Button>
            </Link>
            </Stack>)
            }
        </Text>
    </Box>

    const DesktopBackground = () => {
        const targetRef = useRef(null);
    
        const scrollToTarget = () => {
        targetRef.current.scrollIntoView({ behavior: 'smooth' });
        };
    
        return (
        <Box w={'100%'}>
            <Box borderTop="1px" borderColor="black" width="100vw" position="fixed" ></Box>
                
                <Flex  >
                    { viewWidth > desktopToTabletWidth && searchQuery &&
                        <Box flex={1} textAlign={'center'}>
                            <Flex flexDir={'column'} maxW={'22vw'}>
                            <Flex>
                                <Box flex={1} />
                                <Box flex={1} >
                                <AspectRatio maxW='22vw' ratio={3 /4} margin={'8'} 
                                    width="15vw" 
                                    maxH="30vh" 
                                    borderRadius={'2rem'} 
                                    boxShadow={'30px 10px 42px  rgba(0, 0, 0, 0.2)'}
                                >
                                    {shouldPlayVideo && (
                                        <video
                                            style={{borderRadius: "30px"}}
                                            title={'Video'}
                                            src={"https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Good_Match-v2.mp4"}
                                            controls={false}
                                            autoPlay={selectedOption === null ? true : false}
                                            playsInline={true}
                                            allowFullScreen={false}
                                        />
                                    )}
                                </AspectRatio>
                                </Box>
                                <Box flex={1} />
                            </Flex>
                            <Flex flex={1} flexDir={'column'}>
                                <Box
                                flex={1}
                                padding={'8'} 
                                margin="8"
                                borderRadius={'2rem'} 
                                backgroundColor={'white'} 
                                boxShadow={'30px 10px 42px  rgba(0, 0, 0, 0.2)'}
                                >
                                <Text fontSize={'xl'} fontWeight={'bold'}> 
                                    {searchQuery}
                                </Text>
                                </Box>
                                <Box flex={1} >       
                                <Button backgroundColor={"#FF9438"} color={'white'} onClick={handleOptionsToggle}> Refine Search</Button>
                                </Box>
                            </Flex>
                            </Flex>
                        </Box>
                    }

                    { showSearchOptions ? (
                        <Center>
                                <Box  bgColor={'rgba(255,255,255,.4)'} borderRadius={'30px'} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}  flex={1} m={10} p={4}>
                                    <HStack p={2}>
                                        <Heading p={2}>
                                            Options 
                                        </Heading>
                                        <Spacer />
                                        <Button backgroundColor={"#FF9438"} color={'white'} onClick={handleOptionsToggle}> Save</Button>
                                    </HStack>
                                    <Grid templateColumns='repeat(2, 1fr)' gap={20} overflowY={'scroll'} h={'60vh'} >
                                        <Box w={'100%'} flex={1}>
                                            <Heading fontSize={'xl'} p={4}>
                                                Time of Day
                                            </Heading>
                                            <Center>
                                                <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                                                    {timeOfDayOptions?.options?.map(item => {
                                                        return item.saveDataEntryValue === quizResults.preferredTimeOfDay ? (
                                                            <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                            ) : (
                                                                <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                                )
                                                        })}
                                                </Grid>
                                            </Center>

                                        </Box>
                                        <Box>
                                            <Heading fontSize={'xl'} p={4}>
                                                Visit Reason
                                            </Heading>
                                            <Center>
                                                <Grid templateColumns={'repeat(2, 1fr)'} gap={6}>
                                                    {customerType?.options?.map(item => {
                                                        return item.saveDataEntryValue === quizResults.visitReason ? (
                                                            <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                            ) : (
                                                                <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                                )
                                                        })}
                                                </Grid >
                                            </Center>

                                        </Box>
                                        <Box>
                                            <Heading fontSize={'xl'} p={4}>
                                                Consumption Method
                                            </Heading>
                                            <Center>
                                                <Grid templateColumns='repeat(3, 1fr)' gap={6} p={4}>
                                                    {consumptionOptions?.options?.map(item => {
                                                        return item.saveDataEntryValue === quizResults.consumptionMethod ? (
                                                            <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                            ) : (
                                                                <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                                )
                                                        })}
                                                </Grid>
                                            </Center>

                                        </Box>
                                        <Box>
                                            <Heading fontSize={'xl'} p={4}>
                                                Feelings
                                            </Heading>
                                            <Center>
                                            <Grid templateColumns='repeat(3, 1fr)' gap={6} p={4}>
                                                {intentionsOptions?.options?.map(item => {
                                                    // Check if intentionFeelings is an array and includes the current item
                                                    const isSelected = Array.isArray(quizResults.intentionFeelings) 
                                                        ? quizResults.intentionFeelings.includes(item.saveDataEntryValue) 
                                                        : item.saveDataEntryValue === quizResults.intentionFeelings;

                                                    return isSelected ? (
                                                        <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                    ) : (
                                                        <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName}</Button>
                                                    );
                                                })}
                                            </Grid>

                                            </Center>

                                        </Box>
                                        
                                        
                                        <Box>
                                            <Heading fontSize={'xl'} p={4}>
                                                Quality
                                            </Heading>
                                            <Center>
                                                <Stack>
                                                    {qualityOptions?.options?.map(item => {
                                                        return item.saveDataEntryValue === quizResults.preferredQuality ? (
                                                            <Button bgColor={"#FF9438"} color={'white'}>{item.displayName}</Button>
                                                            ) : (
                                                                <Button variant={'outline'} onClick={() => {handleDataSaveToRedux(item.saveDataEntryValue, item.saveDataEntryName)}}>{item.displayName} </Button>
                                                                )
                                                        })}
                                                </Stack>
                                            </Center>

                                        </Box>
                                    </Grid>

                                </Box>
                        </Center>
                                            ) : (
                        <Flex >
                            <Box overflowY={'scroll'} h={'80vh'} m={4}>
                                {viewWidth < desktopToTabletWidth && 
                                    tabletAvatar
                                }
                                {viewWidth > desktopToTabletWidth ? (
                                    <Box>
                                        <Box marginTop={'6'}>
                                            <Heading fontSize={'7xl'} fontWeight={'bold'}>
                                                Super Lemon Haze
                                            </Heading>
                                        </Box>
                                        <HStack >
                                            <Text fontSize={'4xl'} fontWeight={'thin'}>
                                                by
                                            </Text>
                                            <Text fontSize={'4xl'} fontWeight={'bold'}>
                                                matter
                                            </Text>
                                        </HStack>
                                    </Box>
                                ) : (
                                    <Flex >
                                        <Box flex={1} marginTop={'6'}>
                                            <Box >
                                                <Heading fontSize={'7xl'} fontWeight={'bold'}>
                                                    Super Lemon Haze
                                                </Heading>
                                            </Box>
                                            <HStack >
                                                <Text fontSize={'4xl'} fontWeight={'thin'}>
                                                    by
                                                </Text>
                                                <Text fontSize={'4xl'} fontWeight={'bold'}>
                                                    matter
                                                </Text>
                                            </HStack>
                                            <TabletPriceQuantityAddToCart />
                                        </Box>
                                        <Box flex={1} marginTop={'12'}>
                                            <Center>
                                                <Image borderRadius={'14px'} w='360px' src={"https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"} />
                                            </Center>
                                        </Box>
                                    </Flex>
                                )}
                                
                                <Flex flexDir={'column'} >
                                    <Flex flexDir={'row'} paddingTop={'6'}>
                                        <Box flex={1} p={6}>
                                            <Text fontSize={'3xl'} fontWeight={'bold'}>
                                                AI Summary
                                            </Text>
                                            <HStack flex={1} >
                                                <Image w="100px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/morning-b.webp"} alt="Flowerly"/>
                                                <Box flex={3} >
                                                    <Text fontSize={'xl'} fontWeight={'thin'}>
                                                        Sativa
                                                    </Text>
                                                    <Text fontSize={'lg'} >
                                                    Best for calming mornings, creative focus, or a sunny hike. 
                                                    </Text>
                                                </Box>
                                                <Box flex={1} />
                                            </HStack >
                                            <Flex flex={1} paddingTop={'4'}>
                                                <Stack textAlign={'center'} flex={1}>
                                                    <Text fontSize={'xl'} fontWeight={'thin'}>
                                                        Effects
                                                    </Text>
                                                    <Flex>
                                                        <Box flex={1}/>
                                                        <Box flex={1}>
                                                            <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/happy-b.webp"} alt="Flowerly"/>
                                                        </Box>
                                                        <Box flex={1}/>
                                                    </Flex>
                                                    <Text fontSize={'lg'} fontWeight={'bold'}>
                                                        Energy, Focus, Creativity
                                                    </Text>
                                                </Stack>
                                                <Stack textAlign={'center'} flex={1}>
                                                    <Text fontSize={'xl'} fontWeight={'thin'}>
                                                        Aroma
                                                    </Text>
                                                    <Flex>
                                                        <Box flex={1}/>
                                                        <Box flex={1}>
                                                            <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/happy-b.webp"} alt="Flowerly"/>
                                                        </Box>
                                                        <Box flex={1}/>
                                                    </Flex>
                                                    <Text fontSize={'lg'} fontWeight={'bold'}>
                                                        Citrus, Lemon, Sweet
                                                    </Text>
                                                </Stack>
                                                <Stack textAlign={'center'} flex={1}>
                                                    <Text fontSize={'xl'} fontWeight={'thin'}>
                                                        Terpenes
                                                    </Text>
                                                    <Flex>
                                                        <Box flex={1}/>
                                                        <Box flex={1}>
                                                            <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/happy-b.webp"} alt="Flowerly"/>
                                                        </Box>
                                                        <Box flex={1}/>
                                                    </Flex>
                                                    <Text fontSize={'lg'} fontWeight={'bold'}>
                                                        Limonene, Pinene, Myrcene
                                                    </Text>
                                                </Stack>
                                            </Flex>
                                            <Flex flexDir={'row'} paddingTop={'4'}>
                                                <Box flex={1} >
                                                    <Text fontSize={'3xl'} fontWeight={'bold'}>
                                                        AI Description
                                                    </Text>
                                                    <Box>
                                                        <Text fontSize={'lg'}>
                                                            Super Lemon Haze, sometimes "SLH", is a sativa-dominant hybrid marijuana strain of Lemon Skunk and Super Silver Haze and two time Cannabis Cup.
                                                        </Text>
                                                    </Box>
                                                </Box>
                                            </Flex>
                                        </Box>
                                        <Divider orientation="vertical" w={viewWidth > desktopToTabletWidth ? '10px' : '80px'}/>
                                        <Box flex={1} p={6}>
                                            <Text fontSize={'3xl'} fontWeight={'bold'}>
                                                Hand Picked For You
                                            </Text>
                                            <Flex>
                                                <Box > 
                                                    <HStack paddingTop={'4'}>
                                                        <Box>
                                                            <CircularProgress size='80px' value={95} color='orange.400' thickness='8px'>
                                                                <CircularProgressLabel fontSize={'xl'} fontWeight={'bold'}>{95}%</CircularProgressLabel>
                                                            </CircularProgress>
                                                        </Box>
                                                        <Box>
                                                            <Text fontSize={'xl'}>
                                                            Super Lemon Haze is perfectly suited for crushing work and working out. Great choice!
                                                            </Text>
                                                        </Box>
                                                    </HStack>
                                                    <Flex flexDir={'column'} paddingTop={'4'}>
                                                        <Stack flex={1} paddingTop={"8px"}>
                                                            <Flex>
                                                                <Text fontSize={'md'} fontWeight={'bold'}>
                                                                    Sativa | Whole Flower | 3.5g
                                                                </Text>
                                                                <Spacer />
                                                                <Text fontSize={'md'} fontWeight={'bold'}>
                                                                    100%
                                                                </Text>
                                                            </Flex>
                                                            <Box backgroundColor={'white'} borderRadius={'30px'} height={'20px'} width={'100%'}>
                                                                <Progress bgGradient='linear(to-l, #FFDD56, #FF9438)' colorScheme={''} height='20px' value={100} borderRadius={30}/>
                                                            </Box>
                                                        </Stack>
                                                        <Stack flex={1} paddingTop={"8px"} marginTop={"20px"}>
                                                            <Flex>
                                                                <Text fontSize={'md'} fontWeight={'bold'}>
                                                                    Creative | Focused | Uplifting
                                                                </Text>
                                                                <Spacer />
                                                                <Text fontSize={'md'} fontWeight={'bold'}>
                                                                    90%
                                                                </Text>
                                                            </Flex>
                                                            <Box backgroundColor={'white'} borderRadius={'30px'} height={'20px'} width={'100%'}>
                                                                <Progress bgGradient='linear(to-l, #FFDD56, #FF9438)' colorScheme={''} height='20px' width={'90%'} value={90} borderRadius={30}/>
                                                            </Box>
                                                        </Stack>
                                                        <Stack flex={1} paddingTop={"8px"} marginTop={"20px"}>
                                                            <Flex>
                                                                <Text fontSize={'md'} fontWeight={'bold'}>
                                                                    Floral | Citrus | Sweet
                                                                </Text>
                                                                <Spacer />
                                                                <Text fontSize={'md'} fontWeight={'bold'}>
                                                                    95%
                                                                </Text>
                                                            </Flex>
                                                            <Box backgroundColor={'white'} borderRadius={'30px'} height={'20px'} width={'100%'}>
                                                                <Progress bgGradient='linear(to-l, #FFDD56, #FF9438)' colorScheme={''} height='20px' width={'95%'} value={95} borderRadius={30}/>
                                                            </Box>
                                                        </Stack>
                                                    </Flex>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                    <Flex flexDir={'column'} paddingTop={'6'} >
                                        <Center flex={1}  paddingBottom={'10vh'}>
                                            <Button fontSize={'4xl'} fontWeight={'bold'} variant={'ghost'} onClick={scrollToTarget}>
                                                <Flex flexDir={'column'}>
                                                <Box flex={1}>
                                                    <Text fontSize={'xl'} fontWeight={'bold'}>
                                                    Learn More
                                                    </Text>
                                                </Box>
                                                <Box flex={1}>
                                                    <TriangleDownIcon fontSize={'xl'}/>   
                                                </Box>
                                                </Flex>
                                            </Button>
                                        </Center>
                                        <Flex ref={targetRef} flex={1} bgColor={''}>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={6} width={'100%'}>
                                            <Box p={6} m={6}>
                                                <Text fontSize={'3xl'} fontWeight={'bold'}>
                                                    Cannabinoids
                                                </Text>
                                                <HStack m={3} p={3}>
                                                    <Box textAlign={'center'} flex={1}>
                                                        <Center>
                                                            <Text>CBG</Text>
                                                        </Center>
                                                        <CircularProgress size='80px' value={25} color='orange.400' thickness='6px'>
                                                            <CircularProgressLabel fontSize={'lg'} fontWeight={'bold'}>{25}%</CircularProgressLabel>
                                                        </CircularProgress>
                                                        <Text fontSize={'sm'}>Anti-Anxiety</Text>
                                                    </Box>
                                                    <Box textAlign={'center'} flex={1}>
                                                        <Center>
                                                            <Text>THCV</Text>
                                                        </Center>
                                                        <CircularProgress size='80px' value={25} color='orange.400' thickness='6px'>
                                                            <CircularProgressLabel fontSize={'lg'} fontWeight={'bold'}>{25}%</CircularProgressLabel>
                                                        </CircularProgress>
                                                        <Text fontSize={'sm'}>Energy</Text>
                                                    </Box>
                                                    <Box textAlign={'center'} flex={1}>
                                                        <Center>
                                                            <Text>CBD</Text>
                                                        </Center>
                                                        <CircularProgress size='80px' value={25} color='orange.400' thickness='6px'>
                                                            <CircularProgressLabel fontSize={'lg'} fontWeight={'bold'}>{25}%</CircularProgressLabel>
                                                        </CircularProgress>
                                                        <Text fontSize={'sm'}>Pain</Text>
                                                    </Box>
                                                    <Box textAlign={'center'} flex={1}>
                                                        <Center>
                                                            <Text>CBC</Text>
                                                        </Center>
                                                        <CircularProgress size='80px' value={25} color='orange.400' thickness='6px'>
                                                            <CircularProgressLabel fontSize={'lg'} fontWeight={'bold'}>{25}%</CircularProgressLabel>
                                                        </CircularProgress>
                                                        <Text fontSize={'sm'}>Anti-Cancer</Text>
                                                    </Box>
                                                </HStack>
                                                <Box paddingBottom={3}>
                                                    <Box backgroundColor={'white'} borderRadius={'30px'} height={'10px'} width={'100%'} >
                                                        <Progress bgGradient='linear(to-l, #FFDD56, #FF9438)' colorScheme={''} height='10px' value={100} borderRadius={30}/>
                                                    </Box>
                                                </Box>
                                                <HStack>
                                                    <Box flex={1} textAlign={'center'}>
                                                        <Text fontSize={'xl'} fontWeight={'bold'}>
                                                            THC
                                                        </Text>
                                                        <Text fontSize={'lg'}>
                                                            23%
                                                        </Text>
                                                    </Box>
                                                    <Box flex={1} textAlign={'center'}>
                                                        <Text fontSize={'xl'} fontWeight={'bold'}>
                                                            CBD
                                                        </Text>
                                                        <Text fontSize={'lg'}>
                                                            3%
                                                        </Text>
                                                    </Box>
                                                    <Box flex={1} textAlign={'center'}>
                                                        <Text fontSize={'xl'} fontWeight={'bold'}>
                                                            Ratio
                                                        </Text>
                                                        <Text fontSize={'lg'}>
                                                            High-THC
                                                        </Text>
                                                    </Box>
                                                </HStack>
                                                <Text fontSize={'3xl'} fontWeight={'bold'}>
                                                    Terpenes
                                                </Text>
                                                <HStack m={3} p={3} fontSize={'xs'}>
                                                    <Box textAlign={'center'} flex={1}>
                                                        <Center p={3}>
                                                            <Text>b-Mycrene</Text>
                                                        </Center>
                                                        <CircularProgress size='80px' value={70} color='orange.400' thickness='6px' >
                                                            <CircularProgressLabel fontSize={'lg'} fontWeight={'bold'}>{.34}%</CircularProgressLabel>
                                                        </CircularProgress>
                                                        <Text>Anti-Inflammatory</Text>
                                                    </Box>
                                                    <Box textAlign={'center'} flex={1}>
                                                        <Center p={3}>
                                                            <Text>b-Caryophyllene</Text>
                                                        </Center>
                                                        <CircularProgress size='80px' value={80} color='orange.400' thickness='6px' >
                                                            <CircularProgressLabel fontSize={'lg'} fontWeight={'bold'}>{.24}%</CircularProgressLabel>
                                                        </CircularProgress>
                                                        <Text>pain relief</Text>
                                                    </Box>
                                                    <Box textAlign={'center'} flex={1}>
                                                        <Center p={3}>
                                                            <Text>Limonene</Text>
                                                        </Center>
                                                        <CircularProgress size='80px' value={90} color='orange.400' thickness='6px' >
                                                            <CircularProgressLabel fontSize={'lg'} fontWeight={'bold'}>{.09}%</CircularProgressLabel>
                                                        </CircularProgress>
                                                        <Text>immunostimulant</Text>
                                                    </Box>
                                                </HStack>
                                            </Box>
                                            <Box p={6} m={6}>
                                                <Text fontSize={'3xl'} fontWeight={'bold'}>
                                                    Ideal Temp + Taste <Badge colorScheme="orange">Beta</Badge>
                                                </Text>
                                                <HStack m={3} p={3} fontSize={'md'}>
                                                    <Box textAlign={'center'} flex={1}>
                                                        <Center p={3}>
                                                            <Text fontSize={'sm'}>Low Temp</Text>
                                                        </Center>
                                                        <Progress colorScheme={'yellow'} height='10px' value={40} borderRadius={50} m={3}/>                                                            
                                                        <Text fontWeight={'bold'}>331℉</Text>
                                                        <Text >166℃</Text>
                                                        <Flex paddingTop={4}>
                                                            <Box flex={1}/>
                                                            <Box flex={1}>
                                                                <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/terpeneTaste/Mango.webp"} alt="Flowerly"/>
                                                            </Box>
                                                            <Box flex={1}/>
                                                        </Flex>
                                                        <Text fontWeight={'bold'}>Mango</Text>
                                                    </Box>
                                                    <Box textAlign={'center'} flex={1}>
                                                        <Center p={3}>
                                                            <Text fontSize={'sm'}>High Temp</Text>
                                                        </Center>
                                                        <Progress colorScheme={'red'} height='10px' value={80} borderRadius={30} m={3}/>                                                      
                                                        <Text fontWeight={'bold'}>390℉</Text>
                                                        <Text >199℃</Text>
                                                        <Flex paddingTop={4}>
                                                            <Box flex={1}/>
                                                            <Box flex={1}>
                                                                <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/terpeneTaste/BlackPepper.webp"} alt="Flowerly"/>
                                                            </Box>
                                                            <Box flex={1}/>
                                                        </Flex>
                                                        <Text fontWeight={'bold'}>Black Pepper</Text>
                                                    </Box>
                                                    <Box textAlign={'center'} flex={1}>
                                                        <Center p={3}>
                                                            <Text fontSize={'sm'}>Medium Temp</Text>
                                                        </Center>
                                                        <Progress colorScheme={'orange'} height='10px' value={60} borderRadius={30} m={3}/>                                                     
                                                        <Text fontWeight={'bold'}>348.8℉</Text>
                                                        <Text >176℃</Text>
                                                        <Flex paddingTop={4}>
                                                            <Box flex={1}/>
                                                            <Box flex={1}>
                                                                <Image w="50px" src={"https://flowerly-app-storage.s3.amazonaws.com/assets/terpeneTaste/Lemon.webp"} alt="Flowerly"/>
                                                            </Box>
                                                            <Box flex={1}/>
                                                        </Flex>
                                                        <Text fontWeight={'bold'}>Lemon</Text>
                                                    </Box>
                                                </HStack>
                                                <Text fontSize={'3xl'} fontWeight={'bold'}>
                                                    Effects from Terpenes <Badge colorScheme="orange">Beta</Badge>
                                                </Text>
                                                <TableContainer>
                                                    <Table variant='simple' size={'xs'}>
                                                        <TableCaption>Results may vary. Consult a doctor for medical advice.</TableCaption>
                                                        <Thead>
                                                            <Tr >
                                                                <Th color={'orange.400'} textAlign={'center'}>b-Myrcene</Th>
                                                                <Th color={'red.600'} textAlign={'center'}>b-Caryophyllene</Th>
                                                                <Th color={'yellow.600'} textAlign={'center'}>Limonene</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody fontSize={'xs'} textAlign={'center'}>
                                                
                                                            <Tr >
                                                                <Td>anti-ageing</Td>
                                                                <Td>anti-inflammatory </Td>
                                                                <Td>anti-inflammatory </Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td>analgesic</Td>
                                                                <Td>antioxidant</Td>
                                                                <Td>cancer-fighting</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td>reduced anxiety</Td>
                                                                <Td>reduced anxiety</Td>
                                                                <Td>reduced anxiety</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td>sedative</Td>
                                                                <Td>pain</Td>
                                                                <Td>stomach issues</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td>antidiabetic</Td>
                                                                <Td>seizures</Td>
                                                                <Td></Td>
                                                            </Tr>
                                                        </Tbody>
                                                        
                                                    </Table>
                                                </TableContainer>

                                            </Box>
                                        </Grid>
                                            <div style={{ paddingBottom: '1000px' }}></div>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Box>
                            {viewWidth > desktopToTabletWidth &&
                            <FlowerlyComponent
                                options={[
                                {price: 60, quantity: '3.5g'},
                                {price: 90, quantity: '7g'}
                                ]}
                            />
                            }
                        </Flex>
                        )}
                </Flex>
        </Box>    
        );
    };
       
        
    return (
      <>
        <div className={'animate__animated animate__fadeIn'}>
            {isMobile ? mobileView : <DesktopBackground />} 
        </div>
      </> 
    )
  }

  export default Summary;
