import UniversalQuestion from '../questionsv3/UniversalAvatarPrompt';
import { qualityOptions } from './../../utilities/menuOptions'

const WhatQuality = (props) => {
    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={qualityOptions.firstLineText}
              secondLineText={qualityOptions.secondLineText}
              fullText={qualityOptions.fullText}
              answers={qualityOptions.options}
              progressValue={qualityOptions.progressValue}
              avatar={qualityOptions.avatar}
              avatarSafari={qualityOptions.avatarSafari}
              avatarChrome={qualityOptions.avatarChrome}
              route={qualityOptions.route}
              prevRoute={qualityOptions.prevRoute}
              
  
    />
  }
  
export default WhatQuality;