import { React, useEffect, useState} from 'react';
import { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Box, Flex, Link, HStack, Spacer, Text, Button, Image } from '@chakra-ui/react';


const FlowerlyLogo= () => (
    <Link href="/summary" >
      <Image width="200px" src="https://flowerly-app-storage.s3.amazonaws.com/logos/Flowerly-Text-Logo.svg" alt="Flowerly AI Logo" />
    </Link>
);


const Navbar = (props) => { 
  const { isMobile } = props;
  const [user, setUser] = useState(null);

  const checkUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        console.log('user: ', currentUser);
        setUser(currentUser);
      } catch (error) {
        setUser(null);
      }
  };

  useEffect(() => {
      const removeListener = Hub.listen('auth', (data) => {
          const { payload } = data;
          if (payload.event === 'signIn') {
              checkUser();
          }
          if (payload.event === 'signOut') {
              setUser(null);
          }
      });

      return () => removeListener();
  }, []);

  useEffect(() => {
      checkUser();
  }, []); // Re-run the effect when `user` changes

  const logoutAuth = async () => {
      try {
        await Auth.signOut();
        setUser(null);
      } catch (error) {
        console.log('IN NAV BITCH - error signing out: ', error);
      }
  }

  const signinAuth = async () => {
      
    //   const attemptedURL = window.location.pathname;
    //   sessionStorage.setItem('attemptedURL', attemptedURL);
      
      try {
        // await Auth.signIn();
        await Auth.federatedSignIn();
        checkUser();
      } catch (error) {
        console.log('IN NAV BITCH - error signing in: ', error);
      }
  }

  function getFirstName(fullName) {
        return fullName.split(' ')[0];
    }
  const awsUser = Auth.user?.attributes?.name;

  return (
    <Box p={4}>
        <Flex alignItems="center">
            <Link href="/">
                <FlowerlyLogo />
            </Link>
            <Spacer />

            {/* Here we're checking if user is defined instead of just checking loggedIn */}
            {user ? (
                <HStack> 
                    {
                        isMobile ? (
                            <></>
                        ) : (
                            <Text fontSize={[null, '4xl']} fontWeight={'bold'} color="black" mr={4}>
                                {/* Accessing name from user state instead of Auth directly */}
                                Welcome {getFirstName(awsUser)}!
                            </Text>
                        )
                    }
                    
                    <Button colorScheme="flowerlyColors" variant='outline' onClick={() => logoutAuth()} >
                        Sign Out
                    </Button>
                </HStack>
            ) : (
                <Link fontSize="md" color="black" mr={4}>
                    <Button colorScheme="flowerlyColors" variant='outline' onClick={() => signinAuth()} >
                        Sign In
                    </Button>
                </Link>
            )}
        </Flex>
    </Box>
  );

};

export default Navbar;
