import UniversalQuestion from '../questionsv3/UniversalAvatarPrompt';
import { intentionsOptions } from './../../utilities/menuOptions';


const Intentions = (props) => {
    
    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={intentionsOptions.firstLineText}
              secondLineText={intentionsOptions.secondLineText} 
              fullText={intentionsOptions.fullText}
              answers={intentionsOptions.options}
              extraColumns={3}
              extraSpacing={4}
              progressValue={intentionsOptions.progressValue}
              avatar={intentionsOptions.avatar}
              multipleSelect={intentionsOptions.multipleSelect}
              route={intentionsOptions.route}
              scroll={intentionsOptions.scroll}
              prevRoute={intentionsOptions.prevRoute}
              imageGrid={intentionsOptions.imageGrid}
    />  
  }
  
  export default Intentions;