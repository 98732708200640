import { useState } from 'react';
import { Link } from "react-router-dom";
import { Box, Flex, Heading, Divider, Image, Spacer, Button, Container, Center, SimpleGrid, Text, Checkbox, AspectRatio, useRadio, useRadioGroup, Stack, Badge, Grid, GridItem } from '@chakra-ui/react';
import { CheckIcon, CheckCircleIcon, ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { has } from 'lodash'


const UnderAge = (props) => {
  const { firstLineText = "Oh No!", secondLineText = "Looks like you're not old enough yet - you need to be at least 21!", route, simpleOptions, answers, extraColumns, extraSpacing, detailText, isMobile, progressValue, avatar, avatarChrome, avatarSafari, scroll, forcedRoute, prevRoute, multipleSelect, imageGrid } = props;  

  const mobileView = (
    <Box overflowY="scroll" maxHeight="88vh">
      <Center>
        <Flex direction="column" w="100%" p={8} m={8}>
          <Box >
            <Heading fontSize="4xl" fontWeight="bold">
              {firstLineText}
            </Heading>
            <Text fontSize="xl" fontWeight="bold">
              {secondLineText}
            </Text>
          </Box>
          <Center flex="1" color="black" borderRadius="2rem" mt="10px" p={8}>
            <Center w={'80vw'} borderRadius="30px" borderWidth="20px" borderColor="#9CDF27" position="relative" bg="#9CDF27">
                
              <Image maxW={props.isMobile ? "220px" : "300px"} src="https://flowerly-app-storage.s3.amazonaws.com/assets/ageVerification/ageWarning.webp" />
              {/* <Center position="absolute" top="0" right="0" bottom="0" left="0" bg="#9CDF27" /> */}
            </Center>
          </Center>
          <Box flex="1" borderRadius="2rem" pt="4vh" pb="8vh" mb="40px" pl="2vw" overflow={scroll === true ? "scroll" : ""} maxHeight="200px">
            <Center>
              {detailText}
            </Center>
            <Center>
              {/* Content */}
            </Center>
          </Box>
        </Flex>
      </Center>
    </Box>
  )

  const desktopBackground = (
    <Box w="100%"> 
      <Box borderTop="1px" borderColor="black" w="100vw" position="fixed" />
      <Flex>
        <Box flex="2">
          <AspectRatio maxW="560px" ratio={3 /4} m="auto" mt="4vh">
            <video
              style={{ borderRadius: "30px" }}
              title="Video"
              src="https://flowerly-app-storage.s3.amazonaws.com/assets/avatarVideos/Not_21-v2.mp4"
              controls={false}
              autoPlay
              allowFullScreen={false}
            />
          </AspectRatio>
        </Box>
        <Box borderLeft="1px" borderColor="black" height="85vh" position="fixed" left="40%" />
        <Box flex="3">
          <Box pl="60px" mt="4vh">
            <Heading fontSize="7xl" fontWeight="bold">
              {firstLineText}
            </Heading>
            <Text fontSize="4xl" fontWeight="bold">
              {secondLineText}
            </Text>
          </Box>
          <Flex pt={8} borderRadius="30px">
            <Box flex="1" />
            <Box flex={props.isMobile ? 2 : 1} borderRadius="30px" borderWidth="20px" borderColor="#9CDF27" position="relative" bg="#9CDF27">
              <Image maxW={props.isMobile ? "220px" : "300px"} src="https://flowerly-app-storage.s3.amazonaws.com/assets/ageVerification/ageWarning.webp" />
              {/* <Box position="absolute" top="0" right="0" bottom="0" left="0" bg="#9CDF27" /> */}
            </Box>

            <Box flex="1" />
          </Flex>
            <Center color="black">
              <Link to="/">
                <Button colorScheme="customColor" variant="outline" mt="4vh">
                  Back
                </Button>
              </Link>
            </Center>
        </Box>
      </Flex>
    </Box>
  )

  return (
    <div className="animate__animated animate__fadeIn">
      {isMobile ? mobileView : desktopBackground}
    </div>
  )
}

export default UnderAge;


// const UnderAge = (props) => {

//     return (
//       <>
//         <div className={'animate__animated animate__bounceInLeft'}>
//           <Flex paddingTop={20} >
//             <Box minW={props.isMobile ? 3 : 100} >
//             </Box>
//             <Box h='25vh' flex={1} bg={"rgba(0, 0, 0, 0.4);"} maxW='sm' color="white" style={{borderRadius: '2rem'}}>
//               <Container style={{paddingTop: props.isMobile ? '60px' : '7vh'}}>
//                 <Heading fontSize='4xl'>Oh No!</Heading>
//                 <Text fontSize='2xl'>Looks like you're not old enough yet - you need to be at least 18!</Text>
//               </Container>
//             </Box>
//             <Box minW={3}>
//             </Box>
//           </Flex>
  
//           <Flex paddingTop={8} >
//             <Box flex={1}>
//             </Box>
//             <Center flex={props.isMobile ? 2 : 1}>
//               <Image maxW={props.isMobile ? '220px' : '300px'} src="https://flowerly-app-storage.s3.amazonaws.com/assets/ageVerification/ageWarning.webp"/>
//             </Center>
//             <Box flex={1}>
//             </Box>
//           </Flex>
//         <Center color={'white'}>
//           <Link to="/">Back</Link>
//         </Center>
//         </div>
//       </> 
//     )
//   }
  
//   export default UnderAge;