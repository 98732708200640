import { useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { updateField } from '../../reducers/quizResults';
import { Box, Flex, Heading, Text, AspectRatio, Center, Slider, SliderMark, SliderThumb, SliderTrack, Button, Image, HStack, Progress  } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { MdGraphicEq } from 'react-icons/md'
import { timeOfDayOptions } from './../../utilities/menuOptions'


const TimeOfDay = (props) => {
    const { isMobile } = props;
    const dispatch = useDispatch();
    const { route, prevRoute, avatar, fullText, progressValue } = timeOfDayOptions;  
    const [timeValue, setTimeValue] = useState("day")
    const [displayValue, setDisplayValue] = useState("day 🌞")
    const [sliderValue, setSliderValue] = useState(50)

    const handleDataSaveToRedux = () => {
        dispatch(updateField({value: timeValue, fieldName: "preferredTimeOfDay"}));
    }

    const handleTranslatedTimeUpdate = (value) => {
        if (value <= 24) {
            setTimeValue("morning")
            setDisplayValue("morning 🌅")
        } else if (value > 24 && value <= 50) {
            setTimeValue("day")
            setDisplayValue("day 🌞")
        } else if (value > 50 && value <= 75) {
            setTimeValue("afternoon")
            setDisplayValue("afternoon 🌇")
        } else if (value > 75) {
            setTimeValue("night")
            setDisplayValue("night 🌚")
        }
    }

    const handleTranslatedTimeUpdateMobile = (value) => {
      if (value <= 24) {
          setTimeValue("morning")
          setDisplayValue("morning 🌅")
      } else if (value > 24 && value <= 50) {
          setTimeValue("day")
          setDisplayValue("day 🌞")
      } else if (value > 50 && value <= 84) {
          setTimeValue("afternoon")
          setDisplayValue("afternoon 🌇")
      } else if (value > 84) {
          setTimeValue("night")
          setDisplayValue("night 🌚")
      }
  }

    const viewHeight = window.innerHeight;

    const mobileView = <Box maxHeight='88vh'>
              <Center >
                  <Flex flexDir={'column'} paddingRight={'6vw'} paddingLeft={'6vw'} width={'100%'}>
                      <Box flex={1} h={"122px"} bgColor={'rgba(255,255,255,.4)'} borderRadius={'10px'} boxShadow={'0px 2px 10px 0px rgba(0, 0, 0, 0.15)'}>
                          <HStack h={'100%'}>
                              <Flex flex={1} width={'100%'} h={"100%"}>
                                  <AspectRatio 
                                      flexGrow={1}
                                      height={"100%"} 
                                      width={'100px'}>
                                      <video
                                      style={{borderRadius: "10px", boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.15)' }}
                                      title={'Video'}
                                      src={avatar}
                                      controls={false}
                                      autoPlay={true}
                                      playsInline={true}
                                      allowFullScreen={false}
                                      />
                                  </AspectRatio>
                              </Flex>
                              <Box p={3} width={'100%'} >
                                  <Heading fontSize='xl'>{fullText}</Heading>
                              </Box>
                          </HStack>    
                      </Box>
                      <Box flex={1} pt={4} >
                      <Progress 
                          size='lg' 
                          value={progressValue}
                          color="white"
                          bg="whiteAlpha.800"
                          borderRadius="md"
                          position="relative"
                          _before={{
                              bg: "linear-gradient(to right, #FFDD56, #FF9438)",
                              position: "absolute",
                              content: '""',
                              top: "0",
                              left: "0",
                              height: "100%",
                              width: `${progressValue}%`  // This should match the value prop of the Progress component
                          }}
                      />

                      </Box>
                      <Box flex={1} style={{borderRadius: '2rem', paddingTop: "4vh", paddingBottom:"8vh", marginBottom: '40px',  }}>
                        <Center>
                          <Box h='20vh' flex={1} color="white" style={{borderRadius: '2rem'}} >
                          <Center h="22vh" padding="20px">
                              <Image 
                                  className="animate__animated animate__fadeIn animate__slow"
                                  w="90vw"
                                  h="100%"
                                  src={`https://flowerly-app-storage.s3.amazonaws.com/assets/timeOfDay/a-${timeValue}.webp`}
                                  borderRadius="10"
                                  objectFit="cover"
                                  objectPosition="80% 80%"
                              />
                                <Center 
                                position="absolute"
                                top="0"
                                left="0"
                                w="100%"
                                h="100%"
                                color={'blackAlpha.600'}
                                fontWeight='semibold'
                                letterSpacing='wide'
                                fontSize='md'
                                // bgColor="rgba(0, 0, 0, 0.5)" // Optional, add if you want a semi-transparent background for the text
                            >
                                {displayValue}
                            </Center>
                            </Center>
                            <Box bg={"white"}  color="black" style={{borderRadius: '2rem'}} >
                              <Flex paddingTop={2} padding={4} m={2} display={"inline-block"}>
                                <Slider
                                  width={'70vw'}
                                  id='slider'
                                  defaultValue={50}
                                  min={0}
                                  max={100}
                                  colorScheme='teal'
                                  onChange={(v) => setSliderValue(v)}
                                  onChangeEnd={(v) => handleTranslatedTimeUpdateMobile(v)}
                                  fontWeight={'bold'}
                                  >
                                  <SliderMark value={2} mt='1' ml='-2.5' fontSize='sm' paddingTop={2}>
                                    morning
                                  </SliderMark>
                                  <SliderMark value={30} mt='1' ml='5.5' fontSize='sm' paddingTop={2}>
                                    day
                                  </SliderMark>
                                  <SliderMark value={60} mt='1' ml='-2.5' fontSize='sm' paddingTop={2}>
                                    afternoon
                                  </SliderMark>
                                  <SliderMark value={94} mt='1' ml='.5' fontSize='sm' paddingTop={2}>
                                    night
                                  </SliderMark>
                                  <SliderTrack/>
                                  <SliderThumb boxSize={6} bgColor='#C8F382' >
                                    <Box  color='black' as={MdGraphicEq} />
                                  </SliderThumb>
                                </Slider>
                              </Flex> 
                            </Box>
                          </Box>
                          </Center>
                          <Center>
                              {/* <FlowerlySelect data={answers} multipleSelect={multipleSelect}/> */}
                      </Center>
                      </Box>
                      <Box textAlign={'center'} paddingTop={viewHeight > 750 ? "12rem" : "6rem"} mb=".4rem" color={'white'}>
                          <Link to={(route)} >
                              <Button bgColor={'#FF9438' } variant={'outline'} w={'88vw'} onClick={handleDataSaveToRedux}> 
                                  <HStack>
                                      <Text fontSize={'2xl'} >Continue</Text>
                                  </HStack>
                              </Button>
                          </Link>
                      </Box>
                  </Flex>
              </Center>
        </Box>
      

    const desktopView = 
      
        <div className={'animate__animated animate__fadeIn'}>
          <Box w={'100%'}> 
            <Box borderTop="1px" borderColor="black" width="100vw" position="fixed" ></Box>
            <Flex>
            <Box flex={2}>
                  <Center >
                      <AspectRatio maxW='560px' w={'100%'} ratio={3 /4} m={['8']} marginTop={'4vh'} p={2} >
                          <video
                          style={{borderRadius: "30px"}}
                          title={'Video'}
                          src={ avatar}
                          controls={false}
                          autoPlay={true}
                          allowFullScreen={false}
                          />
                      </AspectRatio>
                  </Center>
              </Box>
              <Box borderLeft="1px" borderColor="black" height="85vh" position="fixed" left="40%"></Box>
              <Box flex={3}>
                <Box paddingLeft={'60px'} marginTop={'4vh'}>
                  <Heading fontSize={'7xl'} fontWeight={'bold'}>
                    What time
                  </Heading>
                  <Text fontSize={'4xl'} fontWeight={'bold'}>
                    of day are you thinking?
                  </Text>
                </Box>
                <Flex marginTop={'4vh'}>
                  <Box flex={1} textAlign={'center'}>
                    <Box m={'auto'} paddingTop={"120px"}>
                        <Link to={prevRoute} >
                            <Button colorScheme='customColor' variant={'outline'} >
                                <ArrowBackIcon />
                            </Button>
                        </Link>
                    </Box>
                  </Box>
                  <Box flex={6} >
                    <Box h='20vh' flex={1} color="white" style={{borderRadius: '2rem'}} textAlign={"-webkit-center"}>
                    <Box position="relative" w="48vw" h="22vh" padding="20px">
                        <Image 
                            className="animate__animated animate__fadeIn animate__slow"
                            w="100%"
                            h="100%"
                            src={`https://flowerly-app-storage.s3.amazonaws.com/assets/timeOfDay/a-${timeValue}.webp`}
                            borderRadius="10"
                            objectFit="cover"
                            objectPosition="80% 80%"
                        />
                        <Center 
                            position="absolute"
                            top="0"
                            left="0"
                            w="100%"
                            h="100%"
                            color={'blackAlpha.600'}
                            fontWeight='semibold'
                            letterSpacing='wide'
                            fontSize='4xl'
                            // bgColor="rgba(0, 0, 0, 0.5)" // Optional, add if you want a semi-transparent background for the text
                        >
                            {displayValue}
                        </Center>
                    </Box>

                      <Box bg={"white"}  color="black" style={{borderRadius: '2rem'}} >
                        <Flex paddingTop={2} padding={8} display={"inline-block"}>
                          <Slider
                            width={'40vw'}
                            id='slider'
                            defaultValue={50}
                            min={0}
                            max={100}
                            colorScheme='teal'
                            onChange={(v) => setSliderValue(v)}
                            onChangeEnd={(v) => handleTranslatedTimeUpdate(v)}
                            fontWeight={'bold'}
                            >
                            <SliderMark value={2} mt='1' ml='-2.5' fontSize='lg' paddingTop={2}>
                              morning
                            </SliderMark>
                            <SliderMark value={30} mt='1' ml='5.5' fontSize='lg' paddingTop={2}>
                              day
                            </SliderMark>
                            <SliderMark value={60} mt='1' ml='-2.5' fontSize='lg' paddingTop={2}>
                              afternoon
                            </SliderMark>
                            <SliderMark value={94} mt='1' ml='.5' fontSize='lg' paddingTop={2}>
                              night
                            </SliderMark>
                            <SliderTrack/>
                            <SliderThumb boxSize={6} bgColor='#C8F382' >
                              <Box  color='black' as={MdGraphicEq} />
                            </SliderThumb>
                          </Slider>
                        </Flex> 
                      </Box>
                    </Box>
                  </Box>
                  <Box flex={1} textAlign={'center'}>
                    <Box m={'auto'} paddingTop={"120px"}>
                      <Link to={route} >
                        <Button colorScheme='customColor' variant={'outline'} onClick={handleDataSaveToRedux}>
                          <ArrowForwardIcon />
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>                                
        </div>
      
    


    return (
      <>
        {isMobile ? mobileView : desktopView}
      </> 
    )
  }

  export default TimeOfDay;
